import api from './index';

export interface Subject {
    id: number;
    title: string;
    questions: number;
}

const subject = api.injectEndpoints({
    endpoints: (build) => ({
        getSubjects: build.query<Subject[], void>({
            query: () => 'admin/subject/all',
            providesTags: (result) => result
                ? [...result.map(({id}) => ({type: 'Subject' as const, id})), {type: 'Subject', id: 'LIST'}]
                : [{type: 'Subject', id: 'LIST'}]
        }),
        addSubject: build.mutation<Subject, Partial<Subject>>({
            query: (body) => ({
                url: 'admin/subject',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Subject']
        }),
        updateSubject: build.mutation<void, Pick<Subject, 'id'> & Partial<Subject>>({
            query: ({id, ...patch}) => ({
                url: `admin/subject/${id}`,
                method: 'PUT',
                body: patch,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Subject', id}]
        })
    }),
    overrideExisting: false,
});

export const {
    useGetSubjectsQuery, useAddSubjectMutation, useUpdateSubjectMutation
} = subject;

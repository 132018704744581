import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

const baseQuery = fetchBaseQuery({baseUrl: '/api'});
const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // Log in again
        window.localStorage.clear();
        window.location.replace('/login_perform');
    }
    return result
}
const index = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Images',
        'LatestEventLogs',
        'LatestNews', 'MyProfile', 'News',
        'Question', 'Quiz', 'Subject', 'SubjectStats',
        'UserGroup', 'UserGroupAdmin', 'User', 'UserRole', 'UserSummary'
    ],
    endpoints: () => ({}),
});

export default index;

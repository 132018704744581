import React, {useState} from "react";
import {FeedbackDialog} from 'mui-feedback-dialog';
import {enqueueSnackbar} from "notistack";

import FeedbackIcon from "@mui/icons-material/Feedback";
import {BottomNavigationAction, Button} from "@mui/material";

import {t} from "../i18n/i18n";
import {useSubmitFeedbackMutation} from "../api/feedback";

interface SubmitProps {
    email: string;
    description: string;
    screenshot: string;
}

interface Props {
    buttonType: 'TopPanel' | 'BottomPanel';
}
export const FeedbackButton = ({ buttonType }: Props) => {
    const [show, setShow] = useState(false);
    const [ submitFeedback ] = useSubmitFeedbackMutation();

    const handleSubmit = ({ description, email, screenshot}: SubmitProps) => {
        submitFeedback({ description, email, screenshot }).unwrap()
            .then(() => {
                enqueueSnackbar(t('feedback.snackbar.success'), { variant: "success" })
            });
    }

    return (
        <>
            {buttonType === 'TopPanel'
                ? <Button
                    sx={{my: 2, color: 'white', display: 'flex', mr: 4}}
                    startIcon={<FeedbackIcon />}
                    size={'small'}
                    onClick={() => setShow(true)}
                >Tilbakemelding</Button>
                : <BottomNavigationAction
                    key={'feedback'}
                    label={t('bottompanel.feedback')}
                    showLabel
                    icon={<FeedbackIcon/>}
                    onClick={() => setShow(true)}
                />
            }
            <FeedbackDialog
                open={show}
                showSuccessScreen
                attachScreenshotOnOpen
                text={{
                    title: t('feedback.title'),
                    contentText: t('feedback.contentText'),
                    includeScreenshot: t('feedback.includeScreenshot'),
                    emailLabel: t('feedback.emailLabel'),
                    feedbackLabel: t('feedback.feedbackLabel'),
                    tooltipPen: t('feedback.tooltip.pen'),
                    tooltipEraser: t('feedback.tooltip.eraser'),
                    tooltipBlackbox: t('feedback.tooltip.blackbox'),
                    tooltipReset: t('feedback.tooltip.reset'),
                    successText: t('feedback.successText'),
                    errorText: t('feedback.errorText'),
                    cancel: t('generic.button.cancel'),
                    submit: t('generic.button.submit')
                }}
                onClose={() => setShow(false)}
                onSubmit={handleSubmit}
            />
        </>
    );
};

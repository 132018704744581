import api from './index';

export interface Feedback {
    description: string;
    email: string;
    screenshot: string;
}

export const feedback = api.injectEndpoints({
    endpoints: (build) => ({
        submitFeedback: build.mutation<void, Feedback>({
            query: (body) => ({
                url: 'feedback',
                method: 'POST',
                body
            })
        })
    }),
    overrideExisting: false
});

export const { useSubmitFeedbackMutation } = feedback;

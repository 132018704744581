import {useParams} from "react-router-dom";

import {Box, Button, CircularProgress, Grid, Paper, Stack, styled, Typography} from "@mui/material";

import {useInfoQuizQuery} from "../api/quizexecution";
import MiniInfoCard from "../components/MiniInfoCard";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

const StyledPaper = styled(Paper)(() => ({
    padding: '6px',
    height: 'calc(100vh - 12px)',
    width: 'calc(100vw - 12px)',
}));

export const QuizInfoPage = () => {
    const {id} = useParams();
    const {data, isSuccess, isLoading, isError, error} = useInfoQuizQuery(id !== undefined ? id as unknown as number : 0);

    return (
        <StyledPaper>
            {isLoading && <CircularProgress />}

            {isError && <ErrorAlert title={t('apiError.quizStart')} error={error} />}

            {isSuccess && [
                <Stack key={'info'} spacing={2} justifyContent={'space-between'} sx={{marginBottom: 10}}>
                    <h1 style={{textAlign: 'center'}}>{t('quiz.start.title')}</h1>

                    <Box>
                        <Stack spacing={2} alignItems={'center'}>
                            <h2>{data.title}</h2>
                            <Typography>{data.instructions}</Typography>
                            <Grid container spacing={1} alignItems={'flex-start '} justifyContent={'center'}>
                                <Grid item xs={6} md={2}>
                                    <MiniInfoCard title={t('quiz.start.questionTitle')}>
                                        <Typography fontSize={'120%'}>{data.numberOfQuestions}</Typography>
                                    </MiniInfoCard>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <MiniInfoCard title={t('quiz.start.timeTitle')}>
                                        <Typography fontSize={'120%'}>{data.durationMinutes} min</Typography>
                                    </MiniInfoCard>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </Stack>,

                <Paper key={'buttons'} sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <Stack padding={1} alignItems={'center'}>
                        <Button
                            variant={'contained'}
                            href={'/quizstart/' + id}
                            color={'success'}
                            size={'large'}
                            sx={{height: 60, width: {xs: '100%', md: 500}}}
                            disabled={id === undefined}
                        >{t('generic.button.start')}</Button>
                    </Stack>
                </Paper>
            ]}
        </StyledPaper>
    );
};

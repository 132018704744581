import {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CountdownCircleTimer} from 'react-countdown-circle-timer';

import {
    Box,
    CircularProgress,
    FormControl, FormControlLabel,
    FormLabel,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Paper, Radio, RadioGroup,
    Stack,
    styled, useMediaQuery, useTheme
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {List, ListItem} from "@mui/material";
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';

import {useGetQuizStepQuery, useUpdateQuizStepAnswerMutation} from "../api/quizexecution";
import {displaySecondsAsTime} from "../Utils";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

import './QuizPage.css';

export const QuizPage = () => {
    const {quizrunid} = useParams();
    const navigate = useNavigate();
    const [question, setQuestion] = useState<number | undefined>(undefined);
    const {data, isSuccess, isLoading, isError, error} = useGetQuizStepQuery({
            executionId: quizrunid !== undefined ? quizrunid as unknown as number : 0,
            questionNumber: question
        }
    );
    const [updateQuizStepAnswer, updateAnswerStatus] = useUpdateQuizStepAnswerMutation();
    const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(undefined);
    const [storingAnswer, setStoringAnswer] = useState(false);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"));

    const StyledPaper = styled(Paper)(({theme}) => ({
        padding: '6px',
        minHeight: 'calc(100vh - 12px)',
        width: 'calc(100vw - 12px)',
    }));

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 20,
        borderRadius: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[200],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 10,
            backgroundColor: '#0099ff',
        },
    }));

    const handleSelectAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAnswer((event.target as HTMLInputElement).value as unknown as number);
    };

    const handleOk = () => {
        if (data !== undefined) {
            setStoringAnswer(true);

            updateQuizStepAnswer({
                executionId: data.executionId,
                questionNumber: data.questionNumber,
                answer: selectedAnswer
            }).unwrap().then(() => {
                setStoringAnswer(false);
                setSelectedAnswer(undefined);

                if (data.questionNumber === data.totalQuestions) {
                    navigate(`/quizend/${quizrunid}`);
                } else {
                    setQuestion(1 + Number(data.questionNumber));
                }
            }).catch((error) => {
                setStoringAnswer(false);
            })
        }
    }

    return (
        <>
            <Paper sx={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100}} elevation={3}>
                {isSuccess && (
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <IconButton to={`/quizend/${quizrunid}`} component={Link}><CloseIcon
                            fontSize={'large'}/></IconButton>
                        <h1 style={{marginLeft: 0}}>#{data.questionNumber}</h1>
                        <Box flexGrow={1}>
                            <BorderLinearProgress variant={'determinate'}
                                                  value={Number(data.questionNumber) * 100 / Number(data.totalQuestions)}
                            />
                        </Box>
                        <CountdownCircleTimer
                            isPlaying
                            duration={data.secondsLeft}
                            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                            colorsTime={[7, 5, 2, 0]}
                            size={50}
                            strokeWidth={2}
                            onComplete={() => navigate(`/quizend/${quizrunid}`)}
                        >
                            {({remainingTime}) => displaySecondsAsTime(remainingTime)}
                        </CountdownCircleTimer>
                    </Stack>
                )}
            </Paper>

            <div style={{ backgroundColor: 'aliceblue' }}>
                <StyledPaper id={'questionExecutionPage'}>
                    {isError && <ErrorAlert title={t('apiError.resultView')} error={error} />}
                    {updateAnswerStatus.isError && <ErrorAlert title={t('apiError.resultView')} error={updateAnswerStatus.error} />}

                    {isLoading && <CircularProgress />}

                    {isSuccess && [
                        <Stack key={'form'} spacing={2} justifyContent={'space-between'} sx={{ marginBottom: 10 }}>
                            <Box sx={{ paddingTop: '60px' }}>
                                <FormControl disabled={storingAnswer} fullWidth>
                                    <FormLabel>{data.question}</FormLabel>
                                    {data.questionType === 'PHOTO_QUESTION' && data.questionImage !== undefined && (<>
                                        <img
                                            alt={data.questionImage}
                                            src={'/api/image/thumb/' + data.questionImage}
                                            height={400}
                                            style={{ margin: '20px auto' }}
                                        />
                                    </>)}
                                    {data.questionType !== 'PHOTO_ANSWERS' && (
                                        <RadioGroup
                                            value={selectedAnswer}
                                            onChange={handleSelectAnswer}
                                        >
                                            <List
                                                sx={{
                                                    '--List-gap': '0.5rem',
                                                    '--List-item-paddingY': '1rem',
                                                    '--List-item-radius': '8px',
                                                    '--List-decorator-size': '32px',
                                                }}
                                            >
                                                {data.choices.map(({id, text}) => (
                                                    <ListItem
                                                        className={'answerItem' + (selectedAnswer !== undefined && id === +selectedAnswer ? 'Selected' : '')}
                                                        key={'answer_' + id}
                                                    >
                                                        <FormControlLabel
                                                            value={id}
                                                            control={<Radio />}
                                                            label={text}
                                                            sx={{flexGrow: 1, flexDirection: 'row'}}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </RadioGroup>
                                    )}
                                    {data.questionType === 'PHOTO_ANSWERS' && (<>
                                        <ImageList cols={isMd && data.choices.length > 2 ? 3 : 2}>
                                            {data.choices.map(({id, text}) => (
                                                <ImageListItem
                                                    key={'answer_' + id}
                                                    style={{ margin: '0 auto' }}
                                                    className={id === selectedAnswer ? 'answerItemSelected' : 'answerItem'}
                                                    onClick={() => setSelectedAnswer(id)}
                                                >
                                                    <img
                                                        alt={text}
                                                        src={'/api/image/thumb/' + text}
                                                        height={500}
                                                        style={{ maxHeight: '500px' }}
                                                    />
                                                    <ImageListItemBar
                                                        title={t('quiz.image.optionLabel', { id })}
                                                        position={'below'}
                                                        style={{
                                                            backgroundColor: id === selectedAnswer ? 'lightgreen' : '#ddd',
                                                            paddingLeft: '8px'
                                                        }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </>)}
                                </FormControl>
                            </Box>
                        </Stack>,

                        <Paper key={'buttons'} sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100}} elevation={3}>
                            <Stack padding={1} alignItems={'center'}>
                                <LoadingButton
                                    disabled={selectedAnswer === undefined}
                                    loading={storingAnswer}
                                    variant={'contained'}
                                    color={'success'}
                                    size={'large'}
                                    sx={{height: 60, width: {xs: '100%', md: 500}}}
                                    endIcon={<SendIcon/>}
                                    onClick={handleOk}
                                >
                                    {data.questionNumber === data.totalQuestions ? t('generic.button.quit') : t('generic.button.next')}
                                </LoadingButton>
                            </Stack>
                        </Paper>
                    ]}
                </StyledPaper>
            </div>
        </>
    );
};

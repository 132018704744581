import {CircularProgress} from "@mui/material";
import {BarChart} from "@mui/x-charts";
import {MakeOptional} from "@mui/x-charts/models/helpers";
import {BarSeriesType} from "@mui/x-charts/models/seriesType/bar";

import {useGetExecutionStatsQuery, useGetLast2YearsQuery, useGetTodayQuery} from "../../api/statistics";
import {ErrorAlert} from "../../components/ErrorAlert";
import {t} from "../../i18n/i18n";

export const StatisticsPage = () => {
    const {data, isSuccess, isLoading, isError, error} = useGetTodayQuery();
    const {data: lastYears, isSuccess: lastYearsSuccess, isLoading: lastYearsIsLoading, isError: lastYearsIsError, error: lastYearsError} = useGetLast2YearsQuery();
    const {data: executionStats, isSuccess: executionStatsSuccess, isLoading: executionStatsIsLoading, isError: executionStatsIsError, error: executionStatsError } = useGetExecutionStatsQuery();

    const months = Array.from({length: 12}, (item, i) => {
        return new Date(0, i).toLocaleString('nb-NO', {month: 'long'})
    });

    const yearData = !lastYearsSuccess ? [] :
        lastYears.reduce<MakeOptional<BarSeriesType, 'type'>[]>((acc, current) => {
            const yearIdx = acc.map(e => e.label).indexOf(current.year.toString());

            if (yearIdx >= 0) {
                // @ts-ignore
                acc[yearIdx].data[current.month - 1] = current.count;
            } else {
                const defaultData = Array.from({length: 12}, () => 0);
                defaultData[current.month - 1] = current.count;
                acc.push({
                    label: current.year.toString(),
                    data: defaultData
                });
            }
            return acc;
        }, []);

    const executionData = !executionStatsSuccess ? [] :
        executionStats.reduce<MakeOptional<BarSeriesType, 'type'>[]>((acc, current) => {
            const yearIdx = acc.map(e => e.label).indexOf(current.year.toString());

            if (yearIdx >= 0) {
                // @ts-ignore
                acc[yearIdx].data[current.month - 1] = current.count;
            } else {
                const defaultData = Array.from({length: 12}, () => 0);
                defaultData[current.month - 1] = current.count;
                acc.push({
                    label: current.year.toString(),
                    data: defaultData
                });
            }
            return acc;
        }, []);

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.statistics')} error={error} />}
            {lastYearsIsError && <ErrorAlert title={t('apiError.statistics')} error={lastYearsError} />}
            {executionStatsIsError && <ErrorAlert title={t('apiError.statistics')} error={executionStatsError} />}

            {isLoading && <CircularProgress />}
            {lastYearsIsLoading && <CircularProgress />}
            {executionStatsIsLoading && <CircularProgress />}

            {lastYearsSuccess && (
                <>
                    <h2>{t('admin.statistics.usersLastYears')}</h2>
                    <BarChart
                        height={300}
                        series={yearData}
                        xAxis={[{
                            data: months,
                            scaleType: 'band'
                        }]}
                        yAxis={[{
                            tickMinStep: 1
                        }]}
                        margin={{
                            top: 10,
                            bottom: 30,
                            left: 40,
                            right: 10
                        }}
                    />
                </>
            )}

            {executionStatsSuccess && (
                <>
                    <h2>{t('admin.statistics.executions')}</h2>
                    <BarChart
                        height={300}
                        series={executionData}
                        xAxis={[{
                            data: months,
                            scaleType: 'band'
                        }]}
                        yAxis={[{
                            tickMinStep: 1
                        }]}
                        margin={{
                            top: 10,
                            bottom: 30,
                            left: 40,
                            right: 10
                        }}
                    />
                </>
            )}

            {isSuccess && (
                <>
                    <h2>{t('admin.statistics.statsToday')}</h2>
                    {t('admin.statistics.usersToday', { count: data.uniqueUsers })}<br/>
                    {t('admin.statistics.quizesToday', { count: data.quizesPerformed })}
                </>
            )}
        </>
    );
}

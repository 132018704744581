import {useState} from "react";

import {ExtFile} from "@files-ui/react";

import {CircularProgress, Fab, IconButton, ImageList, ImageListItem, ImageListItemBar, Zoom} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";

import {useGetAllFilenamesQuery, useUploadImageMutation} from "../../api/images";
import {useGetUserInfoQuery} from "../../api/user";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";
import {ErrorAlert} from "../../components/ErrorAlert";
import {FileUploadDialog} from "../../components/FileUploadDialog";
import {isSystemAdmin} from "../../util/AdminUtil";
import {t} from "../../i18n/i18n";

interface ConfirmDialogState {
    open: boolean;
    title?: string;
    description?: string;
    callback?: () => void;
}

export const ImagesPage = () => {
    const {data: userInfo} = useGetUserInfoQuery();
    const [fileuploadDialogOpen, setFileuploadDialogOpen] = useState(false);
    const [confirmState, setConfirmState] = useState<ConfirmDialogState>({ open: false });
    const { data: filenames, isSuccess: filenamesSuccess } = useGetAllFilenamesQuery();
    const [uploadFile, uploadStatus] = useUploadImageMutation();

    const imagesData = filenames?.map((filename) => ({
        img: '/api/image/thumb/' + filename,
        title: filename
    })) ?? [];

    const handleAddNew = () => {
        setFileuploadDialogOpen(true);
    }

    const handleUploadClose = (submit: boolean, extFiles: ExtFile[]) => {
        if (submit && extFiles.length > 0) {
            const files: File[] = [];
            extFiles.map((f) => f.file !== undefined && files.push(f.file));

            uploadFile(files);
        }

        setFileuploadDialogOpen(false);
    }

    const handleConfirmClose = (confirmed: boolean) => {
        if (confirmed && confirmState.callback !== undefined) {
            confirmState.callback();
        }

        setConfirmState({ open: false });
    }

    return (
        <>
            {uploadStatus.isError && <ErrorAlert title={t('admin.image.uploadError')} error={uploadStatus.error} />}

            <FileUploadDialog
                title={t('admin.image.uploadTitle')}
                accept={'image/*'}
                open={fileuploadDialogOpen}
                onClose={handleUploadClose}
            />
            <ConfirmationDialog
                id={'confirm-dialog'}
                title={confirmState.title ?? ''}
                description={confirmState.description}
                open={confirmState.open}
                onClose={handleConfirmClose}
            />

            {!filenamesSuccess && <CircularProgress />}

            <ImageList cols={6}>
                {imagesData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.title}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.title}`}
                                >
                                    <PreviewIcon />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            {isSystemAdmin(userInfo) && (
                <div className={'fabArea'}>
                    <Zoom in unmountOnExit>
                        <Fab
                            disabled={fileuploadDialogOpen}
                            color={'primary'}
                            className={'fabButton'}
                            aria-label={t('generic.button.addNew')}
                            onClick={handleAddNew}
                        ><AddIcon/></Fab>
                    </Zoom>
                </div>
            )}
        </>
    );
};

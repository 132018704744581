import api from './index';

export interface AccessUser {
    userId: number;
    fullName: string;
    roles: AccessRole[];
}

export interface AccessRole {
    id: number;
    role: string;
    userGroup: AccessUserGroup;
}

interface AccessUserGroup {
    id: number;
    title: string;
}

interface AccessUserRoleAdd {
    userId: number;
    role: string;
    userGroupId?: number | null;
}

interface AccessUserRoleDelete {
    userId: number;
    roleId: number;
}

const accessmanagement = api.injectEndpoints({
    endpoints: (build) => ({
        getAllAccessUsers: build.query<AccessUser[], void>({
            query: () => 'admin/access',
            providesTags: ['UserRole']
        }),
        addAccessUserRole: build.mutation<AccessUser, AccessUserRoleAdd>({
            query: (body) => ({
                url: `admin/access/${body.userId}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['UserRole']
        }),
        deleteAccessUserRole: build.mutation<AccessUser, AccessUserRoleDelete>({
            query: (body) => ({
                url: `admin/access/${body.userId}/${body.roleId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['UserRole']
        })
    }),
    overrideExisting: false
});

export const {
    useGetAllAccessUsersQuery, useAddAccessUserRoleMutation, useDeleteAccessUserRoleMutation
} = accessmanagement;

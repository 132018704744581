import api from './index';
import {UserGroupInfo} from "./usergroups";

export interface UserRole {
    id: number;
    role: string;
    userGroup?: UserGroupInfo;
}

type UserStatusType = 'NOT_CONFIRMED' | 'ACTIVE';

export interface UserInfo {
    id: number | null;
    status: UserStatusType;
    firstName: string;
    fullName: string;
    birthDate: string | null;
    email: string;
    bpIdSub: string;
    handicapMin: number;
    created: string;
    lastLogin: string;
    lastUpdatedNif: string;
    userGroup?: UserGroupInfo;
    additionalUserGroups: UserGroupInfo[];
    admin: boolean;
    readTermsOfUse: boolean;
    profileComplete: boolean;
}

export interface UserInfoFilter {
    groupId: undefined | number;
}

export interface UserInfoMe extends UserInfo {
    userRoles: UserRole[];
}

interface SummaryInfo {
    availableQuizes: number;
    newAvailableQuizes: number;
    lastLoggedIn: string;
    missingRegion: boolean;
}

const user = api.injectEndpoints({
    endpoints: (build) => ({
        getUserInfo: build.query<UserInfoMe, void>({
            query: () => 'user/me',
            providesTags: ['MyProfile']
        }),
        confirmUser: build.mutation<void, void>({
            query: () => ({
                url: 'user/me/confirm',
                method: 'PUT'
            }),
        }),
        updateProfile: build.mutation<void, Pick<UserInfo, 'id'> & Partial<UserInfo>>({
            query: (body) => ({
                url: `user/me`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['MyProfile', 'UserGroup', 'UserGroupAdmin', 'UserSummary']
        }),
        updateUserAdditional: build.mutation<void, Pick<UserInfo, 'id'> & Partial<UserInfo>>({
            query: ({id, ...patch}) => ({
                url: `admin/user/${id}/additional`,
                method: 'PUT',
                body: patch
            }),
            invalidatesTags: (result, error, {id}) => id !== null
                ? [{type: 'User', id}, 'UserGroup', 'UserGroupAdmin', 'UserSummary']
                : ['UserGroup', 'UserGroupAdmin', 'UserSummary']
        }),
        getSummaryInfo: build.query<SummaryInfo, void>({
            query: () => 'info/summaryinfo',
            providesTags: ['UserSummary']
        }),
        getAllUsers: build.query<UserInfo[], UserInfoFilter | void>({
            query: () => 'admin/user/all',
            providesTags: (result) => result
                ? [...result.filter(({id}) => id !== null).map(({id}) => ({type: 'User' as const, id: id as unknown as number})), {type: 'User', id: 'LIST'}]
                : [{type: 'User', id: 'LIST'}]
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetUserInfoQuery,
    useConfirmUserMutation,
    useUpdateProfileMutation,
    useGetSummaryInfoQuery,
    useUpdateUserAdditionalMutation,
    useGetAllUsersQuery
} = user;

import {UserInfoMe} from "../api/user";

export const isSystemAdmin = (auth: UserInfoMe | undefined) => {
    if (auth === undefined) {
        return false;
    }

    const roles = auth.userRoles.map(ur => ur.role);
    return roles.indexOf('ADMIN') >= 0;
};

export const isRegionAdmin = (auth: UserInfoMe | undefined) => {
    if (auth === undefined) {
        return false;
    }

    const roles = auth.userRoles.map(ur => ur.role);
    return roles.indexOf('REGIONADMIN') >= 0;
};

import {useContext, useState} from "react";

import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";

import {t} from "../../../i18n/i18n";
import {useGetSubjectsQuery} from "../../../api/subject";
import {ErrorAlert} from "../../ErrorAlert";
import {formContext} from "./QuestionFormContext";
import {QuestionType} from "../../../api/question";
import {ImageSelectorDialog} from "../../ImageSelectorDialog";
import {ImageInputField} from "./ImageInputField";

interface Props {
    replace?: boolean;
}

export const QuestionForm1 = ({
    replace,
}: Props) => {
    const { formData, setFormData } = useContext(formContext);
    const {
        data: subjectData,
        isSuccess: subjectsSuccess,
        isLoading: subjectsLoading,
        isError: subjectsIsError,
        error: subjectsError,
    } = useGetSubjectsQuery();
    const [imageSelectorOpen, setImageSelectorOpen] = useState(false);

    return (
        <Stack spacing={2}>
            {subjectsIsError && <ErrorAlert title={t('apiError.getSubjectsInfo')} error={subjectsError}/>}
            {subjectsLoading && <CircularProgress/>}

            <FormControl fullWidth required error={formData.subject === undefined || formData.subject.id === null}>
                <InputLabel id={'subjectLabel'}>{t('admin.question.form.subject')}</InputLabel>
                <Select
                    id={'subject'}
                    disabled={!subjectsSuccess || replace}
                    label={t('admin.question.form.subject')}
                    labelId={'subjectLabel'}
                    value={formData.subject === undefined || formData.subject.id === null ? '' : formData.subject.id}
                    onChange={e => setFormData({
                        ...formData,
                        subject: {
                            id: e.target.value !== null ? e.target.value as unknown as number : null
                        }
                    })}
                >
                    {subjectData !== undefined && subjectData.map((subject) => (
                        <MenuItem key={subject.id} value={subject.id}>{subject.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth required error={formData.difficulty === null}>
                <InputLabel id={'difficultyLabel'}>{t('admin.question.form.difficulty')}</InputLabel>
                <Select
                    id={'difficulty'}
                    disabled={replace}
                    label={t('admin.question.form.difficulty')}
                    labelId={'difficultyLabel'}
                    value={formData.difficulty}
                    onChange={e => setFormData({...formData, difficulty: e.target.value})}
                >
                    <MenuItem value={'EASY'}>{t('generic.questionDifficultyLevel.easy')}</MenuItem>
                    <MenuItem value={'MEDIUM'}>{t('generic.questionDifficultyLevel.medium')}</MenuItem>
                    <MenuItem value={'HARD'}>{t('generic.questionDifficultyLevel.hard')}</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel id={'questionTypeLabel'}>{t('admin.question.form.questionType')}</InputLabel>
                <Select
                    id={'questionType'}
                    disabled={replace}
                    label={t('admin.question.form.questionType')}
                    labelId={'questionTypeLabel'}
                    value={formData.questionType}
                    onChange={e => setFormData({
                        ...formData,
                        questionType: e.target.value as QuestionType,
                        titleImage: e.target.value as QuestionType === 'PHOTO_QUESTION' ? formData.titleImage : undefined
                    })}
                >
                    {['TEXT', 'PHOTO_QUESTION', 'PHOTO_ANSWERS'].map((qt) =>
                        <MenuItem key={qt} value={qt}>
                            {t('admin.question.form.questionTypes.' + qt)}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <Stack direction={'row'} gap={2}>
                    <TextField
                        id={'title'}
                        margin={'dense'}
                        label={t('admin.question.form.question')}
                        multiline
                        minRows={5}
                        fullWidth
                        required
                        error={formData.title === undefined || formData.title.trim().length === 0}
                        variant={'outlined'}
                        value={formData.title}
                        onChange={e => setFormData({
                            ...formData,
                            title: e.target.value
                        })}
                    />

                    {formData.questionType === 'PHOTO_QUESTION' && <ImageInputField
                        filename={formData.titleImage}
                        onSelectImage={() => setImageSelectorOpen(true)} />
                    }
                </Stack>
            </FormControl>

            <ImageSelectorDialog open={imageSelectorOpen} onClose={(confirmed, filename) => {
                if (confirmed) {
                    setFormData({
                        ...formData,
                        titleImage: filename
                    });
                }
                setImageSelectorOpen(false);
            }} />
        </Stack>
    );
};

import {SyntheticEvent, useState} from "react";
import {ExtFile} from "@files-ui/react";
import {enqueueSnackbar} from "notistack";

import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener, GridRenderCellParams,
    GridRowId,
    GridRowParams,
    GridRowsProp,
    GridToolbar,
    MuiEvent,
    nbNO
} from "@mui/x-data-grid";
import {Button, Fab, Stack, Tooltip, Zoom} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BurstModeOutlinedIcon from '@mui/icons-material/BurstModeOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from "@mui/icons-material/Edit";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PreviewIcon from "@mui/icons-material/Preview";
import UploadFileIcon from '@mui/icons-material/UploadFile';

import {
    Question,
    useDeleteQuestionMutation,
    useGetQuestionsQuery,
    useUploadQuestionsFileMutation,
} from "../../api/question";
import {useGetSubjectsQuery} from "../../api/subject";
import {useGetUserInfoQuery} from "../../api/user";
import {FileUploadDialog} from "../../components/FileUploadDialog";
import {EditQuestionDialog} from "../../components/admin/EditQuestionDialog";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";
import {ViewDialogData, ViewQuestionDialog} from "../../components/admin/ViewQuestionDialog";
import {ErrorAlert} from "../../components/ErrorAlert";
import FormProvider from "../../components/admin/forms/QuestionFormContext";
import {isSystemAdmin} from "../../util/AdminUtil";
import {groupsFilterOperators, groupsSortComparator} from "./utils";
import {t} from "../../i18n/i18n";

interface EditDialogData {
    open: boolean;
    formValues?: Partial<Question>;
    replaceQ?: Question;
    closeCallback?: () => void;
}

interface ConfirmDialogState {
    open: boolean;
    quizId?: GridRowId;
    title?: string;
    description?: string;
    callback?: () => void;
}

export const QuestionsPage = () => {
    const {data: userInfo} = useGetUserInfoQuery();
    const {data, isSuccess, isError, error} = useGetQuestionsQuery();
    const {
        data: subjectData,
        isSuccess: subjectsSuccess,
        isError: subjectsIsError,
        error: subjectsError,
    } = useGetSubjectsQuery();
    const [uploadQuestionsFile, uploadStatus] = useUploadQuestionsFileMutation();
    const [deleteQuestion, deleteStatus] = useDeleteQuestionMutation();
    const [viewDialogData, setViewDialogData] = useState<ViewDialogData>({ showDialog: false });
    const [editDialogData, setEditDialogData] = useState<EditDialogData>({ open: false });
    const [fileuploadDialogOpen, setFileuploadDialogOpen] = useState(false);
    const [confirmState, setConfirmState] = useState<ConfirmDialogState>({ open: false });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditDialogClose = () => {
        setEditDialogData({
            open: false
        });
    }

    const handleAddNew = () => {
        setEditDialogData({
            open: true,
            closeCallback: handleEditDialogClose
        });
    }

    const handleEditRow = (id: GridRowId) => () => {
        const question = (data || []).find(e => e.id === id);
        if (question !== undefined) {
            setEditDialogData({
                formValues: question,
                open: true,
                closeCallback: handleEditDialogClose
            });
        }
    }

    const handleViewRow = (id: GridRowId) => () => {
        const question = (data || []).find(e => e.id === id);
        if (question !== undefined) {
            setViewDialogData({
                showDialog: true,
                question
            });
        }
    }

    const handleViewDialogClose = () => {
        setViewDialogData({
            showDialog: false
        });
    }

    const handleReplaceRow = (id: GridRowId) => () => {
        const question = (data || []).find(e => e.id === id);
        if (question !== undefined) {
            setEditDialogData({
                open: true,
                formValues: {
                    ...question,
                    id: undefined
                },
                replaceQ: question,
                closeCallback: handleEditDialogClose
            });
        }
    }

    const handleUploadFiles = () => {
        setFileuploadDialogOpen(true);
    }

    const handleFilelisteClose = (confirmed: boolean, files: ExtFile[]) => {
        if (confirmed && files.length === 1) {
            const uploadFile = files[0];
            if (uploadFile !== null && uploadFile.file !== undefined) {
                enqueueSnackbar(t('admin.question.snackbar.uploadingQuestionsFile'), { variant: "info" });

                const form = new FormData();
                form.append("file", uploadFile.file);

                uploadQuestionsFile(form).unwrap().then(() => {
                    enqueueSnackbar(t('admin.question.snackbar.uploadedQuestionsFile'), { variant: "success" });
                    setFileuploadDialogOpen(false);
                }).catch(() => {
                    enqueueSnackbar(t('admin.question.snackbar.uploadQuestionsFileError'), { variant: "error" });
                });
            }
        } else if (!confirmed) {
            setFileuploadDialogOpen(false);
        }
    }

    const handleDeleteQuestion = (id: GridRowId) => {
        const question = (data || []).find(q => q.id === id);
        if (question !== undefined) {
            enqueueSnackbar(t('admin.question.snackbar.deletingQuestion', {questionId: question.id}), { variant: "info" });
            deleteQuestion(question.id).unwrap().then(() => {
                enqueueSnackbar(t('admin.question.snackbar.deletedQuestion', {questionId: question.id}), { variant: "success" });
            }).catch(() => {
                enqueueSnackbar(t('admin.question.snackbar.deleteQuestionError', {questionId: question.id}), { variant: "error" });
            })
        }
    }

    const handleConfirmClose = (confirmed: boolean) => {
        if (confirmed && confirmState.callback !== undefined) {
            confirmState.callback();
        }

        setConfirmState({ open: false });
    }

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.question.list.header.actions'),
            width: 140,
            getActions: ({id}) => {
                if (!isSystemAdmin(userInfo)) {
                    return [];
                }

                return [
                    <GridActionsCellItem
                        icon={<PreviewIcon/>}
                        label={t('generic.button.edit')}
                        className={'textPrimary'}
                        onClick={handleViewRow(id)}
                        color={'inherit'}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label={t('generic.button.edit')}
                        className={'textPrimary'}
                        onClick={handleEditRow(id)}
                        color={'inherit'}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title={t('generic.button.replace')}><AutorenewIcon/></Tooltip>}
                        label={t('generic.button.replace')}
                        onClick={handleReplaceRow(id)}
                        color={'inherit'}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title={t('generic.button.delete')}><DeleteForeverIcon/></Tooltip>}
                        label={t('generic.button.delete')}
                        onClick={() => id !== undefined && setConfirmState({
                            open: true,
                            title: t('admin.question.list.delete.title'),
                            description: t('admin.question.list.delete.description', { questionId: id }),
                            callback: () => handleDeleteQuestion(id)})
                        }
                        color={'inherit'}
                    />
                ];
            }
        },
        {
            field: 'id',
            type: 'number',
            headerName: t('admin.question.list.header.id'),
            width: 50
        },
        {
            field: 'subject',
            headerName: t('admin.question.list.header.subject'),
            valueGetter: (params) => {
                return params.value.title;
            },
            valueOptions: (subjectData || [])
                .map(s => ({
                    value: s.title,
                    label: s.title ? s.title : '-'
                }))
                .sort((a, b) => a.label.localeCompare(b.label, 'no')),
            width: 200,
            filterOperators: groupsFilterOperators
        },
        {
            field: 'difficulty',
            headerName: t('admin.question.list.header.difficulty'),
            valueFormatter: ({value}) => t('generic.questionDifficultyLevel.' + value.toLowerCase()),
            valueOptions: [
                { value: 'EASY', label: t('generic.questionDifficultyLevel.easy')},
                { value: 'MEDIUM', label: t('generic.questionDifficultyLevel.medium')},
                { value: 'HARD', label: t('generic.questionDifficultyLevel.hard')}
            ],
            width: 150,
            sortComparator: groupsSortComparator,
            filterOperators: groupsFilterOperators
        },
        {
            field: 'title',
            headerName: t('admin.question.list.header.question'),
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                if (params.row.questionType === 'PHOTO_QUESTION') {
                    return <><ImageOutlinedIcon fontSize={'small'} color={'success'} />&nbsp;{params.value}</>;
                } else if (params.row.questionType === 'PHOTO_ANSWERS') {
                    return <><BurstModeOutlinedIcon fontSize={'small'} color={'info'} />&nbsp;{params.value}</>;
                }

                return <>{params.value}</>;
            }
        },
        {
            field: 'rule',
            headerName: t('admin.question.list.header.rule'),
            width: 100
        },
        {
            field: 'quizCount',
            type: 'number',
            headerName: t('admin.question.list.header.quizes'),
            width: 70
        }
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getQuestionsInfo')} error={error} />}
            {subjectsIsError && <ErrorAlert title={t('apiError.getSubjectsInfo')} error={subjectsError} />}
            {uploadStatus.isError && <ErrorAlert title={t('apiError.uploadQuestions')} error={uploadStatus.error} />}
            {deleteStatus.isError && <ErrorAlert title={t('apiError.deleteQuestion')} error={deleteStatus.error} />}

            <FileUploadDialog
                title={t('admin.question.button.uploadQuestions')}
                accept={'*.xslx'}
                open={fileuploadDialogOpen}
                onClose={handleFilelisteClose}
            />
            <ViewQuestionDialog
                viewQuestion={viewDialogData}
                callbackClose={handleViewDialogClose}
            />

            <FormProvider>
                <EditQuestionDialog
                    dialogOpen={editDialogData.open}
                    formValues={editDialogData.formValues}
                    replaceQ={editDialogData.replaceQ}
                    closeCallback={editDialogData.closeCallback}
                />
            </FormProvider>

            <ConfirmationDialog
                id={'confirm-dialog'}
                title={confirmState.title ?? ''}
                description={confirmState.description}
                open={confirmState.open}
                onClose={handleConfirmClose}
            />

            {isSystemAdmin(userInfo) && (data || []).length === 0 && (
                <Stack direction={'row'} spacing={1} sx={{mb: 1}}>
                    <Button
                        size={'small'}
                        onClick={handleUploadFiles}
                        endIcon={<UploadFileIcon/>}
                    >{t('generic.button.uploadFile')}</Button>
                </Stack>
            )}

            <DataGrid
                loading={!isSuccess || !subjectsSuccess}
                autoHeight
                editMode={'row'}
                columns={cols}
                rows={(data || []) as GridRowsProp}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                slots={{ toolbar: GridToolbar }}
                localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            rule: false
                        }
                    }
                }}
            />

            {isSystemAdmin(userInfo) && (
                <div className={'fabArea'}>
                    <Zoom in unmountOnExit>
                        <Fab
                            color={'primary'}
                            className={'fabButton'}
                            aria-label={t('generic.button.addNew')}
                            onClick={handleAddNew}
                        ><AddIcon/></Fab>
                    </Zoom>
                </div>
            )}
        </>
    );
};

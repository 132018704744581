import {createContext, useState} from "react";
import {Question} from "../../../api/question";

export const initFormData: Partial<Question> = {
    title: '',
    disabled: false,
    subject: {
        id: null
    },
    difficulty: 'EASY',
    questionType: 'TEXT',
    option1: '',
    option2: '',
    correctOption: 1,
    explanation: '',
    rule: ''
};

export const formContext = createContext({
    formData: initFormData,
    setFormData: (formData: Partial<Question>) => {}
});

const FormProvider = ({children}: { children: JSX.Element }) => {
    const [formState, setFormState] = useState(initFormData);

    const setFormData = (data: Partial<Question>) => {
        setFormState(data);
    };

    return (
        <formContext.Provider value={{formData: formState, setFormData}}>
            {children}
        </formContext.Provider>
    );
};

export default FormProvider;

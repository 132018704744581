import {useContext} from "react";

import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";

import {t} from "../../../i18n/i18n";
import {formContext} from "./QuestionFormContext";

export const QuestionForm2 = () => {
    const { formData, setFormData } = useContext(formContext);

    return (
        <>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <Stack spacing={2}>
                        <TextField
                            id={'option1'}
                            margin={'dense'}
                            label={t('admin.question.form.option1')}
                            fullWidth
                            required
                            error={formData.option1 === undefined || formData.option1.trim().length === 0}
                            variant={'outlined'}
                            value={formData.option1}
                            onChange={e => setFormData({
                                ...formData,
                                option1: e.target.value
                            })}
                        />
                        <TextField
                            id={'option2'}
                            margin={'dense'}
                            label={t('admin.question.form.option2')}
                            fullWidth
                            required
                            error={formData.option2 === undefined || formData.option2.trim().length === 0}
                            variant={'outlined'}
                            value={formData.option2}
                            onChange={e => setFormData({
                                ...formData,
                                option2: e.target.value
                            })}
                        />
                        <TextField
                            id={'option3'}
                            margin={'dense'}
                            label={t('admin.question.form.option3')}
                            fullWidth
                            variant={'outlined'}
                            value={formData.option3 || ''}
                            onChange={e => setFormData({
                                ...formData,
                                option3: e.target.value
                            })}
                        />
                        {formData.option3 !== undefined && formData.option3.length > 0 && (
                            <TextField
                                id={'option4'}
                                margin={'dense'}
                                label={t('admin.question.form.option4')}
                                fullWidth
                                variant={'outlined'}
                                value={formData.option4 || ''}
                                onChange={e => setFormData({
                                    ...formData,
                                    option4: e.target.value
                                })}
                            />
                        )}
                        {formData.option4 !== undefined && formData.option4.length > 0 && (
                            <TextField
                                id={'option5'}
                                margin={'dense'}
                                label={t('admin.question.form.option5')}
                                fullWidth
                                variant={'outlined'}
                                value={formData.option5 || ''}
                                onChange={e => setFormData({
                                    ...formData,
                                    option5: e.target.value
                                })}
                            />
                        )}
                        {formData.option5 !== undefined && formData.option5.length > 0 && (
                            <TextField
                                id={'option6'}
                                margin={'dense'}
                                label={t('admin.question.form.option6')}
                                fullWidth
                                variant={'outlined'}
                                value={formData.option6 || ''}
                                onChange={e => setFormData({
                                    ...formData,
                                    option6: e.target.value
                                })}
                            />
                        )}
                    </Stack>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel
                        id={'correctOptionLabel'}>{t('admin.question.form.correctOption')}</InputLabel>
                    <Select
                        id={'correctOption'}
                        label={t('admin.question.form.correctOption')}
                        labelId={'correctOptionLabel'}
                        value={formData.correctOption}
                        onChange={e => setFormData({
                            ...formData,
                            correctOption: e.target.value as number
                        })}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        {formData.option3 !== undefined && formData.option3.length > 0 && <MenuItem value={3}>3</MenuItem>}
                        {formData.option4 !== undefined && formData.option4.length > 0 && <MenuItem value={4}>4</MenuItem>}
                        {formData.option5 !== undefined && formData.option5.length > 0 && <MenuItem value={5}>5</MenuItem>}
                        {formData.option6 !== undefined && formData.option6.length > 0 && <MenuItem value={6}>6</MenuItem>}
                    </Select>
                </FormControl>
            </Stack>
        </>
    );
};

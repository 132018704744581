import {useContext, useRef} from "react";
import {Navigate} from "react-router-dom";
import moment from "moment";

import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    Typography
} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {useGetSummaryInfoQuery} from "../api/user";
import {useGetLatestNewsQuery} from "../api/news";
import {useGetOngoingQuizQuery} from "../api/quizexecution";
import {authContext} from "./AuthProvider";
import {ErrorAlert} from "./ErrorAlert";
import {NewsCard} from "./NewsCard";
import {t} from '../i18n/i18n';

export const LoggedInInfo = () => {
    const {data, isSuccess, isLoading, isError, error} = useGetSummaryInfoQuery(undefined, { refetchOnMountOrArgChange: 180 });
    const {data: newsItems, isSuccess: newsSuccess, isLoading: newsLoading, isError: newsIsError, error: newsError} =
        useGetLatestNewsQuery();
    const {data: ongoingQuizExecution, isSuccess: ongoingQuizExecutionSuccess} = useGetOngoingQuizQuery();
    const authCtx = useRef(useContext(authContext));

    if (authCtx.current.auth?.loading) {
        return <CircularProgress/>;
    }

    if (!authCtx.current.auth?.data?.hasConfirmed) {
        return <Navigate to={"/login_confirm_user"} />;
    }

    const lastLogin = isSuccess ? new Date(data.lastLoggedIn) : '';

    return (
        <>
            <h2>{t('front.title', { name: authCtx.current.auth?.data?.firstname})}</h2>

            {isError && <ErrorAlert title={t('apiError.frontpageSummaryView')} error={error} />}
            {newsIsError && <ErrorAlert title={t('apiError.newsList')} error={newsError} />}

            {(isLoading || newsLoading) && <CircularProgress />}

            <Grid container>
                {isSuccess && data.missingRegion &&
                    <Grid item xs={12} md={12} component={Card} margin={1}>
                        <Alert severity={'warning'} variant={'outlined'}>
                            <AlertTitle>{t('front.missingRegion.title')}</AlertTitle>
                            <CardContent>
                                <Typography>
                                    {t('front.missingRegion.text1')}
                                </Typography>
                                <Typography gutterBottom>
                                    {t('front.missingRegion.text2')}
                                </Typography>
                                <Typography>
                                    {t('front.missingRegion.text3')}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size={'small'}
                                    href={'/profile'}
                                    startIcon={<ArrowForwardIcon/>}
                                >
                                    {t('front.missingRegion.linkText')}
                                </Button>
                            </CardActions>
                        </Alert>
                    </Grid>
                }

                {ongoingQuizExecutionSuccess && ongoingQuizExecution !== null &&
                    <Grid item xs={12} md={12} component={Card} margin={1}>
                        <Alert severity={'warning'} variant={'outlined'}>
                            <AlertTitle>{t('front.ongoingQuiz.title')}</AlertTitle>
                            <CardContent>
                                <Typography>
                                    {t('front.ongoingQuiz.text')}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size={'small'}
                                    href={'/quizrun/' + ongoingQuizExecution.executionId}
                                    startIcon={<ArrowForwardIcon/>}
                                >
                                    {t('front.ongoingQuiz.linkText')}
                                </Button>
                            </CardActions>
                        </Alert>
                    </Grid>
                }

                {newsSuccess && newsItems.map(news => (
                    <Grid item key={news.id} xs={12} md={5} component={Card} margin={1}>
                        <NewsCard
                            title={news.title}
                            date={moment(news.created).format('YYYY-MM-DD HH:mm')}
                            text={news.text}
                            groups={news.userGroups}
                        />
                    </Grid>
                ))}


                {isSuccess && (
                    <Grid item xs={12} md={5} component={Card} margin={1}>
                        <NewsCard
                            title={t('front.yourInfo.title')}
                            date={moment().format('YYYY-MM-DD HH:mm')}
                            text={
                                <ul>
                                    <li>{t('front.yourInfo.availableTests', {numTests: data.availableQuizes})}</li>
                                    <li>{t('front.yourInfo.lastLogin')}: {lastLogin.toLocaleString("nb-NO")}</li>
                                </ul>
                            }
                            groups={[]}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

import {SyntheticEvent, useState} from "react";

import {Button, Fab, Zoom} from "@mui/material";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    MuiEvent,
    nbNO
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
    AccessRole, AccessUser,
    useDeleteAccessUserRoleMutation,
    useGetAllAccessUsersQuery
} from "../../api/accessmanagement";
import {ErrorAlert} from "../../components/ErrorAlert";
import {EditAccess, EditAccessDialog} from "../../components/admin/EditAccessDialog";
import {t} from "../../i18n/i18n";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";
import {enqueueSnackbar} from "notistack";

interface ConfirmDialogState {
    open: boolean;
    description?: string;
    callback?: () => void;
}

export const AccessManagementPage = () => {
    const {data, isSuccess, isError, error} = useGetAllAccessUsersQuery();
    const [removeRole, removeRoleStatus] = useDeleteAccessUserRoleMutation()
    const [addEditDialog, setAddEditDialog] = useState<EditAccess>({ showDialog: false });
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [confirmState, setConfirmState] = useState<ConfirmDialogState>({ open: false });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleAddNew = () => {
        setAddEditDialog({ showDialog: true });
    }

    const handleEditRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.Edit}
        });
    }

    const handleAddRole = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View}
        });
    }

    const handleCancelRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });
    }

    const handleRemoveAccessRole = (rowId: GridRowId, row: AccessUser, roleId: number, roleName: string) => {
        setConfirmState({
            open: true,
            description: t('admin.accessManagement.list.confirmRemove.description', {
                role: roleName,
                fullName: row.fullName
            }),
            callback: () => {
                removeRole({
                    userId: row.userId,
                    roleId: roleId
                }).unwrap().then(() => {
                    enqueueSnackbar(t('snackbar.access.updated'), { variant: "success" });
                    setRowModesModel({
                        ...rowModesModel,
                        [rowId]: {mode: GridRowModes.View, ignoreModifications: true}
                    });
                })
            }
        });
    }

    const handleConfirmRemoveAccessRoles = (confirmed: boolean) => {
        if (confirmed && confirmState.callback !== undefined) {
            confirmState.callback();
        }

        setConfirmState({ open: false });
    }

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.accessManagement.list.header.actions'),
            width: 100,
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<AddIcon/>}
                            label={t('generic.button.add')}
                            onClick={handleAddRole(id)}
                            color={'inherit'}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label={t('generic.button.cancel')}
                            className={'textPrimary'}
                            onClick={handleCancelRow(id)}
                            color={'inherit'}
                        />
                    ];
                } else {
                    return [
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label={t('generic.button.edit')}
                            className={'textPrimary'}
                            onClick={handleEditRow(id)}
                            color={'inherit'}
                        />
                    ];
                }
            }
        },
        {
            field: 'fullName',
            headerName: t('admin.accessManagement.list.header.user'),
            editable: false,
            width: 400
        },
        {
            field: 'roles',
            headerName: t('admin.accessManagement.list.header.roles'),
            editable: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, AccessRole[]>) => (
                <ul>
                    {
                        params.value?.map((r: AccessRole) => {
                            if (r.role === 'ADMIN') {
                                return <li key={r.id}>{r.role}</li>;
                            }

                            return <li key={r.id}>{r.role + ' (' + r.userGroup.title + ')'}</li>;
                        })
                    }
                </ul>
            ),
            renderEditCell: (params: GridRenderEditCellParams<any, AccessRole[]>) => (
                <ul>
                    {
                        params.value?.map((r: AccessRole) => {
                            if (r.role === 'ADMIN') {
                                return (
                                    <li key={r.id}><Button
                                        startIcon={<DeleteForeverIcon />}
                                        onClick={() => handleRemoveAccessRole(
                                            params.id,
                                            params.row,
                                            r.id,
                                            r.role
                                        )}
                                    />{r.role}</li>
                                );
                            }

                            return (
                                <li key={r.id}><Button
                                    startIcon={<DeleteForeverIcon />}
                                    onClick={() => handleRemoveAccessRole(
                                        params.id,
                                        params.row,
                                        r.id,
                                        r.role + ' (' + r.userGroup.title + ')'
                                    )}
                                />{r.role + ' (' + r.userGroup.title + ')'}</li>
                            );
                        })
                    }
                </ul>
            )
        }
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={error} />}
            {removeRoleStatus.isError && <ErrorAlert title={t('')} error={removeRoleStatus.error} />}

            <EditAccessDialog
                editAccess={addEditDialog}
                callbackClose={() => setAddEditDialog({ showDialog: false })}
            />

            <ConfirmationDialog
                id={'confirm-remove-role'}
                title={t('admin.accessManagement.list.confirmRemove.title')}
                description={confirmState.description}
                open={confirmState.open}
                onClose={handleConfirmRemoveAccessRoles}
            />

            <DataGrid
                loading={!isSuccess}
                autoHeight
                getRowHeight={() => 'auto'}
                editMode={'row'}
                columns={cols}
                getRowId={(row) => row.userId}
                rows={(data || []) as GridRowsProp}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
            />

            <div className={'fabArea'}>
                <Zoom in unmountOnExit>
                    <Fab
                        color={'primary'}
                        className={'fabButton'}
                        aria-label={t('generic.button.addNew')}
                        onClick={handleAddNew}
                    ><AddIcon/></Fab>
                </Zoom>
            </div>
        </>
    );
};

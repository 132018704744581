import {SyntheticEvent, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    MuiEvent,
    nbNO
} from "@mui/x-data-grid";
import {Fab, Zoom} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ListIcon from '@mui/icons-material/List';

import {t} from "../../i18n/i18n";
import {
    useDeleteUserGroupMutation,
    useGetAllUserGroupsAdminQuery,
    useUpdateUserGroupMutation
} from "../../api/usergroups";
import {useGetUserInfoQuery} from "../../api/user";
import {isSystemAdmin} from "../../util/AdminUtil";
import {AddUserGroupDialog} from "../../components/admin/AddUserGroupDialog";
import {ErrorAlert} from "../../components/ErrorAlert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";
import {enqueueSnackbar} from "notistack";

interface ConfirmDialogState {
    open: boolean;
    description?: string;
    callback?: () => void;
}

export const UserGroupsPage = () => {
    const {data: userInfo} = useGetUserInfoQuery();
    const {data, isSuccess, isError, error} = useGetAllUserGroupsAdminQuery();
    const [updateUserGroup, updateStatus] = useUpdateUserGroupMutation();
    const [removeUserGroup, removeStatus] = useDeleteUserGroupMutation();
    const [addOpen, setAddOpen] = useState(false);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [confirmState, setConfirmState] = useState<ConfirmDialogState>({open: false});
    let navigate = useNavigate();

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleAddNew = () => {
        setAddOpen(true);
    }

    const handleEditRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.Edit}
        });
    }

    const handleSaveRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View}
        });
    }

    const handleDeleteRow = (rowId: GridRowId, row: GridRowModel) => () => {
        setConfirmState({
            open: true,
            description: t('admin.userGroup.list.confirmRemove.description', {name: row.title}),
            callback: () => {
                removeUserGroup(row.id)
                    .unwrap().then(() => {
                    enqueueSnackbar(t('snackbar.usergroup.deleted'), {variant: "success"});
                    setRowModesModel({
                        ...rowModesModel,
                        [rowId]: {mode: GridRowModes.View, ignoreModifications: true}
                    });
                })
            }
        });
    }

    const handleConfirmRemoveUserGroup = (confirmed: boolean) => {
        if (confirmed && confirmState.callback !== undefined) {
            confirmState.callback();
        }

        setConfirmState({open: false});
    }

    const processRowUpdate = (row: GridRowModel) => {
        updateUserGroup({
            id: row.id,
            shortname: row.shortname,
            title: row.title,
            userSelectable: row.userSelectable
        }).unwrap();
        return row;
    }

    const handleCancelRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });
    }

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.userGroup.list.header.actions'),
            width: 100,
            getActions: ({id, row}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label={t('generic.button.save')}
                            onClick={handleSaveRow(id)}
                        />,
                        <GridActionsCellItem
                            icon={<DeleteForeverIcon/>}
                            label={t('generic.button.remove')}
                            onClick={handleDeleteRow(id, row)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label={t('generic.button.cancel')}
                            className={'textPrimary'}
                            onClick={handleCancelRow(id)}
                            color={'inherit'}
                        />
                    ];
                }

                const actions = [];

                if (isSystemAdmin(userInfo)) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label={t('generic.button.edit')}
                            className={'textPrimary'}
                            onClick={handleEditRow(id)}
                            color={'inherit'}
                        />
                    );
                }

                actions.push(
                    <GridActionsCellItem
                        icon={<ListIcon/>}
                        label={t('admin.userGroup.button.listMembers')}
                        onClick={() => navigate('/admin/users?group=' + id)}
                        color={'inherit'}
                    />
                );

                return actions;
            }
        },
        {
            field: 'userSelectable',
            headerName: t('admin.userGroup.list.header.userSelectable'),
            type: 'boolean',
            width: 150,
            editable: true
        },
        {
            field: 'shortname',
            headerName: t('admin.userGroup.list.header.shortname'),
            width: 100,
            editable: true
        },
        {
            field: 'title',
            headerName: t('admin.userGroup.list.header.name'),
            flex: 1,
            editable: true
        },
        {
            field: 'members',
            headerName: t('admin.userGroup.list.header.members'),
            type: 'number',
            width: 100,
            editable: false
        },
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={error}/>}
            {updateStatus.isError && <ErrorAlert title={t('apiError.updateGroupInfo')} error={updateStatus.error}/>}
            {removeStatus.isError && <ErrorAlert title={t('apiError.removeGroup')} error={removeStatus.error}/>}

            <ConfirmationDialog
                id={'confirm-remove-group'}
                title={t('admin.userGroup.list.confirmRemove.title')}
                description={confirmState.description}
                open={confirmState.open}
                onClose={handleConfirmRemoveUserGroup}
            />
            <AddUserGroupDialog
                showDialog={addOpen}
                callbackClose={() => setAddOpen(false)}
            />

            <DataGrid
                loading={!isSuccess}
                autoHeight
                editMode={'row'}
                columns={cols}
                rows={(data || []) as GridRowsProp}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'title', sort: 'asc'}]
                    }
                }}
            />

            {isSystemAdmin(userInfo) && (
                <div className={'fabArea'}>
                    <Zoom in unmountOnExit>
                        <Fab
                            color={'primary'}
                            className={'fabButton'}
                            aria-label={t('generic.button.addNew')}
                            onClick={handleAddNew}
                        ><AddIcon/></Fab>
                    </Zoom>
                </div>
            )}
        </>
    );
};

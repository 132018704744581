import {Link, useSearchParams} from "react-router-dom";

import {Alert, Button, Container, Typography} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {LoggedInInfo} from "../components/LoggedInInfo";
import {ShowIfAuth, ShowIfNotAuth} from "../components/RequireAuth";
import {t} from "../i18n/i18n";

export const HomePage = () => {
    const [searchParams] = useSearchParams();

    return (
        <Container sx={{my: {xs: 0, md: 0}, px: {xs: 2, md: 3}}}>
            <ShowIfAuth>
                <LoggedInInfo/>
            </ShowIfAuth>
            <ShowIfNotAuth>
                <>
                    {searchParams.has('loggedOut') &&
                        <Alert severity={'warning'}>{t('home.loggedOut')}</Alert>
                    }

                    <h1>
                        {t('home.welcome')}
                    </h1>

                    <Typography gutterBottom>
                        {t('home.text')}
                    </Typography>

                    <Button to={'/login'} component={Link} startIcon={<ArrowForwardIcon/>}>{t('generic.button.toLogin')}</Button>
                </>
            </ShowIfNotAuth>
        </Container>
    );
};

import {useContext, useState} from "react";

import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";

import {t} from "../../../i18n/i18n";
import {formContext} from "./QuestionFormContext";
import {ImageInputField} from "./ImageInputField";
import {ImageSelectorDialog} from "../../ImageSelectorDialog";

export const QuestionForm2Images = () => {
    const { formData, setFormData } = useContext(formContext);
    const [imageSelectorOpen, setImageSelectorOpen] = useState<number | undefined>(undefined);

    return (
        <>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <ImageInputField
                                id={'option1'}
                                filename={formData.option1}
                                onSelectImage={() => setImageSelectorOpen(1)}
                            />
                            <ImageInputField
                                id={'option2'}
                                filename={formData.option2}
                                onSelectImage={() => setImageSelectorOpen(2)}
                            />
                            <ImageInputField
                                id={'option3'}
                                filename={formData.option3}
                                onSelectImage={() => setImageSelectorOpen(3)}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            {formData.option3 !== undefined && formData.option3.length > 0 && (
                                <ImageInputField
                                    id={'option4'}
                                    filename={formData.option4}
                                    onSelectImage={() => setImageSelectorOpen(4)}
                                />
                            )}
                            {formData.option4 !== undefined && formData.option4.length > 0 && (
                                <ImageInputField
                                    id={'option5'}
                                    filename={formData.option5}
                                    onSelectImage={() => setImageSelectorOpen(5)}
                                />
                            )}
                            {formData.option5 !== undefined && formData.option5.length > 0 && (
                                <ImageInputField
                                    id={'option6'}
                                    filename={formData.option6}
                                    onSelectImage={() => setImageSelectorOpen(6)}
                                />
                            )}
                        </Stack>
                    </Stack>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel
                        id={'correctOptionLabel'}>{t('admin.question.form.correctOption')}</InputLabel>
                    <Select
                        id={'correctOption'}
                        label={t('admin.question.form.correctOption')}
                        labelId={'correctOptionLabel'}
                        value={formData.correctOption}
                        onChange={e => setFormData({
                            ...formData,
                            correctOption: e.target.value as number
                        })}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        {formData.option3 !== undefined && formData.option3.length > 0 && <MenuItem value={3}>3</MenuItem>}
                        {formData.option4 !== undefined && formData.option4.length > 0 && <MenuItem value={4}>4</MenuItem>}
                        {formData.option5 !== undefined && formData.option5.length > 0 && <MenuItem value={5}>5</MenuItem>}
                        {formData.option6 !== undefined && formData.option6.length > 0 && <MenuItem value={6}>6</MenuItem>}
                    </Select>
                </FormControl>

                <ImageSelectorDialog open={imageSelectorOpen !== undefined} onClose={(confirmed, filename) => {
                    if (confirmed) {
                        const newFormData = {...formData};
                        switch (imageSelectorOpen) {
                            case 1: newFormData.option1 = filename; break;
                            case 2: newFormData.option2 = filename; break;
                            case 3: newFormData.option3 = filename; break;
                            case 4: newFormData.option4 = filename; break;
                            case 5: newFormData.option5 = filename; break;
                            case 6: newFormData.option6 = filename; break;
                        }
                        setFormData(newFormData);
                    }
                    setImageSelectorOpen(undefined);
                }} />
            </Stack>
        </>
    );
};

import api from './index';

export interface Eventlog {
    id: number;
    eventType: string;
    created: string;
    createdById: number;
    createdBy: string;
    event: string;
    user?: string;
    userId?: number;
}

const eventlog = api.injectEndpoints({
    endpoints: (build) => ({
        getLatestEvents: build.query<Eventlog[], void>({
            query: () => 'admin/eventlog/latest',
            providesTags: ['LatestEventLogs']
        })
    }),
    overrideExisting: false
});

export const { useGetLatestEventsQuery } = eventlog;

import React from "react";
import {Outlet} from "react-router-dom";

import {Box, Toolbar} from "@mui/material";

import {TopPanel} from "../components/TopPanel";
import {BottomPanel} from "../components/BottomPanel";

export const PageLayout = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <TopPanel/>
            <Box component="main" sx={{ flexGrow: 1, px: 3, paddingTop: 1, paddingBottom: { xs: '60px', md: '0' }}}>
                <Toolbar/>
                <Outlet/>
            </Box>
            <BottomPanel/>
        </Box>
    );
};

import {Route, Routes} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import 'moment/locale/nb';

import {LocalizationProvider, nbNO} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

import {RequireAuth} from './components/RequireAuth';

import {QuizPage} from "./routes/QuizPage";
import {HomePage} from "./routes/HomePage";
import {LoginPage} from "./routes/LoginPage";
import {ProfilePage} from "./routes/ProfilePage";
import {PageLayout} from "./routes/PageLayout";
import {QuizEndPage} from "./routes/QuizEndPage";
import {AvailableQuizesPage} from "./routes/AvailableQuizesPage";
import {ResultsPage} from "./routes/ResultsPage";
import {QuizInfoPage} from "./routes/QuizInfoPage";
import {ResultPage} from "./routes/ResultPage";
import {LoginSuccessPage} from "./routes/LoginSuccessPage";
import {LoginOngoingPage} from "./routes/LoginOngoingPage";
import {UsersPage} from "./routes/admin/UsersPage";
import {UserGroupsPage} from "./routes/admin/UserGroupsPage";
import {SubjectsPage} from "./routes/admin/SubjectsPage";
import {QuestionsPage} from "./routes/admin/QuestionsPage";
import {QuizesPage} from "./routes/admin/QuizesPage";
import {ReportQuizUserGroupPage} from "./routes/admin/ReportQuizUserGroupPage";
import {StatisticsPage} from "./routes/admin/StatisticsPage";
import {AccessManagementPage} from "./routes/admin/AccessManagementPage";
import {ReportQuizQuestionStatsPage} from "./routes/admin/ReportQuizQuestionStatsPage";
import {NewsPage} from "./routes/admin/NewsPage";
import {QuizStartPage} from "./routes/QuizStartPage";
import {QuizOngoing} from "./routes/QuizOngoing";
import {InitRoute} from "./routes/admin/InitRoute";
import {EventsPage} from "./routes/admin/EventsPage";
import {ImagesPage} from "./routes/admin/ImagesPage";
import {ResultExecutionPage} from "./routes/admin/ResultExecutionPage";
import {LoginConfirmUserPage} from "./routes/LoginConfirmUserPage";
import {SystemInfo} from "./routes/admin/SystemInfo";

import './App.css';

export const App = () => {

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={nbNO.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <SnackbarProvider maxSnack={3}>
                <Routes>
                    <Route path='/' element={<PageLayout/>}>
                        <Route index element={<HomePage/>}/>
                        <Route path='login' element={<LoginPage/>}/>
                        <Route path='login_perform' element={<LoginOngoingPage/>}/>
                        <Route path='login_confirm_user' element={<LoginConfirmUserPage/>}/>
                        <Route path='profile' element={
                            <RequireAuth><ProfilePage/></RequireAuth>
                        }/>
                        <Route path='quizes' element={
                            <RequireAuth><AvailableQuizesPage/></RequireAuth>
                        }/>
                        <Route path='results' element={
                            <RequireAuth><ResultsPage/></RequireAuth>
                        }/>
                        <Route path='results/:quizrunid' element={
                            <RequireAuth><ResultPage/></RequireAuth>
                        }/>
                        <Route path='admin'>
                            <Route index element={
                                <RequireAuth><InitRoute/></RequireAuth>
                            }/>
                            <Route path={'systeminfo'} element={
                                <RequireAuth><SystemInfo/></RequireAuth>
                            }/>
                            <Route path={'news'} element={
                                <RequireAuth><NewsPage/></RequireAuth>
                            }/>
                            <Route path={'users'} element={
                                <RequireAuth><UsersPage/></RequireAuth>
                            }/>
                            <Route path={'usergroups'} element={
                                <RequireAuth><UserGroupsPage/></RequireAuth>
                            }/>
                            <Route path={'access_control'} element={
                                <RequireAuth><AccessManagementPage/></RequireAuth>
                            }/>
                            <Route path={'subjects'} element={
                                <RequireAuth><SubjectsPage/></RequireAuth>
                            }/>
                            <Route path={'questions'} element={
                                <RequireAuth><QuestionsPage/></RequireAuth>
                            }/>
                            <Route path={'images'} element={
                                <RequireAuth><ImagesPage/></RequireAuth>
                            }/>
                            <Route path={'quizes'} element={
                                <RequireAuth><QuizesPage/></RequireAuth>
                            }/>
                            <Route path='reports'>
                                <Route path={'quizusergroup'} element={
                                    <RequireAuth><ReportQuizUserGroupPage/></RequireAuth>
                                }/>
                                <Route path={'quizquestionstats'} element={
                                    <RequireAuth><ReportQuizQuestionStatsPage/></RequireAuth>
                                }/>
                                <Route path='execution/:executionid' element={
                                    <RequireAuth><ResultExecutionPage/></RequireAuth>
                                }/>
                            </Route>
                            <Route path={'statistics'} element={
                                <RequireAuth><StatisticsPage/></RequireAuth>
                            }/>
                            <Route path={'events'} element={
                                <RequireAuth><EventsPage/></RequireAuth>
                            }/>
                        </Route>
                    </Route>
                    <Route path='/quizinfo/:id' element={
                        <RequireAuth><QuizInfoPage/></RequireAuth>
                    }/>
                    <Route path='/quizstart/:id' element={
                        <RequireAuth><QuizStartPage/></RequireAuth>
                    }/>
                    <Route path='/quizrun/:quizrunid' element={
                        <RequireAuth><QuizPage/></RequireAuth>
                    }/>
                    <Route path='/quizend/:quizrunid' element={
                        <RequireAuth><QuizEndPage/></RequireAuth>
                    }/>
                    <Route path='/quizongoing' element={
                        <RequireAuth><QuizOngoing/></RequireAuth>
                    }/>
                    <Route path='/login_success' element={<LoginSuccessPage/>}/>
                </Routes>
            </SnackbarProvider>
        </LocalizationProvider>
    );
}

import api from './index';

interface QuizOwner {
    id: number;
    fullName: string;
}

interface QuizQuestion {
    id: number;
    questionNumberInQuiz: number;
    question: Question;
}

interface Question {
    id: number;
    title: string;
    difficulty: string;
    subject: Subject;
}

interface Subject {
    id: number | null;
    title: string | null;
}

export type difficultyLevels = 'EASY' | 'MEDIUM' | 'HARD';

interface QuizSubject {
    id: number;
    subject: Subject;
    difficulty: difficultyLevels;
    numberOfQuestions: number;
}

export interface QuizUserGroupInfo {
    id: number;
    title: string;
}

export interface Quiz {
    id: number;
    owner: QuizOwner;
    status: QuizStatusType;
    title: string;
    instructions: string;
    durationMinutes: number;
    passingPercent: number;
    maxAttempts: number;
    quizQuestionsType: string;
    questionsRandomOrder: boolean;
    answersRandomOrder: boolean;
    displayAnswerSheet: boolean;

    questions: QuizQuestion[];
    quizQuestionsSubjects: QuizSubject[];
    numberOfQuestions: number;

    userGroups: QuizUserGroupInfo[];
    userGroupsCount: number;

    start: string;
    end: string | null;
}

export type QuizStatusType = 'DRAFT' | 'ACTIVE' | 'FINISHED' | 'ARCHIVED' | 'DELETED';

interface QuizStatus {
    id: number;
    status: QuizStatusType;
}

interface QuizEndDateTime {
    id: number;
    end: string;
}

interface QuizSubjectsUpdate {
    id: number;
    quizQuestionsSubjects: QuizSubject[];
}

interface QuizQuestionsUpdate {
    id: number;
    questions: QuizQuestion[];
}

const quiz = api.injectEndpoints({
    endpoints: (build) => ({
        getAllQuizes: build.query<Quiz[], void>({
            query: () => 'admin/quiz/all',
            providesTags: (result) => result
                ? [...result.map(({id}) => ({type: 'Quiz' as const, id})), {type: 'Quiz', id: 'LIST'}]
                : [{type: 'Quiz', id: 'LIST'}]
        }),
        getQuiz: build.query<Quiz, number>({
            query: (quizid) => `admin/quiz/${quizid}`,
            providesTags: (result, error, id) => [{type: 'Quiz', id}]
        }),
        addQuiz: build.mutation<Quiz, Partial<Quiz>>({
            query: (body) => ({
                url: 'admin/quiz',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Quiz']
        }),
        cloneQuiz: build.mutation<Quiz, number>({
            query: (id) => ({
                url: `admin/quiz/${id}/clone`,
                method: 'POST',
            }),
            invalidatesTags: ['Quiz']
        }),
        deleteQuiz: build.mutation<void, number>({
            query: (id) => ({
                url: `admin/quiz/${id}/delete`,
                method: 'PUT',
            }),
            invalidatesTags: ['Quiz']
        }),
        updateQuiz: build.mutation<void, Pick<Quiz, 'id'> & Partial<Quiz>>({
            query: ({id, ...patch}) => ({
                url: `admin/quiz/${id}`,
                method: 'PUT',
                body: patch
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Quiz', id}]
        }),
        updateQuizStatus: build.mutation<Quiz, Pick<QuizStatus, 'id'> & Partial<QuizStatus>>({
            query: (body) => ({
                url: `admin/quiz/${body.id}/status`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Quiz', id}]
        }),
        updateQuizEnd: build.mutation<Quiz, Pick<QuizEndDateTime, 'id'> & Partial<QuizEndDateTime>>({
            query: (body) => ({
                url: `admin/quiz/${body.id}/end`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Quiz', id}]
        }),
        updateQuizSubjects: build.mutation<Quiz, Pick<QuizSubjectsUpdate, 'id'> & Partial<QuizSubjectsUpdate>>({
            query: (body) => ({
                url: `admin/quiz/${body.id}/subjects`,
                method: 'PUT',
                body: body
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Quiz', id}]
        }),
        updateQuizQuestions: build.mutation<Quiz, Pick<QuizQuestionsUpdate, 'id'> & Partial<QuizQuestionsUpdate>>({
            query: (body) => ({
                url: `admin/quiz/${body.id}/questions`,
                method: 'PUT',
                body: body
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Quiz', id}]
        })
    }),
    overrideExisting: false
});

export const {
    useGetAllQuizesQuery, useGetQuizQuery,
    useAddQuizMutation, useCloneQuizMutation, useDeleteQuizMutation,
    useUpdateQuizMutation, useUpdateQuizStatusMutation, useUpdateQuizEndMutation,
    useUpdateQuizSubjectsMutation, useUpdateQuizQuestionsMutation
} = quiz;

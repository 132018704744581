import {useContext, useEffect, useRef} from "react";
import {Navigate, useNavigate} from "react-router-dom";

import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Typography
} from "@mui/material";

import {useConfirmUserMutation, useGetUserInfoQuery} from "../api/user";
import {authContext} from "../components/AuthProvider";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";
import {enqueueSnackbar} from "notistack";

export const LoginConfirmUserPage = () => {
    const navigate = useNavigate();
    const {data, isSuccess, isError, error} = useGetUserInfoQuery();
    const [confirmUser, confirmStatus] = useConfirmUserMutation();
    const authCtx = useRef(useContext(authContext));

    useEffect(() => {
        if (isSuccess) {
            authCtx.current.setAuthData({
                firstname: data.firstName,
                admin: data.admin,
                profileComplete: data.profileComplete,
                hasConfirmed: data.status !== 'NOT_CONFIRMED',
            });
        }
    }, [isSuccess, data, authCtx]);

    const handleApprove = () => {
        if (isSuccess) {
            confirmUser().unwrap().then(() => {
                authCtx.current.setAuthData({
                    firstname: data.firstName,
                    admin: data.admin,
                    profileComplete: data.profileComplete,
                    hasConfirmed: true
                });
                enqueueSnackbar(t('profile.message.updated'), {variant: "success"});
                navigate('/');
            }).catch(() => {
            });
        }
    };

    if (authCtx.current.auth?.data?.hasConfirmed) {
        return <Navigate to={'/'} />;
    }

    return (
        <Container sx={{my: {xs: 0, md: 0}, px: {xs: 2, md: 3}}}>
            <h2>{t('front.title', {name: data?.firstName})}</h2>

            {isError && <ErrorAlert title={t('apiError.getUserInfo')} error={error}/>}
            {confirmStatus.isError && <ErrorAlert title={t('apiError.confirmUser')} error={confirmStatus.error}/>}

            <Grid container>
                <Grid item xs={12} md={12} component={Card} margin={1}>
                    <Alert severity={'warning'} variant={'outlined'}>
                        <AlertTitle>{t('front.newUser.title')}</AlertTitle>
                        <CardContent>
                            <Typography>
                                {t('front.newUser.text1')}
                            </Typography>
                            <Typography>
                                {t('front.newUser.text2')}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleApprove} variant={'outlined'}>
                                {t('generic.button.approve')}
                            </Button>
                        </CardActions>
                    </Alert>
                </Grid>
            </Grid>
        </Container>
    );
};

import api from './index';

export type QuestionType = 'TEXT' | 'PHOTO_QUESTION' | 'PHOTO_ANSWERS';

interface Subject {
    id: number | null;
}

export interface Question {
    id: number;
    disabled: boolean;
    replacedById?: number;
    title: string;
    titleImage: string;
    difficulty: string;
    subject: Subject;
    questionType: QuestionType;
    option1: string;
    option2: string;
    option3?: string;
    option4?: string;
    option5?: string;
    option6?: string;
    correctOption: number;
    explanation: string;
    rule: string;
    quizCount: number;
}

export interface ReplaceQuestion {
    replaceId: number;
    newQuestion: Partial<Question>;
}

const question = api.injectEndpoints({
    endpoints: (build) => ({
        getQuestions: build.query<Question[], void>({
            query: () => 'admin/question/all',
            providesTags: (result) => result
                ? [...result.map(({id}) => ({type: 'Question' as const, id})), {type: 'Question', id: 'LIST'}]
                : [{type: 'Question', id: 'LIST'}]
        }),
        addQuestion: build.mutation<Question, Partial<Question>>({
            query: (body) => ({
                url: 'admin/question',
                method: 'post',
                body
            }),
            invalidatesTags: ['Question', 'Subject', 'SubjectStats']
        }),
        updateQuestion: build.mutation<void, Pick<Question, 'id'> & Partial<Question>>({
            query: ({id, ...patch}) => ({
                url: `admin/question/${id}`,
                method: 'PUT',
                body: patch
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Question', id}, 'Subject', 'SubjectStats']
        }),
        deleteQuestion: build.mutation<void, number>({
            query: (id) => ({
                url: `admin/question/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, id) => [{type: 'Question', id}, 'Subject', 'SubjectStats']
        }),
        replaceQuestion: build.mutation<Question, ReplaceQuestion>({
            query: ({replaceId, newQuestion}) => ({
                url: `admin/question/${replaceId}/replace`,
                method: 'POST',
                body: newQuestion
            }),
            invalidatesTags: ['Question', 'Subject', 'SubjectStats']
        }),
        uploadQuestionsFile: build.mutation({
            query: (body) => ({
                url: `admin/question/upload`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Question', 'Subject', 'SubjectStats']
        })
    }),
    overrideExisting: false,
});

export const {
    useGetQuestionsQuery,
    useAddQuestionMutation, useUpdateQuestionMutation,
    useDeleteQuestionMutation, useReplaceQuestionMutation,
    useUploadQuestionsFileMutation
} = question;

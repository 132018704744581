import {useSearchParams} from "react-router-dom";

import {Alert, Box, Button, Container, Link, Stack, Typography} from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';

import {t} from "../i18n/i18n";

export const LoginPage = () => {
    const [searchParams] = useSearchParams();

    return (
        <Container sx={{mb: 4}}>
            {searchParams.has('failed') && <Alert severity={'error'}>{t('login.error.failed')}</Alert>}
            {searchParams.has('error') && <Alert severity={'error'}>{t('login.error.unknownError')}</Alert>}

            <h1>
                {t('login.header')}
            </h1>

            <Stack spacing={3}>
                <Typography variant={'subtitle1'}>
                    {t('login.subtitle')}
                </Typography>

                <Box>
                    <Button
                        variant={'contained'}
                        startIcon={<LoginIcon/>}
                        href={'/login_perform'}
                    >{t('login.button.login')}</Button>
                </Box>

                <Box>
                    <Typography variant={'body1'}>
                        {t('login.idrettensid.notRegistered')}:
                    </Typography>

                    <Link href="https://signup.nif.no/" rel="noreferrer" target="_blank">
                        {t('login.idrettensid.getStarted')}
                    </Link>

                    <Typography variant={'body2'}>
                        {t('login.idrettensid.itsFree')}
                    </Typography>
                </Box>
            </Stack>
        </Container>
    );
};

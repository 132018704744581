import {Box, Button, Paper, Stack, styled, Typography} from "@mui/material";

import {useGetOngoingQuizQuery} from "../api/quizexecution";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

const StyledPaper = styled(Paper)(() => ({
    padding: '6px',
    height: 'calc(100vh - 12px)',
    width: 'calc(100vw - 12px)',
}));

export const QuizOngoing = () => {
    const {data, isSuccess, isError, error} = useGetOngoingQuizQuery();

    return (
        <StyledPaper>
            {isError && <ErrorAlert title={t('apiError.quizOngoing')} error={error} />}

            {isSuccess && [
                <Stack key={'main'} spacing={2} justifyContent={'space-between'} sx={{marginBottom: 10}}>
                    <h1 style={{textAlign: 'center'}}>{t('quiz.ongoing.title')}</h1>
                    <Box>
                        <Stack spacing={2} alignItems={'center'}>
                            <Typography>{t('quiz.ongoing.instructions')}</Typography>
                            {}
                        </Stack>
                    </Box>
                </Stack>,

                <Paper key={'buttons'} sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <Stack padding={1} alignItems={'center'}>
                        <Button
                            variant={'contained'}
                            href={'/quizrun/' + data.executionId}
                            color={'success'}
                            size={'large'}
                            sx={{height: 60, width: {xs: '100%', md: 500}}}
                            disabled={!isSuccess}
                        >{t('quiz.ongoing.button.continue')}</Button>
                    </Stack>
                </Paper>
            ]}
        </StyledPaper>
    );
};

import i18n from 'i18next';
import { initReactI18next} from 'react-i18next';

import nb from './norsk.json';

const nsTranslate = 'ns.translate';

const loadLanguage = () => {
    const languages = { nb };

    Object.keys(languages).forEach((lang) => {
        type ObjectKey = keyof typeof languages;
        i18n.addResourceBundle(lang, nsTranslate, languages[lang as ObjectKey], true);
    });
};

i18n.use(initReactI18next);
if (!i18n.isInitialized && i18n.options.lng === undefined) {
    i18n.init({
        lng: 'nb',
        fallbackLng: 'nb',
        debug: false,
        ns: [nsTranslate],
        defaultNS: nsTranslate,
        interpolation: {
            escapeValue: false
        }
    });
}

loadLanguage();

export default i18n;

export const t = (t: string, arg?: any) => {
    return i18n.t(t, arg) as string;
};

import {ReactElement, SyntheticEvent, useState} from "react";
import moment from "moment/moment";

import {Chip, Fab, Stack, Zoom} from "@mui/material";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRenderCellParams,
    GridRowId,
    GridRowParams,
    GridRowsProp,
    MuiEvent,
    nbNO
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import {useGetAllNewsQuery} from "../../api/news";
import {useGetAllUserGroupsAdminQuery, UserGroupInfo} from "../../api/usergroups";
import {ErrorAlert} from "../../components/ErrorAlert";
import {EditNews, EditNewsDialog} from "../../components/admin/EditNewsDialog";
import {groupsFilterOperators, groupsSortComparatorUserGroups} from "./utils";
import {t} from "src/i18n/i18n";

export const NewsPage = () => {
    const {data, isSuccess, isError, error} = useGetAllNewsQuery();
    const {
        data: groupsData,
        isSuccess: groupsSuccess,
        isError: groupsIsError,
        error: groupsError,
    } = useGetAllUserGroupsAdminQuery();
    const [editNews, setEditNews] = useState<EditNews>({ showDialog: false });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleAddNew = () => {
        setEditNews({ showDialog: true });
    }

    const handleEditRow = (id: GridRowId) => () => {
        const newsItem = (data || []).find(e => e.id === id);
        if (newsItem !== undefined) {
            setEditNews({
                showDialog: true,
                news: newsItem,
            });
        }
    }

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.news.list.header.actions'),
            width: 100,
            getActions: ({id}) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label={'Edit'}
                        className={'textPrimary'}
                        onClick={handleEditRow(id)}
                        color={'inherit'}
                    />
                ];
            }
        },
        {
            field: 'created',
            headerName: t('admin.news.list.header.date'),
            width: 150,
            valueFormatter: ({value}) => moment(value).format('YYYY-MM-DD HH:mm')
        },
        {
            field: 'userGroups',
            headerName: t('admin.news.list.header.userGroups'),
            width: 350,
            type: 'singleSelect',
            valueOptions: (groupsData || [])
                .map(g => ({
                    value: g.id,
                    label: g.title ? `${g.title} (${g.shortname})` : '-'
                }))
                .sort((a, b) => a.label.localeCompare(b.label, 'no')),
            renderCell: (params: GridRenderCellParams<any, UserGroupInfo[]>) => {
                if (params.value !== undefined && params.value.length > 0) {
                    const el: ReactElement[] = [];
                    const groups = [...params.value];
                    groups.sort((a, b) => a.shortname.localeCompare(b.shortname, 'no'));
                    for (const group of groups) {
                        el.push(
                            <Chip
                                key={group.id}
                                label={group.shortname}
                                title={group.title}
                                size={'small'}
                                variant={'outlined'}
                            />
                        );
                    }

                    return <Stack direction={'row'} spacing={1}>{el}</Stack>;
                }
            },
            sortComparator: groupsSortComparatorUserGroups,
            filterOperators: groupsFilterOperators
        },
        {
            field: 'title',
            headerName: t('admin.news.list.header.title'),
            flex: 1
        }
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.news')} error={error} />}
            {groupsIsError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={groupsError} />}

            <EditNewsDialog
                editNews={editNews}
                callbackClose={() => setEditNews({ showDialog: false })}
            />

            <DataGrid
                autoHeight
                editMode={'row'}
                columns={cols}
                loading={!isSuccess || !groupsSuccess}
                rows={(data || []) as GridRowsProp}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
            />

            <div className={'fabArea'}>
                <Zoom in unmountOnExit>
                    <Fab
                        color={'primary'}
                        className={'fabButton'}
                        aria-label={t('generic.button.addNew')}
                        onClick={handleAddNew}
                    ><AddIcon/></Fab>
                </Zoom>
            </div>
        </>
    );
};

import {ReactNode} from "react";

import {CardActionArea, CardActions, CardContent, Chip, Divider, Typography} from "@mui/material";

interface MyPropsGroup {
    id: number;
    title?: string;
}

interface MyProps {
    title: string;
    date: string;
    text: string | ReactNode;
    groups: MyPropsGroup[];
}

export const NewsCard = ({title, date, text, groups}: MyProps) => {
    return (
        <>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant={'h5'} component={'div'}>
                        {title}
                    </Typography>
                    <Typography variant={'caption'}>
                        {date}
                    </Typography>
                    <Divider/>
                    <br/>
                    {typeof text === "string" ? (
                    <Typography variant={'body2'}>
                        {text}
                    </Typography>) : text}
                </CardContent>
                <CardActions>
                    {groups.length > 0 && groups.map(group => (
                        <Chip key={group.id} label={group.title}/>
                    ))}
                </CardActions>
            </CardActionArea>
        </>
    )
};

import React from 'react';
import {createRoot} from "react-dom/client";
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';

import {createTheme, ThemeProvider} from "@mui/material";

import {App} from './App';
import {store} from './store';
import AuthProvider from "./components/AuthProvider";

import './index.css';

const theme = createTheme();
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router>
                    <AuthProvider>
                        <App/>
                    </AuthProvider>
                </Router>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);

import {createContext, useEffect, useState} from "react";

export const authContext = createContext({
    auth: {} as AuthState | null, setAuthData: (data: AuthData | null) => {
    }
});

export interface AuthData {
    firstname: string;
    admin: boolean;
    profileComplete: boolean;
    hasConfirmed: boolean;
}

interface AuthState {
    loading: boolean;
    firstTime?: boolean;
    data: AuthData | null;
}

const AuthProvider = ({children}: { children: JSX.Element }) => {
    const [auth, setAuth] = useState<AuthState>({loading: true, firstTime: true, data: null});

    const setAuthData = (data: AuthData | null) => {
        if (data === null) {
            setAuth({loading: true, data: null});
        } else {
            setAuth({loading: false, data: data});
        }
    };

    useEffect(() => {
        const storedAuthData = window.localStorage.getItem('authData');
        const storedAuthDataJson = storedAuthData !== null ? JSON.parse(storedAuthData) : null;
        const authDataString = auth.data !== null ? JSON.stringify(auth.data) : null;

        if (auth.firstTime) {
            if (storedAuthDataJson !== null) {
                setAuth({loading: false, data: storedAuthDataJson});
            } else {
                setAuth({loading: true, data: null});
            }
        } else if (authDataString !== null && authDataString !== storedAuthData) {
            window.localStorage.setItem('authData', authDataString);
        } else if (auth.data === null && storedAuthData !== null) {
            window.localStorage.clear();
        }
    }, [auth]);

    return (
        <authContext.Provider value={{auth, setAuthData}}>
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

import {Question} from "../../api/question";
import {Subject} from "../../api/subject";
import {t} from "../../i18n/i18n";

export interface ViewDialogData {
    showDialog: boolean;
    question?: Question;
}

interface Props {
    viewQuestion: ViewDialogData;
    callbackClose: () => void;
}

export const ViewQuestionDialog = ({
    viewQuestion,
    callbackClose,
}: Props) => {
    function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
        return key in obj;
    }

    const formatValue = (key: string, value: any) => {
        if (['created'].indexOf(key) >= 0) {
            return value.substring(0, 19).replace('T', ' ');
        }

        if (['disabled'].indexOf(key) >= 0) {
            return value ? t('generic.yes') : t('generic.no');
        }

        if (key === 'subject') {
            return value !== undefined && value !== null ? (value as Subject).title : '-';
        }

        if (key === 'difficulty') {
            switch (value) {
                case 'EASY': return t('generic.questionDifficultyLevel.easy');
                case 'MEDIUM': return t('generic.questionDifficultyLevel.medium');
                case 'HARD': return t('generic.questionDifficultyLevel.hard');
            }
        }

        if (key === 'questionType') {
            switch (value) {
                case 'TEXT': return t('generic.questionType.text');
                case 'PHOTO': return t('generic.questionType.photo');
            }
        }

        if (key === 'title') {
            return <Typography variant={'body1'} sx={{ fontSize: 20 }}>{value}</Typography>;
        }

        return value;
    };

    const formatLabel = (key: string, question: Question) => {
        if (key === 'title') {
            return <Typography variant={'body1'} sx={{ fontSize: 20 }}>{t('admin.question.view.' + key) + ':'}</Typography>
        }

        if (key === 'option' + question.correctOption) {
            return <>
                {t('admin.question.view.' + key) + ': '}
                <CheckCircleSharpIcon key={'correctOption'} color={'success'} sx={{ fontSize: 16 }} />
            </>;
        }

        return t('admin.question.view.' + key) + ':';
    };

    return (
        <Dialog
            open={viewQuestion.showDialog}
            onClose={callbackClose}
            maxWidth={'lg'}
            fullWidth
            scroll={'paper'}
        >
            <DialogTitle>{t('admin.question.view.dialogTitle', { id: viewQuestion.question?.id})}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    {[
                        'title', 'titleImage',
                        'disabled', 'replacedById',
                        'difficulty', 'subject',
                        'questionType',
                        'option1', 'option2', 'option3', 'option4', 'option5', 'option6',
                        'explanation', 'rule',
                        'quizCount'
                    ].map((e) => {
                        if (viewQuestion.question !== undefined && hasKey(viewQuestion.question, e)) {
                            return ([
                                <Grid key={e + '-label'} item sm={3}>{formatLabel(e, viewQuestion.question)}</Grid>,
                                <Grid key={e + '-value'} item sm={9}>{formatValue(e, viewQuestion.question[e])}</Grid>
                            ]);
                        }

                        return [];
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={callbackClose}>
                    {t('generic.button.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import {useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";

import {
    Box, Button, Checkbox, Chip, CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel, ListItemText, MenuItem,
    OutlinedInput, Select, SelectChangeEvent
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

import {UserInfo, useUpdateUserAdditionalMutation} from "../../api/user";
import {useGetAllUserGroupsAdminQuery, UserGroupInfo} from "../../api/usergroups";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

const formInitialState: UserInfo = {
    id: null,
    status: 'ACTIVE',
    firstName: '',
    fullName: '',
    birthDate: '',
    email: '',
    bpIdSub: '',
    handicapMin: 0,
    userGroup: undefined,
    additionalUserGroups: [],
    admin: false,
    readTermsOfUse: false,
    profileComplete: false,
    created: '',
    lastLogin: '',
    lastUpdatedNif: ''
}

export interface EditUser {
    showDialog: boolean;
    user?: UserInfo;
}

interface Props {
    editUser: EditUser;
    callbackClose: () => void;
}

export const EditUserView = ({
    editUser,
    callbackClose,
}:Props) => {
    const [updateUserAdditional, updateUserStatus] = useUpdateUserAdditionalMutation();
    const {data: groupsData, isSuccess: groupsSuccess} = useGetAllUserGroupsAdminQuery();
    const [formData, setFormData] = useState<UserInfo>(formInitialState);

    useEffect(() => {
        if (editUser.showDialog && groupsSuccess && editUser.user !== undefined) {
            const addGrps: UserGroupInfo[] = editUser.user.additionalUserGroups.map(
                aug => groupsData.find(g => g.id === aug.id)
            ).filter((aug): aug is UserGroupInfo => !!aug);

            setFormData(() => ({
                ...formInitialState,
                ...editUser.user,
                additionalUserGroups: addGrps
            }));
        }
    }, [editUser, groupsSuccess, groupsData]);

    const handleSave = () => {
        updateUserAdditional(formData).unwrap().then(() => {
            setFormData(formInitialState);
            enqueueSnackbar(t('snackbar.user.updated'), { variant: "success" });
            callbackClose();
        });
    }

    const handleChangeAdditionalGroups = (event: SelectChangeEvent<UserGroupInfo[]>) => {
        const liste = event.target.value as UserGroupInfo[];

        setFormData({
            ...formData,
            additionalUserGroups: [...liste]
        });
    }

    const sortedGroups = [...(groupsData ?? [])];
    sortedGroups.sort((a, b) => a.title.localeCompare(b.title, 'no'));

    return (
        <Dialog open={editUser.showDialog} onClose={callbackClose} maxWidth={'lg'} fullWidth>
            <DialogTitle>{t('admin.user.edit.title')}</DialogTitle>

            {updateUserStatus.isError && <ErrorAlert title={t('apiError.updateUser')} error={updateUserStatus.error} />}

            {!groupsSuccess && <CircularProgress />}

            <DialogContent>
                <DialogContentText>
                    {t('admin.user.edit.description')}
                </DialogContentText>
                <Box component={'form'}
                     sx={{
                         display: 'grid',
                         gridTemplateColumns: {sm: '1fr'},
                         gap: 2,
                         marginTop: '2em'
                     }}
                     noValidate
                     autoComplete={'off'}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={'user-name'}>{t('admin.user.form.fullName')}</InputLabel>
                        <OutlinedInput
                            id={'user-name'}
                            disabled
                            label={t('admin.user.form.fullName')}
                            value={formData.fullName}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={'user-birthdate'}>{t('admin.user.form.birthdate')}</InputLabel>
                        <OutlinedInput
                            id={'profile-birthdate'}
                            disabled
                            label={t('admin.user.form.birthdate')}
                            value={formData.birthDate ?? '-'}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={'profile-email'}>{t('admin.user.form.email')}</InputLabel>
                        <OutlinedInput
                            id={'profile-email'}
                            disabled
                            label={t('admin.user.form.email')}
                            value={formData.email}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={'profile-handicapmin'}>{t('admin.user.form.handicapMin')}</InputLabel>
                        <OutlinedInput
                            id={'profile-handicapmin'}
                            label={t('admin.user.form.handicapMin')}
                            value={formData.handicapMin}
                            type={'number'}
                            inputProps={{ min: 0, max: 90 }}
                            onChange={e => setFormData({
                                ...formData,
                                handicapMin: e.target.value as unknown as number
                            })}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={'profile-usergroup'}>{t('admin.user.form.userGroup')}</InputLabel>
                        <OutlinedInput
                            id={'profile-usergroup'}
                            disabled
                            label={t('admin.user.form.userGroup')}
                            value={formData.userGroup?.title ?? ''}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel
                            id={'profile-additional-groups-label'}>{t('admin.user.form.additionalGroups')}</InputLabel>
                        <Select
                            id={'profile-additional-groups'}
                            labelId={'profile-additional-groups-label'}
                            fullWidth
                            multiple
                            value={formData.additionalUserGroups}
                            onChange={handleChangeAdditionalGroups}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                            renderValue={(selected: UserGroupInfo[]) => {
                                const sortedSelected = [...selected];
                                sortedSelected.sort((a, b) => a.title.localeCompare(b.title, 'no'));

                                return (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {sortedSelected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.title}
                                            />
                                        ))}
                                    </Box>
                                )
                            }}
                        >
                            {(sortedGroups || []).map((group) => (
                                <MenuItem
                                    key={group.id}
                                    value={group as any}
                                    disabled={group.id === formData.userGroup?.id}
                                >
                                    <Checkbox checked={
                                        group.id === formData.userGroup?.id ||
                                        formData.additionalUserGroups.map(g => g.id).indexOf(group.id) >= 0
                                    }/>
                                    <ListItemText primary={group.title}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    disabled={!groupsSuccess || updateUserStatus.isLoading}
                    loading={updateUserStatus.isLoading}
                    variant={'contained'}
                    color={'success'}
                    size={'large'}
                    endIcon={<SendIcon/>}
                    onClick={handleSave}
                >
                    {t('generic.button.save')}
                </LoadingButton>
                <Button onClick={callbackClose}>{t('generic.button.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

import api from './index';

interface ResultInfo {
    executionId: number;
    title: string;
    start: string;
    result: string;
    timespent: string;
    correctAsPercent: number;
    score: number;
    totalQuestions: number;
}

interface ResultDetailed {
    executionId: number;
    userId: number;
    fullName: string;
    userEmail: string;
    title: string;
    start: string;
    timespent: string;
    result: string;
    correctAsPercent: number;
    score: number;
    totalQuestions: number;
    subjectAnalysis: SubjectAnalysis[],
    sheet: QuestionAnswerDetails[]
}

interface SubjectAnalysis {
    subject: string;
    partPercent: number;
    numQuestions: number;
    score: number;
    maxScore: number;
    scorePercent: number;
    secondsSpent: number;
}

interface QuestionAnswerDetails {
    questionNumberInExecution: number;
    title: string;
    correctAnswerString: string;
    userAnswerString: string;
    score: number;
    secondsSpent: number;
}

const results = api.injectEndpoints({
    endpoints: (build) => ({
        getResults: build.query<ResultInfo[], void>({
            query: () => 'result/my',
        }),
        getUserResults: build.query<ResultInfo[], number>({
            query: (userId) => `admin/result/user/${userId}/all?limit=999`,
        }),
        getResult: build.query<ResultInfo, number>({
            query: (executionId) => `result/${executionId}`
        }),
        getDetailedResult: build.query<ResultDetailed, number>({
            query: (executionId) => `admin/result/detailed/${executionId}`
        })
    }),
    overrideExisting: false,
});

export const {
    useGetResultsQuery,
    useGetUserResultsQuery,
    useGetResultQuery,
    useGetDetailedResultQuery
} = results;

import {useContext, useEffect, useRef, useState} from "react";

import {CircularProgress} from "@mui/material";
import {authContext} from "../components/AuthProvider";

export const LoginOngoingPage = () => {
    const [fetchingUrl, setFetchingUrl] = useState<boolean>(false);
    const calledOnce = useRef(false);
    const authCtx = useRef(useContext(authContext));

    const fetchUrl = async () => {
        const response = await fetch('/oauth2/authorization/nif');
        if (response.ok) {
            const {redirectUrl} = await (response.json());
            window.location.replace(redirectUrl);
        } else {
            window.location.replace('/login?error')
        }
    }

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }

        if (!fetchingUrl && authCtx.current.auth?.data === null) {
            calledOnce.current = true;
            setFetchingUrl(true);
            fetchUrl()
                .catch(console.error);
        }
    }, [fetchingUrl, authCtx.current.auth]);


    return <CircularProgress/>;
};

import moment from "moment";

import {Chip, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import {useGetDetailedResultQuery} from "../../api/results";
import MiniInfoCard from "../MiniInfoCard";
import {t} from "../../i18n/i18n";
import {ErrorAlert} from "../ErrorAlert";

export interface Props {
    executionId: number;
}
export const ResultExecutionView = ({ executionId }: Props) => {
    const {data, isSuccess, isError, error} =
        useGetDetailedResultQuery(executionId);

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.resultView')} error={error} />}

            {!isSuccess && <CircularProgress />}

            {isSuccess && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <h2>{data.title}</h2>
                        <Typography>
                            <strong>{t('admin.executionView.user')}:</strong> {data.fullName}
                        </Typography>
                        <Typography>
                            <strong>{t('admin.executionView.userEmail')}:</strong> {data.userEmail}
                        </Typography>
                        <Typography>
                            <strong>{t('admin.executionView.startTime')}:</strong> {moment(data.start).format('YYYY-MM-DD HH:mm')}
                        </Typography>
                        <Typography>
                            <strong>{t('admin.executionView.duration')}:</strong> {data.timespent}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MiniInfoCard
                            title={t('admin.executionView.result')}
                            color={data.result === 'PASSED' ? '#4f4' : '#f44'}
                            bodyMinHeight={'75px'}
                        >
                            <>
                                <Typography fontWeight={700}
                                            fontSize={'140%'}>{data.score} / {data.totalQuestions} ({data.correctAsPercent}%)</Typography>
                                <Chip
                                    label={data.result}
                                    sx={{
                                        backgroundColor: data.result === 'PASSED' ? '#4f4' : '#f44',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </>
                        </MiniInfoCard>
                    </Grid>

                    <Grid item xs={12}>
                        <h3>{t('admin.executionView.subjectAnalysis.title')}</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} size={'small'} aria-label={'Answer sheet'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('admin.executionView.subjectAnalysis.subject')}</TableCell>
                                        <TableCell>{t('admin.executionView.subjectAnalysis.part')}</TableCell>
                                        <TableCell>{t('admin.executionView.subjectAnalysis.questions')}</TableCell>
                                        <TableCell>{t('admin.executionView.subjectAnalysis.scoreAndMax')}</TableCell>
                                        <TableCell>{t('admin.executionView.subjectAnalysis.scorePercent')}</TableCell>
                                        <TableCell>{t('admin.executionView.subjectAnalysis.timeSpent')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {data.subjectAnalysis.map((row) => (
                                        <TableRow
                                            key={row.subject}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component={'th'} scope={'row'}>
                                                {row.subject}
                                            </TableCell>
                                            <TableCell>
                                                {row.partPercent} %
                                            </TableCell>
                                            <TableCell>
                                                {row.numQuestions}
                                            </TableCell>
                                            <TableCell>
                                                {row.score} / {row.maxScore}
                                            </TableCell>
                                            <TableCell>
                                                {row.scorePercent} %
                                            </TableCell>
                                            <TableCell>
                                                {row.secondsSpent}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <h3>{t('admin.executionView.answerSheet.title')}</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} size={'small'} aria-label={'Answer sheet'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('admin.executionView.answerSheet.sheet.number')}</TableCell>
                                        <TableCell>{t('admin.executionView.answerSheet.sheet.question')}</TableCell>
                                        <TableCell align={'right'}>{t('admin.executionView.answerSheet.sheet.timeSpent')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {data.sheet.map((row) => (
                                        <TableRow
                                            key={row.title}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component={'th'} scope={'row'} sx={{verticalAlign: 'top'}}>
                                                {row.questionNumberInExecution}
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{paddingBottom: 1}}>
                                                    {row.title}
                                                </Typography>
                                                <Typography sx={{color: row.score > 0 ? '#4b4' : '#b44'}}>
                                                    {row.userAnswerString}
                                                </Typography>
                                                {row.score === 0 && (<>
                                                    <Typography>
                                                        <strong>{t('admin.executionView.answerSheet.sheet.correctAnswer')}:</strong> {row.correctAnswerString}
                                                    </Typography>
                                                </>)}
                                            </TableCell>
                                            <TableCell align={'right'} sx={{verticalAlign: 'top'}}>
                                                {row.score > 0
                                                    ? (<CheckIcon sx={{color: '#4b4'}}/>)
                                                    : (<ClearIcon sx={{color: '#b44'}}/>)
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

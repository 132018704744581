import {Grid} from "@mui/material";

import {t} from "../../i18n/i18n";
import {UserGroupInfo} from "../../api/usergroups";
import {UserInfo} from "../../api/user";

export interface ViewUser {
    user: UserInfo;
}

export const UserView = (viewUser: ViewUser) => {
    function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
        return key in obj;
    }

    const formatValue = (key: string, value: any) => {
        if (['created', 'lastLogin', 'lastUpdatedNif'].indexOf(key) >= 0) {
            return value !== null ? value.substring(0, 19).replace('T', ' ') : '';
        }

        if (['admin', 'profileComplete', 'readTermsOfUse'].indexOf(key) >= 0) {
            return value ? t('generic.yes') : t('generic.no');
        }

        if (key === 'userGroup') {
            return value !== undefined && value !== null ? (value as UserGroupInfo).title : '-';
        }

        if (key === 'additionalUserGroups') {
            const addgroups = [...(value as UserGroupInfo[])];
            addgroups.sort((a, b) => a.title.localeCompare(b.title, 'no'));
            return addgroups.map((g) => g.title).join(', ');
        }

        return value;
    };

    return (
        <Grid container spacing={2}>
            {[
                'email', 'birthDate', 'handicapMin',
                'created', 'lastLogin', 'lastUpdatedNif',
                'userGroup', 'additionalUserGroups',
                'profileComplete', 'admin', 'readTermsOfUse'
            ].map((e) => {
                if (viewUser.user !== undefined && hasKey(viewUser.user, e)) {
                    return ([
                        <Grid key={e + '-label'} item sm={3}>{t('admin.user.view.' + e)}:</Grid>,
                        <Grid key={e + '-value'} item sm={9}>{formatValue(e, viewUser.user[e])}</Grid>
                    ]);
                }

                return [];
            })}
        </Grid>
    );
};

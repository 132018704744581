import {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowsProp} from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { green, red, yellow } from '@mui/material/colors';

import {useGetAllUserGroupsAdminQuery} from "../../api/usergroups";
import {useGetAllQuizesQuery} from "../../api/quiz";
import {
    ReportQuizQuestionStats,
    ReportQuizQuestionStatsQuery,
    useGetReportQuizQuestionStatsMutation
} from "../../api/reports";
import {ErrorAlert} from "../../components/ErrorAlert";
import {getSortedQuizListNewestFirst} from "./utils";
import {t} from "../../i18n/i18n";

const formInitialState = {
    userGroupId: null as number | null,
    quizId: null
}

export const ReportQuizQuestionStatsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {data: groupsData, isSuccess: groupsSuccess, isError: groupsIsError, error: groupsError} = useGetAllUserGroupsAdminQuery();
    const {data: quizData, isSuccess: quizSuccess, isError: quizIsError, error: quizError} = useGetAllQuizesQuery();
    const [getReportQuizQuestionStats, reportStatus] = useGetReportQuizQuestionStatsMutation();
    const [formData, setFormData] = useState<ReportQuizQuestionStatsQuery>(formInitialState);
    const [searching, setSearching] = useState(false);
    const [searchData, setSearchData] = useState<ReportQuizQuestionStats[] | null>(null);
    const calledOnce = useRef(false);

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }

        calledOnce.current = true;

        let newFormData = {
            ...formData
        };

        if (searchParams.get('quizId') !== null) {
            newFormData = {
                ...newFormData,
                quizId: searchParams.get('quizId') as unknown as number
            };
        }

        if (searchParams.get('userGroupId') !== null) {
            newFormData = {
                ...newFormData,
                userGroupId: searchParams.get('userGroupId') as unknown as number
            };
        }

        setFormData(newFormData);
    }, []);

    let searchQuizId = searchParams.get('quizId');
    let searchUserGroupId = searchParams.get('userGroupId');
    useEffect(() => {
        if (searchQuizId !== null && searchUserGroupId !== null) {
            setSearching(true);
            getReportQuizQuestionStats({
                quizId: searchQuizId as unknown as number,
                userGroupId: searchUserGroupId as unknown as number
            }).unwrap().then((data) => {
                setSearching(false);
                setSearchData(data);
            }).catch((error) => {
                setSearching(false);
                setSearchData(null);
            });
        }
    }, [getReportQuizQuestionStats, searchQuizId, searchUserGroupId]);

    if (!groupsSuccess || !quizSuccess) {
        return <CircularProgress/>;
    }

    const handleSearch = () => {
        setSearchParams({
            quizId: formData.quizId as unknown as string,
            userGroupId: formData.userGroupId as unknown as string
        });
    }

    // const handleViewRow = (id: GridRowId) => () => {
    //     navigate('/admin/reports/execution/' + id);
    // }

    const cols: GridColDef[] = [
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     cellClassName: 'actions',
        //     headerName: 'Actions',
        //     width: 100,
        //     getActions: ({id}) => {
        //         return [
        //             <GridActionsCellItem
        //                 icon={<PreviewIcon/>}
        //                 label={'View'}
        //                 className={'textPrimary'}
        //                 onClick={handleViewRow(id)}
        //                 color={'inherit'}
        //             />
        //         ];
        //     }
        // },
        {
            field: 'id',
            headerName: t('admin.report.quizQuestionStatistics.list.header.id'),
            width: 100,
            valueGetter: (params) => {
                return params.row.questionNumberInQuiz + ' (#' + params.row.question.id + ')';
            }
        },
        {
            field: 'question',
            headerName: t('admin.report.quizQuestionStatistics.list.header.question'),
            width: 800,
            flex: 1,
            valueGetter: (params) => {
                return params.row.question.title;
            }
        },
        {
            field: 'score',
            headerName: t('admin.report.quizQuestionStatistics.list.header.correctAnswers'),
            width: 100,
            valueGetter: (params) => {
                return params.row.correctHits + ' / ' + params.row.totalHits;
            },
        },
        {
            field: '.',
            headerName: t('admin.report.quizQuestionStatistics.list.header.percent'),
            width: 100,
            valueGetter: (params): number => (
                params.row.correctHits * 100 / params.row.totalHits
            ),
            renderCell: (params: GridRenderCellParams<any, number>) => {
                const percent = params.value || 0;

                return (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress
                            variant="determinate"
                            value={percent}
                            size={48}
                            sx={{
                                color: percent >= 80 ? green[600] : percent >= 40 ? yellow[600] : red[500],
                            }}
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                            >{Number(percent).toLocaleString("nb-NO", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%</Typography>
                        </Box>
                    </Box>
                );
            }
        }
    ];

    const sortedQuizListNewestFirst = getSortedQuizListNewestFirst(quizData);

    return (
        <>
            <Box
                component={'form'}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {sm: '1fr'},
                    gap: 2
                }}
            >
                {groupsIsError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={groupsError} />}
                {quizIsError && <ErrorAlert title={t('apiError.getQuizesInfo')} error={quizError} />}
                {reportStatus.isError && <ErrorAlert title={t('apiError.report')} error={reportStatus.error} />}

                <h2>{t('admin.report.quizQuestionStatistics.title')}</h2>

                <FormControl required error={formData.quizId === null && searchParams.get('quizId') === null}>
                    <InputLabel id={'quiz-label'}>{t('admin.report.quizQuestionStatistics.form.quiz')}</InputLabel>
                    <Select
                        id={'quiz'}
                        labelId={'quiz-label'}
                        fullWidth
                        value={formData.quizId ?? searchParams.get('quizId') ?? ''}
                        onChange={e => setFormData({
                            ...formData,
                            quizId: e.target.value as unknown as number
                        })}
                    >
                        {sortedQuizListNewestFirst.map((quiz) => (
                            <MenuItem
                                key={quiz.id}
                                value={quiz.id}
                            >
                                {quiz.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl required error={formData.userGroupId === null && searchParams.get('userGroupId') === null}>
                    <InputLabel id={'group-label'}>{t('admin.report.quizQuestionStatistics.form.region')}</InputLabel>
                    <Select
                        id={'group'}
                        labelId={'group-label'}
                        fullWidth
                        value={formData.userGroupId ?? searchParams.get('userGroupId') ?? ''}
                        onChange={e => setFormData({
                            ...formData,
                            userGroupId: e.target.value as unknown as number
                        })}
                    >
                        {groupsData.map((group) => (
                            <MenuItem
                                key={group.id}
                                value={group.id}
                            >
                                {group.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Stack direction={'row'} spacing={1} sx={{mb: 1}}>
                    <LoadingButton
                        disabled={formData.userGroupId === null || formData.quizId === null}
                        loading={searching}
                        variant={'contained'}
                        color={'success'}
                        size={'large'}
                        endIcon={<SendIcon/>}
                        onClick={handleSearch}
                    >{t('generic.button.search')}</LoadingButton>
                </Stack>
            </Box>
            {searching && <CircularProgress/>}
            {!searching && searchData !== null && <DataGrid
                autoHeight
                columns={cols}
                rows={searchData as GridRowsProp}
                getRowId={(row) => row.question.id}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }]
                    }
                }}
            />}
        </>
    );
}

import {SyntheticEvent, useState} from "react";
import {enqueueSnackbar} from "notistack";

import {Fab, Tooltip, Zoom} from "@mui/material";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    MuiEvent,
    nbNO
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {useGetSubjectsQuery, useUpdateSubjectMutation} from "../../api/subject";
import {useGetUserInfoQuery} from "../../api/user";
import {isSystemAdmin} from "../../util/AdminUtil";
import {EditQuestionDialog} from "../../components/admin/EditQuestionDialog";
import {ErrorAlert} from "../../components/ErrorAlert";
import {AddSubjectDialog} from "../../components/admin/AddSubjectDialog";
import FormProvider from "../../components/admin/forms/QuestionFormContext";
import {t} from "src/i18n/i18n";

interface AddQuestionDialogData {
    open: boolean;
    formValues?: object;
}

export const SubjectsPage = () => {
    const {data: userInfo} = useGetUserInfoQuery();
    const {data, isSuccess, isError, error} = useGetSubjectsQuery();
    const [updateSubject] = useUpdateSubjectMutation();
    const [addOpen, setAddOpen] = useState(false);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [addQuestionDialogData, setAddQuestionDialogData] = useState<AddQuestionDialogData>({ open: false });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleAddNew = () => {
        setAddOpen(true);
    }

    const handleEditRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.Edit}
        });
    }

    const handleSaveRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View}
        });
    }

    const processRowUpdate = (row: GridRowModel) => {
        updateSubject({
            id: row.id,
            title: row.title
        }).unwrap().then(() => {
            enqueueSnackbar(t('snackbar.subject.updated'), { variant: "success" });
        });
        return row;
    }

    const handleCancelRow = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });
    }

    const handleAddQuestion = (id: GridRowId) => () => {
        setAddQuestionDialogData({
            open: true,
            formValues: {
                subject: { id }
            }
        });
    };

    const handleCloseAddQuestion = () => {
        setAddQuestionDialogData({
            open: false
        });
    }

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.subject.list.header.actions'),
            width: 100,
            getActions: ({id}) => {
                if (!isSystemAdmin(userInfo)) {
                    return [];
                }

                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label={t('generic.button.save')}
                            onClick={handleSaveRow(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label={t('generic.button.cancel')}
                            className={'textPrimary'}
                            onClick={handleCancelRow(id)}
                            color={'inherit'}
                        />
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label={t('generic.button.edit')}
                        className={'textPrimary'}
                        onClick={handleEditRow(id)}
                        color={'inherit'}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title={t('admin.subject.list.action.addQuestion')}><AddIcon/></Tooltip>}
                        label={t('admin.subject.list.action.addQuestion')}
                        onClick={handleAddQuestion(id)}
                        color={'inherit'}
                    />
                ]
            }
        },
        {
            field: 'title',
            headerName: t('admin.subject.list.header.name'),
            flex: 1,
            editable: true
        },
        {
            field: 'questions',
            headerName: t('admin.subject.list.header.questions'),
            type: 'number',
            width: 90,
            editable: false
        }
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={error} />}

            <AddSubjectDialog
                showDialog={addOpen}
                callbackClose={() => setAddOpen(false)}
            />

            <FormProvider>
                <EditQuestionDialog
                    dialogOpen={addQuestionDialogData.open}
                    formValues={addQuestionDialogData.formValues}
                    closeCallback={handleCloseAddQuestion}
                />
            </FormProvider>

            <DataGrid
                loading={!isSuccess}
                autoHeight
                editMode={'row'}
                columns={cols}
                rows={(data || []) as GridRowsProp}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'title', sort: 'asc' }]
                    }
                }}
            />

            {isSystemAdmin(userInfo) && (
                <div className={'fabArea'}>
                    <Zoom in unmountOnExit>
                        <Fab
                            color={'primary'}
                            className={'fabButton'}
                            aria-label={t('generic.button.addNew')}
                            onClick={handleAddNew}
                        ><AddIcon/></Fab>
                    </Zoom>
                </div>
            )}
        </>
    );
};

import api from './index';

interface QuizInvite {
    id: number;
    title: string;
    durationMinutes: number;
    numberOfQuestions: number;
    start: string;
    end: string;
    attemptsLeft: number;
}

const quizinvite = api.injectEndpoints({
    endpoints: (build) => ({
        getQuizInvites: build.query<QuizInvite[], void>({
            query: () => 'quiz/available',
        }),
    }),
    overrideExisting: false,
});

export const {useGetQuizInvitesQuery} = quizinvite;

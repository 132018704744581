import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

import {t} from '../i18n/i18n';

export interface ConfirmationDialogProps {
    id: string;
    title: string;
    description?: string;
    open: boolean;
    onClose: (accept: boolean) => void;
}

export const ConfirmationDialog = ({title, description, onClose, open, ...other}: ConfirmationDialogProps) => {

    const handleCancel = () => {
        onClose(false);
    }

    const handleOk = () => {
        onClose(true);
    }

    return (
        <Dialog
            maxWidth={'xs'}
            open={open}
            {...other}
        >
            <DialogTitle>{title}</DialogTitle>
            {description && <DialogContent>{description}</DialogContent>}
            <DialogActions>
                <Button onClick={handleOk}>{t('generic.button.ok')}</Button>
                <Button autoFocus onClick={handleCancel}>{t('generic.button.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

import api from './index';
import {QuestionType} from "./question";

interface QuizExecutionInfo {
    executionId: number;
    title: string;
    instructions: string;
    numberOfQuestions: number;
    durationMinutes: number;
}

interface QuizExecutionStep {
    executionId: number;
    questionNumber: number;
    totalQuestions: number;
    secondsLeft: number;
    question: string;
    questionImage: string;
    questionType: QuestionType;
    choices: Answer[];
    nextQuestionNumber?: number;
}

interface Answer {
    id: number;
    text: string;
}

interface QuizExecutionFinish {
    executionId: number;
    title: string;
    endMessage: string;
    start: string;
    timespent: string;
    result: string;
    correctAsPercent: number;
    correctAnswers: number;
    totalQuestions: number;
    attemptsLeft: number;
    quizId: number;
}

interface QuizExecutionStepParams {
    executionId: number;
    questionNumber?: number;
}

interface QuizExecutionStepAnswer {
    executionId: number;
    questionNumber: number;
    answer: number;
}

const quizexecution = api.injectEndpoints({
    endpoints: (build => ({
        infoQuiz: build.query<QuizExecutionInfo, number>({
            query: (quizId) => `quizrun/startinfo/${quizId}`
        }),
        startQuiz: build.query<QuizExecutionStep, number>({
            query: (quizId) => `quizrun/start/${quizId}`
        }),
        getQuizStep: build.query<QuizExecutionStep, QuizExecutionStepParams>({
            query: ({executionId, questionNumber}) => questionNumber !== undefined ?
                `quizrun/${executionId}/${questionNumber}` : `quizrun/${executionId}`
        }),
        updateQuizStepAnswer: build.mutation<void, Pick<QuizExecutionStepAnswer, 'executionId'> & Partial<QuizExecutionStepAnswer>>({
            query: (body) => ({
                url: `quizrun/${body.executionId}/answer`,
                method: 'PUT',
                body
            })
        }),
        endQuiz: build.query<QuizExecutionFinish, number>({
            query: (executionId) => `quizrun/${executionId}/end`
        }),
        getOngoingQuiz: build.query<QuizExecutionInfo, void>({
            query: () => 'quizrun/ongoing'
        })
    }))
});

export const {
    useInfoQuizQuery, useStartQuizQuery, useGetQuizStepQuery, useEndQuizQuery,
    useGetOngoingQuizQuery,
    useUpdateQuizStepAnswerMutation
} = quizexecution;
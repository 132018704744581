import {useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";

import {
    Backdrop,
    Box, Button, Checkbox, Chip,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel, ListItemText, MenuItem,
    OutlinedInput,
    Select, SelectChangeEvent,
    TextField
} from "@mui/material";

import {NewsItem, useAddNewsMutation, useUpdateNewsMutation} from "../../api/news";
import {useGetAllUserGroupsAdminQuery, UserGroupInfo} from "../../api/usergroups";
import {useGetUserInfoQuery} from "../../api/user";
import {isSystemAdmin} from "../../util/AdminUtil";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

const formInitialState = {
    id: undefined as undefined | number,
    title: '',
    text: '',
    userGroups: [] as UserGroupInfo[]
}

export interface EditNews {
    showDialog: boolean;
    news?: NewsItem;
}

interface Props {
    editNews: EditNews;
    callbackClose: () => void;
}

export const EditNewsDialog = ({
    editNews,
    callbackClose,
}: Props) => {
    const {data: userInfo} = useGetUserInfoQuery();
    const {data: userGroups, isSuccess: userGroupsSuccess} = useGetAllUserGroupsAdminQuery();
    const [addNewsItem, addStatus] = useAddNewsMutation();
    const [updateNewsItem, updateStatus] = useUpdateNewsMutation();
    const [formData, setFormData] = useState(formInitialState);

    useEffect(() => {
        if (editNews.showDialog && editNews.news !== undefined) {
            setFormData(f => ({
                ...f,
                ...editNews.news
            }));
        }
    }, [editNews]);

    const handleSave = () => {
        if (formData.id === undefined) {
            addNewsItem(formData).unwrap().then(() => {
                setFormData(formInitialState);
                callbackClose();
            });
        } else {
            // @ts-ignore
            updateNewsItem(formData).unwrap().then(() => {
                setFormData(formInitialState);
                enqueueSnackbar(t('snackbar.news.updated'), { variant: "success" });
                callbackClose();
            });
        }
    };

    const handleChangeUserGroups = (event: SelectChangeEvent<UserGroupInfo[]>) => {
        const liste = event.target.value as UserGroupInfo[];

        setFormData({
            ...formData,
            userGroups: [...liste]
        });
    }

    const saveAllowed = () => {
        return formData.title.trim().length > 0 &&
            formData.text.trim().length > 0 &&
            (isSystemAdmin(userInfo) || formData.userGroups.length > 0);
    };

    const handleCancel = () => {
        setFormData(formInitialState);
        callbackClose();
    };

    const userGroupsSorted = [...(userGroups ?? [])];
    userGroupsSorted.sort((a, b) => a.title.localeCompare(b.title, 'no'));

    return (
        <Dialog open={editNews.showDialog} onClose={callbackClose} maxWidth={'lg'} fullWidth>
            {addStatus.isError && <ErrorAlert title={t('apiError.addNews')} error={addStatus.error} />}
            {updateStatus.isError && <ErrorAlert title={t('apiError.updateNews')} error={updateStatus.error} />}

            {!userGroupsSuccess && <CircularProgress />}

            <DialogTitle>{formData.id !== undefined
                ? t('admin.news.list.edit.title') + formData.id
                : t('admin.news.list.add.title')}</DialogTitle>

            <DialogContent>
                <Box component={'form'}
                     sx={{
                         display: 'grid',
                         gridTemplateColumns: {sm: '1fr'},
                         gap: 2
                     }}
                     noValidate
                     autoComplete={'off'}>
                    <FormControl fullWidth>
                        <TextField
                            id={'title'}
                            margin={'dense'}
                            label={t('admin.news.form.title')}
                            fullWidth
                            required
                            error={formData.title.trim().length === 0}
                            variant={'standard'}
                            value={formData.title}
                            onChange={e => setFormData({...formData, title: e.target.value})}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            id={'text'}
                            margin={'dense'}
                            label={t('admin.news.form.title')}
                            multiline
                            minRows={5}
                            fullWidth
                            required
                            error={formData.text.trim().length === 0}
                            variant={'standard'}
                            value={formData.text}
                            onChange={e => setFormData({...formData, text: e.target.value})}
                        />
                    </FormControl>
                    <FormControl
                        required={!isSystemAdmin(userInfo)}
                        error={!isSystemAdmin(userInfo) && formData.userGroups.length === 0}
                    >
                        <InputLabel id={'profile-groups-label'}>{t('admin.news.form.userGroups')}</InputLabel>
                        <Select
                            id={'profile-groups'}
                            labelId={'profile-groups-label'}
                            fullWidth
                            multiple
                            value={formData.userGroups}
                            onChange={handleChangeUserGroups}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                            renderValue={(selected) => {
                                const selectedSorted = [...selected];
                                selectedSorted.sort((a, b) => a.title.localeCompare(b.title, 'no'));
                                return (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {selectedSorted.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.title}
                                            />
                                        ))}
                                    </Box>
                                )
                            }}
                        >
                            {userGroupsSorted.map((group) => (
                                <MenuItem
                                    key={group.id}
                                    value={group as any}
                                >
                                    <Checkbox checked={formData.userGroups.map(g => g.id)
                                        .indexOf(group.id) >= 0}/>
                                    <ListItemText primary={group.title}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={!saveAllowed() || addStatus.isLoading || updateStatus.isLoading}
                >{t('generic.button.save')}</Button>
                <Button onClick={handleCancel}>{t('generic.button.cancel')}</Button>
            </DialogActions>
            <Backdrop open={addStatus.isLoading || updateStatus.isLoading}>
                <CircularProgress/>
            </Backdrop>
        </Dialog>
    );
};

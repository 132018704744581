import React, {useContext} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {authContext} from "./AuthProvider";

export function RequireAuth({children}: { children: JSX.Element }) {
    const location = useLocation();
    const {auth} = useContext(authContext);

    if (auth?.loading) {
        return <></>;
    }

    if (auth?.data !== null) {
        return children;
    }

    return <Navigate to='/login_perform' state={{from: location}} replace/>;
}

export function ShowIfAuthAndProfileComplete({children}: { children: JSX.Element }) {
    const {auth} = useContext(authContext);

    if (auth?.data !== null && auth?.data.profileComplete) {
        return children;
    }

    return <></>;
}

export function ShowIfAuth({children}: { children: JSX.Element }) {
    const {auth} = useContext(authContext);

    if (auth?.data !== null) {
        return children;
    }

    return <></>;
}

export function AuthUserFirstname() {
    const {auth} = useContext(authContext);

    if (auth !== null && auth.data !== null) {
        return <>{auth.data.firstname}</>;
    }

    return <></>;
}

export function ShowIfAdmin({children}: { children: JSX.Element }) {
    const {auth} = useContext(authContext);

    if (auth?.data !== null && auth?.data?.admin === true) {
        return children;
    }

    return <></>;
}

export function ShowIfNotAuth({children}: { children: JSX.Element }) {
    const {auth} = useContext(authContext);

    if (auth?.data !== null) {
        return <></>;
    }

    return children;
}

import {Box, Card, Typography} from "@mui/material";

const MiniInfoCard = ({
                          title,
                          icon,
                          color = '#88f',
                          bodyMinHeight,
                          children
                      }: {
    title: string,
    icon?: JSX.Element,
    color?: string,
    bodyMinHeight?: string,
    children: JSX.Element
}) => {
    return (
        <Card style={{minWidth: '120px', border: '2px solid', borderColor: color}}>
            <Box sx={{p: 1, backgroundColor: color, minHeight: '30px', textAlign: 'center'}}>
                <Typography fontWeight={700} fontSize={'110%'}>{icon} {title}</Typography>
            </Box>
            <Box sx={{p: 2, minHeight: bodyMinHeight}} textAlign={'center'}>
                {children}
            </Box>
        </Card>
    );
};

export default MiniInfoCard;

import {
    getGridSingleSelectOperators,
    GridCellParams,
    GridColDef,
    GridComparatorFn,
    GridFilterItem,
    ValueOptions
} from "@mui/x-data-grid";

import {UserGroupInfo} from "../../api/usergroups";
import {Quiz} from "../../api/quiz";
import {GroupsColumn} from "./UsersPage";

export const groupsSortComparator: GridComparatorFn<ValueOptions> = (opt1, opt2) => {
    if (typeof opt1 === 'object' && 'label' in opt1 && typeof opt2 === 'object' && 'label' in opt2) {
        return opt1.label.localeCompare(opt2.label, 'no');
    } else if (typeof opt1 === 'string' && typeof opt2 === 'string') {
        return opt1.localeCompare(opt2, 'no');
    }

    return 0;
}

export const groupsSortComparatorUserGroups: GridComparatorFn<UserGroupInfo[]> = (groups1, groups2) => {
    const a = groups1.length === 0 ? '' : groups1[0].shortname ?? '';
    const b = groups2.length === 0 ? '' : groups2[0].shortname ?? '';
    return a.localeCompare(b, 'no');
}

export const groupsSortComparatorUserGroupColumn: GridComparatorFn<GroupsColumn> = (groups1, groups2) => {
    const a = groups1.mainGroup?.shortname ?? '';
    let b = groups2.mainGroup?.shortname ?? '';
    return a.localeCompare(b, 'no');
}

export const groupsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === 'isAnyOf')
    .map((operator) => {
        const newOperator = { ...operator };
        newOperator.getApplyFilterFn = (filterItem: GridFilterItem, _: GridColDef) => {
            return (params: GridCellParams<any, UserGroupInfo[]>): boolean => {
                if (filterItem?.value === undefined || filterItem?.value?.length === 0 || params.value === undefined) {
                    return true;
                }

                let isOk = false;
                params.value.forEach((item: UserGroupInfo) => {
                    isOk = isOk || filterItem.value.includes(item.id);
                });
                return isOk;
            }
        };
        return newOperator;
    });

export const groupsFilterOperatorsUsers = getGridSingleSelectOperators()
    .filter((operator) => operator.value === 'isAnyOf' || operator.value === 'not')
    .map((operator) => {
        const newOperator = { ...operator };
        newOperator.getApplyFilterFn = (filterItem: GridFilterItem, _: GridColDef) => {
            return (params: GridCellParams<any, GroupsColumn>): boolean => {
                if (filterItem?.value === undefined || filterItem?.value?.length === 0 || params.value === undefined) {
                    return true;
                }

                if (operator.value === 'isAnyOf') {
                    let isOk = filterItem.value.includes(params.value.mainGroup.id);
                    params.value.additionalGroups.forEach((item: UserGroupInfo) => {
                        isOk = isOk || filterItem.value.includes(item.id);
                    });
                    return isOk;
                } else if (operator.value === 'not') {
                    if (filterItem.value === params.value.mainGroup.id) {
                        return false;
                    }

                    return !params.value.additionalGroups.map((item: UserGroupInfo) => item.id)
                        .includes(filterItem.value);
                }

                return true;
            }
        };
        return newOperator;
    });

interface IdTitle {
    id: number;
    title: string;
}

export const getSortedQuizListNewestFirst = (quizData: Quiz[]): IdTitle[] => {
    return quizData
        .map((quiz) => ({
            id: quiz.id,
            title: quiz.title
        }))
        .sort((a, b) => b.id - a.id);
};

import api from './index';

export const images = api.injectEndpoints({
    endpoints: (build) => ({
        getAllFilenames: build.query<string[], void>({
            query: () => 'image/all',
            providesTags: ['Images']
        }),
        uploadImage: build.mutation<void, File[]>({
            query: (data) => {
                const formData = new FormData();
                data.map((image) => formData.append('image', image));

                return {
                    url: 'image',
                    method: 'POST',
                    body: formData,
                    formData: true
                }
            },
            invalidatesTags: ['Images']
        }),
    }),
    overrideExisting: false
});

export const {
    useGetAllFilenamesQuery,
    useUploadImageMutation
} = images;

import {Box, Button, Chip, CircularProgress, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import TimeSpentIcon from "@mui/icons-material/WatchLater";

import {useEndQuizQuery} from "../api/quizexecution";
import {useParams} from "react-router-dom";
import MiniInfoCard from "../components/MiniInfoCard";
import moment from "moment/moment";
import {t} from "../i18n/i18n";
import {ErrorAlert} from "../components/ErrorAlert";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: '6px',
    height: 'calc(100vh - 12px)',
    width: 'calc(100vw - 12px)',
}));

export const QuizEndPage = () => {
    const {quizrunid} = useParams();
    const {data, isSuccess, isLoading, isError, error} = useEndQuizQuery(quizrunid !== undefined ? quizrunid as unknown as number : 0);

    return (
        <StyledPaper>
            {isError && <ErrorAlert title={t('apiError.quizEnd')} error={error} />}

            {isLoading && <CircularProgress />}

            {isSuccess && [
                <Stack key={'main'} spacing={2} justifyContent={'space-between'} sx={{marginBottom: 10}}>
                    <h1 style={{textAlign: 'center'}}>{t('quiz.end.title')}</h1>

                    <Box>
                        <Grid container spacing={2} alignItems={'flex-start '} justifyContent={'center'}>
                            <Grid item xs={12} textAlign={'center'}>
                                <h2>{data.title}</h2>
                            </Grid>

                            <Grid item md={3} sx={{display: {xs: 'none', md: 'block'}}}/>

                            <Grid item xs={12} md={6}>
                                <Box sx={{border: '1px dotted grey'}}>
                                    <Typography textAlign={'center'} sx={{p: 2}}>
                                        {data.endMessage}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item md={3} sx={{display: {xs: 'none', md: 'block'}}}/>

                            <Grid item xs={6} md={2}>
                                <MiniInfoCard title={t('result.timeSpent')} icon={<TimeSpentIcon/>} bodyMinHeight={'75px'}>
                                    <Typography textAlign={'center'}>{data.timespent}</Typography>
                                </MiniInfoCard>
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <MiniInfoCard
                                    title={t('result.title')}
                                    color={data.result === 'PASSED' ? '#4f4' : '#f44'}
                                    bodyMinHeight={'75px'}
                                >
                                    <>
                                        <Typography fontWeight={700}
                                                    fontSize={'140%'}>{data.correctAnswers} / {data.totalQuestions} ({data.correctAsPercent}%)</Typography>
                                        <Chip
                                            label={data.result}
                                            sx={{
                                                backgroundColor: data.result === 'PASSED' ? '#4f4' : '#f44',
                                                fontWeight: 'bold'
                                            }}
                                        />
                                    </>
                                </MiniInfoCard>
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <MiniInfoCard title={t('result.dateStarted')} bodyMinHeight={'75px'}>
                                    <Typography
                                        textAlign={'center'}>{moment(data.start).format('YYYY-MM-DD HH:mm')}</Typography>
                                </MiniInfoCard>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>,

                <Paper key={'buttons'} sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <Stack padding={1} alignItems={'center'}>
                        <Stack direction={'row'} padding={0}>
                            <Button
                                disabled={data.attemptsLeft !== undefined && data.attemptsLeft !== null && data.attemptsLeft === 0}
                                variant={'contained'}
                                href={'/quizinfo/' + data.quizId}
                                color={'primary'}
                                size={'large'}
                                sx={{height: 60, width: {xs: '100%', md: 500}}}
                                startIcon={<ReplayIcon/>}
                            >{data.attemptsLeft !== undefined && data.attemptsLeft !== null ?
                                t('generic.button.tryAgainAttempts', { attemptsleft: data.attemptsLeft}) : t('generic.button.tryAgain')
                            }</Button>
                            &nbsp;
                            <Button
                                variant={'contained'}
                                href={'/results'}
                                color={'success'}
                                size={'large'}
                                sx={{height: 60, width: {xs: '100%', md: 500}}}
                            >{t('generic.button.finished')}</Button>
                        </Stack>
                    </Stack>
                </Paper>
            ]}
        </StyledPaper>
    );
};

import {NavLink, useParams} from "react-router-dom";
import moment from "moment";

import {Box, Breadcrumbs, Chip, CircularProgress, Container, Grid, Typography} from "@mui/material";
import TimeSpentIcon from "@mui/icons-material/WatchLater";

import {useGetResultQuery} from "../api/results";
import MiniInfoCard from "../components/MiniInfoCard";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

export const ResultPage = () => {
    const {quizrunid} = useParams();
    const {data, isSuccess, isLoading, isError, error} = useGetResultQuery(quizrunid as unknown as number);

    return (
        <Container sx={{my: {xs: 0, md: 0}, px: {xs: 2, md: 3}}}>
            <Breadcrumbs aria-label={'breadcrumb'} style={{ marginBottom: '12px' }}>
                <NavLink to={'/results'}>{t('result.link.resultsPage')}</NavLink>
                {isSuccess && <Typography color={'text.primary'}>
                    {moment(data.start).format('YYYY-MM-DD HH:mm')} - {data.title}
                </Typography>}
            </Breadcrumbs>

            <Box>
                <Grid container spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
                    {isError && <ErrorAlert title={t('apiError.resultView')} error={error} />}

                    {isLoading && <CircularProgress />}

                    {isSuccess && [
                        <Grid key={'title'} item xs={12} textAlign={'center'}>
                            <h2>{data.title}</h2>
                        </Grid>,

                        <Grid key={'timespent'} item xs={6} md={2}>
                            <MiniInfoCard title={t('result.timeSpent')} icon={<TimeSpentIcon/>} bodyMinHeight={'75px'}>
                                <Typography textAlign={'center'}>{data.timespent}</Typography>
                            </MiniInfoCard>
                        </Grid>,

                        <Grid key={'result'} item xs={6} md={2}>
                            <MiniInfoCard
                                title={t('result.title')}
                                color={data.result === 'PASSED' ? '#4f4' : '#f44'}
                                bodyMinHeight={'75px'}
                            >
                                <>
                                    <Typography fontWeight={700}
                                                fontSize={'140%'}>{data.score} / {data.totalQuestions} ({data.correctAsPercent}%)</Typography>
                                    <Chip
                                        label={t('result.status.' + data.result)}
                                        sx={{
                                            backgroundColor: data.result === 'PASSED' ? '#4f4' : '#f44',
                                            fontWeight: 'bold'
                                        }}
                                    />
                                </>
                            </MiniInfoCard>
                        </Grid>,

                        <Grid key={'started'} item xs={6} md={2}>
                            <MiniInfoCard title={t('result.dateStarted')} bodyMinHeight={'75px'}>
                                <Typography
                                    textAlign={'center'}>{moment(data.start).format('YYYY-MM-DD HH:mm')}</Typography>
                            </MiniInfoCard>
                        </Grid>
                    ]}
                </Grid>
            </Box>
        </Container>
    );
};

import {Button, Card, CardActions, CardMedia} from "@mui/material";
import {t} from "../../../i18n/i18n";

interface Props {
    id?: string;
    filename?: string;
    onSelectImage: () => void;
}

export const ImageInputField = ({
    id,
    filename,
    onSelectImage,
}: Props) => {

    return (
        <Card id={id} sx={{ maxWidth: '400px', width: '400px' }} >
            {filename !== undefined && filename.length > 0 && (
                <CardMedia component={'img'} image={'/api/image/thumb/' + filename} />
            )}
            <CardActions>
                <Button onClick={onSelectImage}>{t('generic.button.selectImage')}</Button>
            </CardActions>
        </Card>
    );
}

import {ChangeEvent, useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import moment from "moment/moment";

import {
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack, Tooltip
} from "@mui/material";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowsProp
} from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import SendIcon from "@mui/icons-material/Send";

import {useGetAllUserGroupsAdminQuery} from "../../api/usergroups";
import {useGetAllQuizesQuery} from "../../api/quiz";
import {ReportQuizGroup, ReportQuizGroupQuery, useGetReportQuizGroupMutation} from "../../api/reports";
import {ErrorAlert} from "../../components/ErrorAlert";
import {getSortedQuizListNewestFirst} from "./utils";
import {t} from "../../i18n/i18n";

const formInitialState = {
    userGroupId: null as number | null,
    quizId: null,
    onlyBestResult: true
}

export const ReportQuizUserGroupPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {data: groupsData, isSuccess: groupsSuccess, isError: groupsIsError, error: groupsError} =
        useGetAllUserGroupsAdminQuery();
    const {data: quizData, isSuccess: quizSuccess, isError: quizIsError, error: quizError} = useGetAllQuizesQuery();
    const [getReportQuizGroup, reportStatus] = useGetReportQuizGroupMutation();
    const [formData, setFormData] = useState<ReportQuizGroupQuery>(formInitialState);
    const [searching, setSearching] = useState(false);
    const [searchData, setSearchData] = useState<ReportQuizGroup | null>(null);
    const navigate = useNavigate();
    const calledOnce = useRef(false);

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }

        calledOnce.current = true;

        let newFormData = {
            ...formData
        };

        if (searchParams.get('quizId') !== null) {
            newFormData = {
                ...newFormData,
                quizId: searchParams.get('quizId') as unknown as number
            };
        }

        if (searchParams.get('userGroupId') !== null) {
            newFormData = {
                ...newFormData,
                userGroupId: searchParams.get('userGroupId') as unknown as number
            };
        }

        if (searchParams.get('onlyBestResult') !== null) {
            newFormData = {
                ...newFormData,
                onlyBestResult: searchParams.get('onlyBestResult') === 'true'
            };
        }

        setFormData(newFormData);
    }, []);


    let searchQuizId = searchParams.get('quizId');
    let searchUserGroupId = searchParams.get('userGroupId');
    let searchOnlyBestResult = searchParams.get('onlyBestResult');
    useEffect(() => {
        if (searchQuizId !== null && searchUserGroupId !== null) {
            setSearching(true);
            getReportQuizGroup({
                quizId: searchQuizId as unknown as number,
                userGroupId: searchUserGroupId as unknown as number,
                onlyBestResult: searchOnlyBestResult === 'true'
            }).unwrap().then((data) => {
                setSearching(false);
                setSearchData(data);
            }).catch((error) => {
                setSearching(false);
                setSearchData(null);
            });
        }
    }, [searchQuizId, searchUserGroupId, searchOnlyBestResult, getReportQuizGroup]);

    const handleSearch = () => {
        setSearchParams({
            quizId: formData.quizId as unknown as string,
            userGroupId: formData.userGroupId as unknown as string,
            onlyBestResult: formData.onlyBestResult ? 'true' : 'false'
        });
    }

    const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, onlyBestResult: e.target.checked});
    }

    const handleViewRow = (id: GridRowId) => () => {
        navigate('/admin/reports/execution/' + id);
    }

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.report.quizUserGroup.list.header.actions'),
            width: 100,
            getActions: ({id}) => {
                return [
                    <GridActionsCellItem
                        icon={<PreviewIcon/>}
                        label={'View'}
                        className={'textPrimary'}
                        onClick={handleViewRow(id)}
                        color={'inherit'}
                    />
                ];
            }
        },
        {
            field: 'fullName',
            headerName: t('admin.report.quizUserGroup.list.header.name'),
            width: 500,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return <span>
                    <Tooltip title={params.row.email}>
                        <EmailOutlinedIcon color={'disabled'} fontSize={'small'} />
                    </Tooltip>
                    &nbsp;
                    {params.value}
                </span>;
            }
        },
        {
            field: 'start',
            headerName: t('admin.report.quizUserGroup.list.header.start'),
            width: 200,
            valueFormatter: params => params?.value === null ? '-' : moment(params?.value).format('YYYY-MM-DD HH:mm')
        },
        {
            field: 'timespent',
            headerName: t('admin.report.quizUserGroup.list.header.timeSpent'),
            width: 120
        },
        {
            field: 'score',
            headerName: t('admin.report.quizUserGroup.list.header.score'),
            width: 80
        },
        {
            field: 'result',
            headerName: t('admin.report.quizUserGroup.list.header.result'),
            width: 100
        }
    ];

    const sortedQuizListNewestFirst = getSortedQuizListNewestFirst(quizData ?? []);

    return (
        <>
            <Box
                component={'form'}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {sm: '1fr'},
                    gap: 2
                }}
            >
                {groupsIsError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={groupsError} />}
                {quizIsError && <ErrorAlert title={t('apiError.getQuizesInfo')} error={quizError} />}
                {reportStatus.isError && <ErrorAlert title={t('apiError.report')} error={reportStatus.error} />}

                <h2>{t('admin.report.quizUserGroup.title')}</h2>

                <FormControl required error={formData.quizId === null && searchParams.get('quizId') === null}>
                    <InputLabel id={'quiz-label'}>{t('admin.report.quizUserGroup.form.quiz')}</InputLabel>
                    <Select
                        id={'quiz'}
                        labelId={'quiz-label'}
                        fullWidth
                        value={formData.quizId ?? searchParams.get('quizId') ?? ''}
                        onChange={e => setFormData({
                            ...formData,
                            quizId: e.target.value as unknown as number
                        })}
                    >
                        {sortedQuizListNewestFirst.map((quiz) => (
                            <MenuItem
                                key={quiz.id}
                                value={quiz.id}
                            >
                                {quiz.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl required error={formData.userGroupId === null && searchParams.get('userGroupId') === null}>
                    <InputLabel id={'group-label'}>{t('admin.report.quizUserGroup.form.region')}</InputLabel>
                    <Select
                        id={'group'}
                        labelId={'group-label'}
                        fullWidth
                        value={formData.userGroupId ?? searchParams.get('userGroupId') ?? ''}
                        onChange={e => setFormData({
                            ...formData,
                            userGroupId: e.target.value as unknown as number
                        })}
                    >
                        {(groupsData || []).map((group) => (
                            <MenuItem
                                key={group.id}
                                value={group.id}
                            >
                                {group.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControlLabel control={
                    <Checkbox
                        onChange={handleCheckBox}
                        checked={formData.onlyBestResult}
                    />
                } label={t('admin.report.quizUserGroup.form.onlyBestResult')}/>

                <Stack direction={'row'} spacing={1} sx={{mb: 1}}>
                    <LoadingButton
                        disabled={!groupsSuccess || !quizSuccess || formData.userGroupId === null || formData.quizId === null}
                        loading={searching}
                        variant={'contained'}
                        color={'success'}
                        size={'large'}
                        endIcon={<SendIcon/>}
                        onClick={handleSearch}
                    >{t('generic.button.search')}</LoadingButton>
                </Stack>
            </Box>
            {searching && <CircularProgress/>}
            {!searching && searchData !== null && <DataGrid
                autoHeight
                columns={cols}
                rows={searchData.rows as GridRowsProp}
                getRowId={(row) => row.executionId}
            />}
        </>
    );
}

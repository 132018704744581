import api from './index';
import {UserGroupInfo} from "./usergroups";

export interface NewsItem {
    id: number;
    title: string;
    text: string;
    userGroups: UserGroupInfo[];
    created: string;
    createdBy: string;
}

const news = api.injectEndpoints({
    endpoints: (build) => ({
        getLatestNews: build.query<NewsItem[], void>({
            query: () => 'news/latest',
            providesTags: (result) => result
                ? [...result.map(({id}) => ({type: 'LatestNews' as const, id})), {type: 'LatestNews', id: 'LIST'}]
                : [{type: 'LatestNews', id: 'LIST'}]
        }),
        getAllNews: build.query<NewsItem[], void>({
            query: () => 'admin/news',
            providesTags: (result) => result
                ? [...result.map(({id}) => ({type: 'News' as const, id})), {type: 'News', id: 'LIST'}]
                : [{type: 'News', id: 'LIST'}]
        }),
        addNews: build.mutation<NewsItem, Partial<NewsItem>>({
            query: (body) => ({
                url: 'admin/news',
                method: 'POST',
                body
            }),
            invalidatesTags: ['LatestNews', 'News']
        }),
        updateNews: build.mutation<void, Pick<NewsItem, 'id'> & Partial<NewsItem>>({
            query: ({id, ...patch}) => ({
                url: `admin/news/${id}`,
                method: 'PUT',
                body: patch
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'LatestNews', id}, {type: 'News', id}]
        })
    }),
    overrideExisting: false
});

export const {
    useGetLatestNewsQuery,
    useGetAllNewsQuery, useAddNewsMutation, useUpdateNewsMutation
} = news;

import {configureStore} from "@reduxjs/toolkit";

import api from "./api";

const reducer = {
    [api.reducerPath]: api.reducer,
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production',
})
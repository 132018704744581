import api from './index';

interface BuildProperties {
    group: string;
    artifact: string;
    name: string;
    time: string;
    version: string;
}

interface SystemInfo {
    application: string;
    session: string;
    buildProperties: BuildProperties;
}

const info = api.injectEndpoints({
    endpoints: (build) => ({
        getSystemInfo: build.query<SystemInfo, void>({
            query: () => 'info'
        })
    }),
    overrideExisting: false
});

export const {
    useGetSystemInfoQuery,
} = info;

import {ReactElement, SyntheticEvent, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip} from "@mui/material";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRenderCellParams,
    GridRowId,
    GridRowParams,
    GridRowsProp,
    GridToolbar,
    MuiEvent,
    nbNO
} from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import ListAltIcon from '@mui/icons-material/ListAlt';
import PreviewIcon from '@mui/icons-material/Preview';

import {useGetAllUserGroupsAdminQuery, UserGroupInfo} from "../../api/usergroups";
import {useGetAllUsersQuery, useGetUserInfoQuery} from "../../api/user";
import {isSystemAdmin} from "../../util/AdminUtil";
import {groupsFilterOperatorsUsers, groupsSortComparatorUserGroupColumn} from "./utils";
import {ErrorAlert} from "../../components/ErrorAlert";
import {UserView} from "../../components/admin/UserView";
import {UserResultsListView} from "../../components/admin/UserResultsListView";
import {ResultExecutionView} from "../../components/admin/ResultExecutionView";
import {EditUser, EditUserView} from "src/components/admin/EditUserView";
import {t} from "src/i18n/i18n";

interface dialogAction {
    label: string;
    onClick: () => void;
}

interface DialogInfo {
    show: boolean;
    title?: string;
    content?: any;
    actions?: dialogAction[];
}

export interface GroupsColumn {
    mainGroup: UserGroupInfo;
    additionalGroups: UserGroupInfo[];
}

export const UsersPage = () => {
    const [searchParams] = useSearchParams();
    const { data: loggedInUserInfo } = useGetUserInfoQuery();
    const {data, isSuccess, isError, error} = useGetAllUsersQuery();
    const {
        data: groupsData,
        isSuccess: groupsSuccess,
        isError: groupsIsError,
        error: groupsError,
    } = useGetAllUserGroupsAdminQuery();
    const [dialogInfo, setDialogInfo] = useState<DialogInfo>({ show: false });
    const [editUser, setEditUser] = useState<EditUser>({ showDialog: false });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEdit = (id: GridRowId) => () => {
        const user = (data || []).find(e => e.id === id);
        if (user !== undefined) {
            setEditUser({
                showDialog: true,
                user
            });
        }
    }

    const handleView = (id: GridRowId) => () => {
        const user = (data || []).find(e => e.id === id);
        if (user !== undefined) {
            setDialogInfo({
                show: true,
                title: t('admin.user.view.title', { name: user.fullName }),
                content: <UserView user={user} />
            });
        }
    }

    const handleViewResultExecution = (executionId: number, userGridRowId: GridRowId) => {
        return setDialogInfo({
            show: true,
            content: <ResultExecutionView executionId={executionId} />,
            actions: [{
                label: t('admin.user.results.action.toList'),
                onClick: handleListResults(userGridRowId)
            }]
        })
    }

    const handleListResults = (id: GridRowId) => () => {
        const user = (data || []).find(e => e.id === id);
        if (user !== undefined && user.id !== null) {
            const userId: number = user.id;
            setDialogInfo({
                show: true,
                title: t('admin.user.results.title', { name: user.fullName }),
                content: <UserResultsListView
                    userId={userId}
                    viewResultCallback={(executionId) => handleViewResultExecution(executionId, id)}
                />
            });
        }
    }

    const rowActions = (id: GridRowId) => {
        const actions = [
            <GridActionsCellItem
                icon={<Tooltip title={t('admin.user.table.menu.viewTooltip')}><PreviewIcon/></Tooltip>}
                label={t('admin.user.table.menu.view')}
                className={'textPrimary'}
                onClick={handleView(id)}
                color={'inherit'}
            />
        ];

        if (isSystemAdmin(loggedInUserInfo)) {
            actions.push(
                <GridActionsCellItem
                    icon={<Tooltip title={t('admin.user.table.menu.editUsersTooltip')}><EditIcon/></Tooltip>}
                    label={t('admin.user.table.menu.editUsers')}
                    onClick={handleEdit(id)}
                    color={'inherit'}
                />
            );

            actions.push(
                <GridActionsCellItem
                    icon={<Tooltip title={t('admin.user.table.menu.listResultsTooltip')}><ListAltIcon/></Tooltip>}
                    label={t('admin.user.table.menu.listResults')}
                    onClick={handleListResults(id)}
                    color={'inherit'}
                />
            )
        }

        return actions;
    };

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.user.table.actions'),
            filterable: false,
            width: 100,
            getActions: ({id}) => rowActions(id)
        },
        {
            field: 'fullName',
            headerName: t('admin.user.table.fullName'),
            width: 250
        },
        {
            field: 'birthDate',
            type: 'date',
            headerName: t('admin.user.table.birthdate'),
            width: 150
        },
        {
            field: 'email',
            headerName: t('admin.user.table.email'),
            width: 250
        },
        {
            field: 'created',
            type: 'date',
            headerName: t('admin.user.table.created'),
            width: 180,
            valueFormatter: ({ value }) => {
                if (value === undefined) {
                    return '';
                }

                return value.substring(0, 19).replace('T', ' ');
            }
        },
        {
            field: 'lastLogin',
            type: 'date',
            headerName: t('admin.user.table.lastLogin'),
            width: 180,
            valueFormatter: ({ value }) => {
                if (value === undefined) {
                    return '';
                }

                return value.substring(0, 19).replace('T', ' ');
            }
        },
        {
            field: 'userGroup',
            headerName: t('admin.user.table.groups'),
            flex: 1,
            type: 'singleSelect',
            valueOptions: (groupsData || [])
                .map(g => ({
                    value: g.id,
                    label: g.title ? `${g.title} (${g.shortname})` : '-'
                }))
                .sort((a, b) => a.label.localeCompare(b.label, 'no')),
            valueGetter: (params): GroupsColumn => {
                const addgroups = [...params.row.additionalUserGroups];
                addgroups.sort((a: UserGroupInfo, b: UserGroupInfo) => a.shortname.localeCompare(b.shortname, 'no'));
                return {
                    mainGroup: params.row.userGroup,
                    additionalGroups: addgroups
                };
            },
            renderCell: (params: GridRenderCellParams<any, GroupsColumn>) => {
                if (params.value?.mainGroup !== undefined) {
                    const groupdata = params.value;
                    const el: ReactElement[] = [];
                    if (groupdata.mainGroup !== undefined && groupdata.mainGroup !== null) {
                        el.push(
                            <Chip
                                key={`g${groupdata.mainGroup.id}`}
                                label={groupdata.mainGroup.shortname}
                                title={groupdata.mainGroup.title}
                                size={'small'}
                                variant={'outlined'}
                                color={'primary'}
                            />
                        );
                    }

                    if (groupdata.additionalGroups !== undefined) {
                        for (const additionalGroup of groupdata.additionalGroups) {
                            el.push(
                                <Chip
                                    key={`g${additionalGroup.id}`}
                                    label={additionalGroup.shortname}
                                    title={additionalGroup.title}
                                    size={'small'}
                                    variant={'outlined'}
                                />
                            );
                        }
                    }

                    return <Stack direction={'row'} spacing={1}>{el}</Stack>;
                }
            },
            sortComparator: groupsSortComparatorUserGroupColumn,
            filterOperators: groupsFilterOperatorsUsers
        },
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getUsersInfo')} error={error} />}
            {groupsIsError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={groupsError} />}

            <Dialog
                open={dialogInfo.show}
                onClose={() => setDialogInfo({ show: false })}
                maxWidth={'lg'}
                fullWidth
                scroll={'paper'}
            >
                <DialogTitle>{dialogInfo.title}</DialogTitle>
                <DialogContent dividers>
                    {dialogInfo.content}
                </DialogContent>
                <DialogActions>
                    <>
                    {dialogInfo.actions && dialogInfo.actions.map(({ label, onClick}) => (
                        <Button key={label} onClick={() => onClick()}>
                            {label}
                        </Button>
                    ))}
                    </>
                    <Button onClick={() => setDialogInfo({ show: false })}>
                        {t('generic.button.close')}
                    </Button>
                </DialogActions>
            </Dialog>

            <EditUserView
                editUser={editUser}
                callbackClose={() => setEditUser({ showDialog: false })}
            />

            <DataGrid
                autoHeight
                editMode={'row'}
                columns={cols}
                rows={(data || []) as GridRowsProp}
                loading={!isSuccess || !groupsSuccess}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            birthDate: false,
                            created: false,
                            lastLogin: false
                        }
                    },
                    filter: {
                        filterModel: {
                            items: [
                                {
                                    field: 'userGroup',
                                    operator: 'isAnyOf',
                                    value: searchParams.get('group') !== undefined && searchParams.get('group') !== null
                                        ? [Number(searchParams.get('group'))]
                                        : []
                                }
                            ]
                        }
                    },
                    sorting: {
                        sortModel: [{ field: 'fullName', sort: 'asc' }]
                    }
                }}
                localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
            />
        </>
    );
};

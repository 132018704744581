import React, {useContext} from "react";
import {Link, useLocation} from "react-router-dom";

import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import ExamIcon from "@mui/icons-material/Quiz";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from '@mui/icons-material/School';

import {t} from "../i18n/i18n";
import {authContext} from "./AuthProvider";
import {FeedbackButton} from "./FeedbackButton";

export const BottomPanel = () => {
    const location = useLocation();
    const {auth} = useContext(authContext);

    const navigationElements = [];

    navigationElements.push(
        <BottomNavigationAction key={'home'} label={t('bottompanel.home')} icon={<HomeIcon/>}
                                value={'/'} to={'/'} component={Link}/>,
    );

    if (auth !== null && auth.data !== null) {
        if (auth.data.profileComplete) {
            navigationElements.push(
                <BottomNavigationAction key={'quizes'} label={t('bottompanel.quizes')} icon={<ExamIcon/>}
                                        value={'/quizes'} to={'/quizes'}
                                        component={Link}/>,
                <BottomNavigationAction key={'results'} label={t('bottompanel.results')} icon={<SchoolIcon/>} showLabel
                                        value={'/results'}
                                        to={'/results'} component={Link}/>
            );
        }
        navigationElements.push(
            <FeedbackButton key={'feedback'} buttonType={'BottomPanel'} />,
            <BottomNavigationAction key={'profile'} label={t('bottompanel.yourProfile')} icon={<PersonIcon/>} showLabel
                                    value={'/profile'} to={'/profile'} component={Link}/>
        );
    } else {
        navigationElements.push(
            <FeedbackButton key={'feedback'} buttonType={'BottomPanel'} />,
            <BottomNavigationAction key={'login'} label={t('bottompanel.login')} icon={<LoginIcon/>} showLabel
                                    value={'/login'} to={'/login'} component={Link}/>
        );
    }

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: {md: 'none'}}} elevation={3}>
            <BottomNavigation showLabels value={location.pathname}>
                {navigationElements}
            </BottomNavigation>
        </Paper>
    );
}

import {useEffect, useState} from "react";

import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {useGetQuestionsQuery} from "../../api/question";
import {useGetQuizQuery, useUpdateQuizQuestionsMutation} from "../../api/quiz";
import {useGetAvailableSubjectsDifficultiesQuestionsQuery} from "../../api/quizcreator";
import {ListSelectableItem, ListSelectableItems} from "./ListSelectableItems";
import {ConfirmationDialog} from "../ConfirmationDialog";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

interface MyProps {
    quizid?: number;
    open: boolean;

    close(): any;
}

interface Question {
    id: number | null;
    title: string | null;
}

interface QuestionRow {
    id: number | undefined;
    questionNumberInQuiz: number;
    question: Question;
}

const formInitialState = {
    id: undefined as number | undefined,
    questions: [] as QuestionRow[]
};

const addRowInitialState = {
    subject: {
        id: null as number | null,
        title: ''
    },
    difficulty: '',
    question: {
        id: null as number | null,
        title: ''
    }
}

export const swapQuestionRows = (arr: QuestionRow[], idx1: number, idx2: number) => {
    if (idx1 >= 0 && idx1 < idx2 && idx2 < arr.length) {
        [arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]];
        arr[idx1].questionNumberInQuiz -= (idx2 - idx1);
        arr[idx2].questionNumberInQuiz += (idx2 - idx1);
    }
};

export const EditQuizQuestionsDialog = ({quizid, open, close}: MyProps) => {
    const [formData, setFormData] = useState(formInitialState);
    const [addRowData, setAddRowData] = useState(addRowInitialState);
    const {data, isSuccess, isFetching, isLoading, isError, error} =
        useGetQuizQuery(quizid || 0, {skip: quizid === undefined});
    const {
        data: questions,
        isLoading: questionsLoading,
        isError: questionsIsError,
        error: questionsError,
    } = useGetQuestionsQuery();
    const {
        data: subjectData,
        isLoading: subjectsLoading,
        isError: subjectsIsError,
        error: subjectsError,
    } = useGetAvailableSubjectsDifficultiesQuestionsQuery();
    const [updateQuizQuestions, updateQuizStatus] = useUpdateQuizQuestionsMutation();
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
    const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
    const [confirmDeleteRowOpen, setConfirmDeleteRowOpen] = useState(false);
    const [rowToDelete, setRowToDelete] = useState<QuestionRow | null>(null);

    useEffect(() => {
        if (open) {
            if (quizid === undefined) {
                setFormData(formInitialState);
            } else if (!isFetching && isSuccess) {
                setFormData({
                    id: data.id,
                    questions: data.questions
                });
            }
        }
    }, [quizid, data, isSuccess, open, isFetching])

    if (quizid === undefined) {
        return <></>;
    }

    const handleSave = () => {
        setConfirmSaveOpen(true);
    }

    const handleConfirmSave = (confirmed: boolean) => {
        setConfirmSaveOpen(false);

        if (confirmed) {
            //@ts-ignore
            updateQuizQuestions(formData).unwrap().then(() => {
                setFormData(formInitialState);
                close();
            });
        }
    }

    const handleCancel = () => {
        setConfirmCancelOpen(true);
    }

    const handleConfirmCancel = (confirmed: boolean) => {
        setConfirmCancelOpen(false);

        if (confirmed) {
            setFormData(formInitialState);
            close();
        }
    }

    const handleAddQuestions = (ids: ListSelectableItem[]) => {
        if (ids.length > 0) {
            let startNumber = formData.questions.length;
            const addQuestions = ids.map((item) => ({
                id: undefined,
                questionNumberInQuiz: ++startNumber,
                question: {
                    id: item.id,
                    title: item.title
                }
            }));

            setFormData({
                ...formData,
                questions: [
                    ...formData.questions,
                    ...addQuestions
                ]
            });

            setAddRowData(addRowInitialState);
        }
    }


    // TODO Georg legg til icon for visning av type spørsmål (bilde osv) og legg inn mulighet til å ekspandere for å se svarene

    const moveRowUp = (row: QuestionRow) => {
        const idx = formData.questions.findIndex((r) => r === row);
        const newArray = [...formData.questions];

        swapQuestionRows(newArray, idx-1, idx);

        setFormData({
            ...formData,
            questions: newArray
        });
    }

    const moveRowDown = (row: QuestionRow) => {
        const idx = formData.questions.findIndex((r) => r === row);
        const newArray = [...formData.questions];

        swapQuestionRows(newArray, idx, idx+1);

        setFormData({
            ...formData,
            questions: newArray
        });
    }

    const handleDeleteRow = (confirmed: boolean) => {
        setConfirmDeleteRowOpen(false);

        if (confirmed) {
            const idx = formData.questions.findIndex((r) => r === rowToDelete);
            const newArray = [
                ...formData.questions
                    .filter((r) => r !== rowToDelete)
                    .map((r) => {
                        const newR = {
                            ...r
                        };

                        if (newR.questionNumberInQuiz > idx) {
                            newR.questionNumberInQuiz--;
                        }

                        return newR;
                    })
            ];

            setFormData({
                ...formData,
                questions: newArray
            });
        }

        setRowToDelete(null);
    }

    const currentQuestionIds = formData.questions.map((q) => q.question.id);
    const filteredQuestions = (questions || [])
        .filter((q) => addRowData.subject.id === q.subject.id)
        .filter((q) => addRowData.difficulty !== '' ? addRowData.difficulty === q.difficulty : true)
        .filter((q) => currentQuestionIds.indexOf(q.id) === -1);

    return (
        <Dialog open={open} maxWidth={'lg'} fullWidth>
            {isError && <ErrorAlert title={t('apiError.getQuizInfo')} error={error} />}
            {subjectsIsError && <ErrorAlert title={t('apiError.getSubjectsInfo')} error={subjectsError} />}
            {questionsIsError && <ErrorAlert title={t('apiError.getQuestionsInfo')} error={questionsError} />}
            {updateQuizStatus.isError && <ErrorAlert title={t('apiError.updateQuiz')} error={updateQuizStatus.error} />}

            {(isLoading || subjectsLoading || questionsLoading) && <CircularProgress />}

            <DialogTitle>{t('admin.quiz.editQuestions.title')} - {data?.title}</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: 100}}>{t('admin.quiz.editQuestions.list.header.actions')}</TableCell>
                                <TableCell sx={{width: 100}}>{t('admin.quiz.editQuestions.list.header.number')}</TableCell>
                                <TableCell>{t('admin.quiz.editQuestions.list.header.question')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {formData.questions.map((row) => (
                                <TableRow
                                    key={row.questionNumberInQuiz}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <Stack direction={'row'} spacing={1} sx={{mb: 1}}>
                                            <Button
                                                disabled={row.questionNumberInQuiz === 1}
                                                startIcon={<ArrowUpwardIcon/>}
                                                onClick={() => moveRowUp(row)}
                                            />
                                            <Button
                                                disabled={row.questionNumberInQuiz >= formData.questions.length}
                                                startIcon={<ArrowDownwardIcon/>}
                                                onClick={() => moveRowDown(row)}
                                            />
                                            <Button
                                                startIcon={<DeleteForeverIcon/>}
                                                onClick={() => {
                                                    setRowToDelete(row);
                                                    setConfirmDeleteRowOpen(true);
                                                }}
                                            />
                                        </Stack>
                                    </TableCell>
                                    <TableCell align={'right'}>{row.questionNumberInQuiz}</TableCell>
                                    <TableCell>{row.question.title}</TableCell>
                                </TableRow>
                            ))}

                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Stack direction={'row'} spacing={1} sx={{mb: 1}}>
                                        <FormControl required fullWidth>
                                            <InputLabel id={'subjectLabel'}>{t('admin.quiz.editQuestions.list.form.subject')}</InputLabel>
                                            <Select
                                                id={'subject'}
                                                label={t('admin.quiz.editQuestions.list.form.subject')}
                                                labelId={'subjectLabel'}
                                                value={addRowData.subject.id || ''}
                                                onChange={e => setAddRowData({
                                                    ...addRowData,
                                                    difficulty: '',
                                                    subject: {
                                                        id: e.target.value !== null ? e.target.value as unknown as number : null,
                                                        title: ''
                                                    }
                                                })}
                                            >
                                                {(subjectData || []).map((subject) => (
                                                    <MenuItem key={subject.id} value={subject.id}>
                                                        {subject.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel id={'difficultyLabel'}>{t('admin.quiz.editQuestions.list.form.difficulty.label')}</InputLabel>
                                            <Select
                                                id={'difficulty'}
                                                label={t('admin.quiz.editQuestions.list.form.difficulty.label')}
                                                labelId={'difficultyLabel'}
                                                value={addRowData.difficulty}
                                                onChange={e => setAddRowData({
                                                    ...addRowData,
                                                    difficulty: e.target.value
                                                })}
                                            >
                                                <MenuItem value={''}><em>{t('admin.quiz.editQuestions.list.form.difficulty.option.notSelected')}</em></MenuItem>
                                                <MenuItem value={'EASY'}>{t('admin.quiz.editQuestions.list.form.difficulty.option.easy')}</MenuItem>
                                                <MenuItem value={'MEDIUM'}>{t('admin.quiz.editQuestions.list.form.difficulty.option.medium')}</MenuItem>
                                                <MenuItem value={'HARD'}>{t('admin.quiz.editQuestions.list.form.difficulty.option.hard')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>

                                    <ListSelectableItems
                                        id={'questions-list'}
                                        items={filteredQuestions}
                                        onAdd={handleAddQuestions}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={2}/>
                                <TableCell align={'right'}>
                                    {formData.questions.length !== 20 && (
                                        <Alert severity="warning" variant="outlined">
                                            {t('admin.quiz.editQuestions.warningNumQuestions')}
                                        </Alert>
                                    )}
                                    {formData.questions.length}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <ConfirmationDialog
                    id={'confirm-save-questions'}
                    title={t('admin.quiz.editQuestions.confirm.saveChanges')}
                    open={confirmSaveOpen}
                    onClose={handleConfirmSave}
                />
                <ConfirmationDialog
                    id={'confirm-cancel-questions'}
                    title={t('admin.quiz.editQuestions.confirm.cancelChanges')}
                    open={confirmCancelOpen}
                    onClose={handleConfirmCancel}
                />
                <ConfirmationDialog
                    id={'confirm-delete-row'}
                    title={t('admin.quiz.editQuestions.confirm.deleteRow')}
                    open={confirmDeleteRowOpen}
                    onClose={handleDeleteRow}
                />
                <Button
                    onClick={handleSave}
                    disabled={formData.questions.length === 0 || updateQuizStatus.isLoading || !isSuccess}
                >{t('admin.quiz.editQuestions.button.saveQuestions')}</Button>
                <Button onClick={handleCancel}>{t('generic.button.cancel')}</Button>
            </DialogActions>
            <Backdrop open={updateQuizStatus.isLoading}>
                <CircularProgress/>
            </Backdrop>
        </Dialog>
    );
};

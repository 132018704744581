import {useEffect, useState} from "react";

import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {difficultyLevels, useGetQuizQuery, useUpdateQuizSubjectsMutation} from "../../api/quiz";
import {useGetAvailableSubjectsDifficultiesQuestionsQuery} from "../../api/quizcreator";
import {ConfirmationDialog} from "../ConfirmationDialog";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

interface MyProps {
    quizid?: number;
    open: boolean;

    close(): any;
}

interface Subject {
    id: number | null;
    title: string | null;
}

interface FormRow {
    id: number | undefined;
    subject: Subject;
    numberOfQuestions: number;
    difficulty: difficultyLevels;
}

const formInitialState = {
    id: undefined as number | undefined,
    quizQuestionsSubjects: [] as FormRow[]
};

const addRowInitialState: FormRow = {
    id: undefined,
    subject: {
        id: null,
        title: ''
    },
    numberOfQuestions: 1,
    difficulty: 'EASY'
}

export const EditQuizSubjectsDialog = ({quizid, open, close}: MyProps) => {
    const [formData, setFormData] = useState(formInitialState);
    const [addRowData, setAddRowData] = useState<FormRow>(addRowInitialState);
    const {data, isSuccess, isFetching, isLoading, isError, error} =
        useGetQuizQuery(quizid || 0, {skip: quizid === undefined});
    const {
        data: subjectData,
        isSuccess: subjectsSuccess,
        isLoading: subjectsLoading,
        isError: subjectsIsError,
        error: subjectsError,
    } = useGetAvailableSubjectsDifficultiesQuestionsQuery();
    const [updateQuizSubjects, updateQuizStatus] = useUpdateQuizSubjectsMutation();
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
    const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
    const [confirmDeleteRowOpen, setConfirmDeleteRowOpen] = useState(false);
    const [rowToDelete, setRowToDelete] = useState<FormRow | null>(null);

    useEffect(() => {
        if (open) {
            if (quizid === undefined) {
                setFormData(formInitialState);
            } else if (!isFetching && isSuccess) {
                setFormData({
                    id: data.id,
                    quizQuestionsSubjects: data.quizQuestionsSubjects
                });
            }
        }
    }, [quizid, data, isSuccess, open, isFetching])

    if (quizid === undefined) {
        return <></>;
    }

    const handleSave = () => {
        setConfirmSaveOpen(open);
    }

    const handleConfirmSave = (confirmed: boolean) => {
        setConfirmSaveOpen(false);
        if (confirmed) {
            //@ts-ignore
            updateQuizSubjects(formData).unwrap().then(() => {
                setFormData(formInitialState);
                close();
            });
        }
    }

    const handleCancel = () => {
        setConfirmCancelOpen(true);
    }

    const handleConfirmCancel = (confirmed: boolean) => {
        setConfirmCancelOpen(false);

        if (confirmed) {
            setFormData(formInitialState);
            close();
        }
    }

    const handleAddRow = () => {
        setFormData({
            ...formData,
            quizQuestionsSubjects: [
                ...formData.quizQuestionsSubjects,
                addRowData
            ]
        });
        setAddRowData(addRowInitialState);
    }

    const isAddRowNotAllowed =
        addRowData.subject.id === null ||
        addRowData.numberOfQuestions === 0 ||
        formData.quizQuestionsSubjects.filter(r => r.subject.id === addRowData.subject.id && r.difficulty === addRowData.difficulty)
            .length !== 0;

    const availableSubjectData = (subjectData || []).filter(s => {
        return true
    });

    const totalQuestions: number = formData.quizQuestionsSubjects.reduce((total, subjectLine) =>
        +total + +subjectLine.numberOfQuestions, 0
    );

    const calculateMaxQuestionsForAdd = () => {
        const subject = availableSubjectData.find(d => d.id === addRowData.subject.id);

        if (subject !== undefined && subject !== null) {
            const map = new Map(Object.entries(subject.difficultiesCountMap));
            return map.get(addRowData.difficulty);
        }

        return 0;
    };

    const subjectTitle = (row: FormRow) => {
        if (row.subject.title !== null) {
            return row.subject.title;
        }

        const subject = (subjectData || []).find(d => d.id === row.subject.id);
        return subject?.title;
    }

    const handleDeleteRow = (confirmed: boolean) => {
        setConfirmDeleteRowOpen(false);

        if (confirmed) {
            setFormData({
                ...formData,
                quizQuestionsSubjects: [
                    ...formData.quizQuestionsSubjects.filter(r => {
                        return r !== rowToDelete
                    })
                ]
            });
        }

        setRowToDelete(null);
    }

    const maxQuestionsForAdd = calculateMaxQuestionsForAdd();

    return (
        <Dialog open={open} maxWidth={'lg'} fullWidth>
            <DialogTitle>{t('admin.quiz.editSubjects.title')} - {data?.title}</DialogTitle>
            <DialogContent>
                {isError && <ErrorAlert title={t('apiError.getQuizInfo')} error={error} />}
                {subjectsIsError && <ErrorAlert title={t('apiError.getSubjectsInfo')} error={subjectsError} />}
                {updateQuizStatus.isError && <ErrorAlert title={t('apiError.updateQuiz')} error={updateQuizStatus.error} />}

                {(isLoading || subjectsLoading) && <CircularProgress />}

                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('admin.quiz.editSubjects.list.header.actions')}</TableCell>
                                <TableCell>{t('admin.quiz.editSubjects.list.header.subject')}</TableCell>
                                <TableCell>{t('admin.quiz.editSubjects.list.header.questions')}</TableCell>
                                <TableCell>{t('admin.quiz.editSubjects.list.header.difficulty')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {formData.quizQuestionsSubjects.map((row) => (
                                <TableRow
                                    key={row.subject.id + '-' + row.difficulty}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <Button startIcon={<DeleteForeverIcon/>} onClick={() => {
                                            setRowToDelete(row);
                                            setConfirmDeleteRowOpen(true);
                                        }}>
                                            {t('generic.button.remove')}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{subjectTitle(row)}</TableCell>
                                    <TableCell>{row.numberOfQuestions}</TableCell>
                                    <TableCell>
                                        {t('admin.quiz.editSubjects.list.form.option.' + row.difficulty.toLowerCase())}
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TableRow>
                                <TableCell>
                                    <Button
                                        variant={'contained'}
                                        startIcon={<AddIcon/>}
                                        onClick={handleAddRow}
                                        disabled={isAddRowNotAllowed}
                                    >{t('generic.button.add')}</Button>
                                </TableCell>
                                <TableCell>
                                    <FormControl required fullWidth>
                                        <InputLabel id={'subjectLabel'}>{t('admin.quiz.editSubjects.list.form.subject')}</InputLabel>
                                        <Select
                                            id={'subject'}
                                            label={t('admin.quiz.editSubjects.list.form.subject')}
                                            labelId={'subjectLabel'}
                                            value={addRowData.subject.id || ''}
                                            onChange={e => setAddRowData({
                                                ...addRowData,
                                                subject: {
                                                    id: e.target.value !== null ? e.target.value as unknown as number : null,
                                                    title: null
                                                }
                                            })}
                                        >
                                            {availableSubjectData.map((subject) => (
                                                <MenuItem key={subject.id} value={subject.id}>{subject.title}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        id={'questions'}
                                        margin={'dense'}
                                        label={t('admin.quiz.editSubjects.list.form.questions')}
                                        fullWidth
                                        variant={'standard'}
                                        type={'number'}
                                        disabled={addRowData.subject.id === null}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*',
                                            min: Math.min(1, maxQuestionsForAdd),
                                            max: maxQuestionsForAdd
                                        }}
                                        value={addRowData.numberOfQuestions}
                                        onChange={e => setAddRowData({
                                            ...addRowData,
                                            numberOfQuestions: e.target.value as unknown as number
                                        })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <InputLabel id={'difficultyLabel'}>{t('admin.quiz.editSubjects.list.form.difficulty')}</InputLabel>
                                        <Select
                                            id={'difficulty'}
                                            label={t('admin.quiz.editSubjects.list.form.difficulty')}
                                            labelId={'difficultyLabel'}
                                            disabled={addRowData.subject.id === null}
                                            value={addRowData.difficulty}
                                            onChange={e => setAddRowData({
                                                ...addRowData,
                                                difficulty: e.target.value as difficultyLevels
                                            })}
                                        >
                                            <MenuItem value={'EASY'}>{t('admin.quiz.editSubjects.list.form.option.easy')}</MenuItem>
                                            <MenuItem value={'MEDIUM'}>{t('admin.quiz.editSubjects.list.form.option.medium')}</MenuItem>
                                            <MenuItem value={'HARD'}>{t('admin.quiz.editSubjects.list.form.option.hard')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={2}/>
                                <TableCell align="right">
                                    {totalQuestions !== 20 && (
                                        <Alert severity="warning" variant="outlined">
                                            {t('admin.quiz.editSubjects.warningNumQuestions')}
                                        </Alert>
                                    )}
                                    {totalQuestions}
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>

            <DialogActions>
                <ConfirmationDialog
                    id={'confirm-save-subjects'}
                    title={t('admin.quiz.editSubjects.confirm.saveChanges')}
                    open={confirmSaveOpen}
                    onClose={handleConfirmSave}
                />
                <ConfirmationDialog
                    id={'confirm-cancel-subjects'}
                    title={t('admin.quiz.editSubjects.confirm.cancelChanges')}
                    open={confirmCancelOpen}
                    onClose={handleConfirmCancel}
                />
                <ConfirmationDialog
                    id={'confirm-delete-row'}
                    title={t('admin.quiz.editSubjects.confirm.deleteRow')}
                    open={confirmDeleteRowOpen}
                    onClose={handleDeleteRow}
                />
                <Button
                    onClick={handleSave}
                    disabled={!isSuccess || !subjectsSuccess || formData.quizQuestionsSubjects.length === 0}
                >{t('admin.quiz.editSubjects.button.saveSubjects')}</Button>
                <Button
                    onClick={handleCancel}
                >{t('generic.button.cancel')}</Button>
            </DialogActions>
            <Backdrop open={updateQuizStatus.isLoading}>
                <CircularProgress/>
            </Backdrop>
        </Dialog>
    );
};

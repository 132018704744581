import {useState} from "react";
import {enqueueSnackbar} from "notistack";

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

import {t} from "../../i18n/i18n";
import {useAddSubjectMutation} from "../../api/subject";
import {ErrorAlert} from "../ErrorAlert";

const formInitialState = {
    title: ''
};

interface Props {
    showDialog: boolean;
    callbackClose: () => void;
}

export const AddSubjectDialog = ({
    showDialog,
    callbackClose,
}: Props) => {
    const [addSubject, addStatus] = useAddSubjectMutation();
    const [formData, setFormData] = useState(formInitialState);

    const handleAddClose = () => {
        setFormData(formInitialState);
        callbackClose();
    }

    const handleSaveNew = () => {
        addSubject({
            title: formData.title
        }).unwrap().then(() => {
            setFormData(formInitialState);
            enqueueSnackbar(t('snackbar.subject.added'), { variant: "success" });
            callbackClose();
        });
    }

    return (
        <Dialog open={showDialog} onClose={handleAddClose} fullWidth>
            <DialogTitle>{t('admin.subject.add.title')}</DialogTitle>

            {addStatus.isError && <ErrorAlert title={t('apiError.addSubject')} error={addStatus.error} />}

            <DialogContent>
                <DialogContentText>
                    {t('admin.subject.add.description')}
                </DialogContentText>
                <TextField
                    id={'title'}
                    autoFocus
                    margin={'dense'}
                    label={t('admin.subject.form.title')}
                    fullWidth
                    variant={'standard'}
                    value={formData.title}
                    onChange={e => setFormData({...formData, title: e.target.value})}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveNew}>{t('generic.button.save')}</Button>
                <Button onClick={handleAddClose}>{t('generic.button.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

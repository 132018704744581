import moment from "moment";

import {
    Chip,
    Skeleton
} from "@mui/material";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowsProp, nbNO} from "@mui/x-data-grid";

import {useGetUserResultsQuery} from "../../api/results";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

export interface Props {
    userId: number;
    viewResultCallback: (executionId: number) => void;
}

export const UserResultsListView = ({ userId, viewResultCallback }: Props) => {
    const {data, isSuccess, isLoading, isError, error} = useGetUserResultsQuery(
        userId, {
            refetchOnMountOrArgChange: 180
        }
    );

    const cols: GridColDef[] = [
        {
            field: 'title',
            headerName: t('admin.user.results.header.title'),
            width: 400
        },
        {
            field: 'start',
            headerName: t('admin.user.results.header.start'),
            width: 200,
            valueFormatter: params => params?.value === null ? '-' : moment(params?.value).format('YYYY-MM-DD HH:mm')
        },
        {
            field: 'timespent',
            headerName: t('admin.user.results.header.timeSpent'),
            width: 120,
            align: "center"
        },
        {
            field: 'score',
            headerName: t('admin.user.results.header.score'),
            width: 120,
            align: "right"
        },
        {
            field: 'totalQuestions',
            headerName: t('admin.user.results.header.totalQuestions'),
            width: 120,
            align: "right"
        },
        {
            field: 'result',
            headerName: t('admin.user.results.header.result'),
            width: 150,
            align: "center",
            renderCell: (params: GridRenderCellParams<any, string>) => (
                <Chip
                    label={params.value}
                    sx={{
                        backgroundColor: params.value === 'PASSED' ? '#4f4' : '#f44',
                        fontWeight: 'bold'
                    }}
                />
            )
        }
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.resultList')} error={error} />}

            {isLoading && <Skeleton height={'80px'}/>}

            {isSuccess && (data.length === 0 ? <p>{t('results.noResults')}</p> :
                <DataGrid
                    autoHeight
                    columns={cols}
                    rows={data as GridRowsProp}
                    getRowId={(row) => row.executionId}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'asc' }]
                        }
                    }}
                    onRowClick={(params) => viewResultCallback(params.row.executionId)}
                    localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
                />
            )}
        </>
    );
};

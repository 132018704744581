
export const displaySecondsAsTime = (timeInSeconds: number) => {
    if (timeInSeconds === undefined || timeInSeconds === null) {
        return '-';
    }

    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = timeInSeconds % 60

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

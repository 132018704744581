import api from './index';

interface AvailableSubjectQuestion {
    id: number;
    title: string;
    difficultiesCountMap: Map<string, number>
}

const quizcreator = api.injectEndpoints({
    endpoints: (build) => ({
        getAvailableSubjectsDifficultiesQuestions: build.query<AvailableSubjectQuestion[], void>({
            query: () => 'admin/quizcreator',
            providesTags: ['SubjectStats']
        })
    }),
    overrideExisting: false
});

export const {
    useGetAvailableSubjectsDifficultiesQuestionsQuery
} = quizcreator;

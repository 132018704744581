import {useContext} from "react";

import {FormControl, Stack, TextField} from "@mui/material";

import {t} from "../../../i18n/i18n";
import {formContext} from "./QuestionFormContext";

export const QuestionForm3 = () => {
    const { formData, setFormData } = useContext(formContext);

    return (
        <FormControl fullWidth>
            <Stack spacing={2}>
                <TextField
                    id={'rule'}
                    margin={'dense'}
                    label={t('admin.question.form.rule')}
                    fullWidth
                    variant={'outlined'}
                    value={formData.rule}
                    onChange={e => setFormData({...formData, rule: e.target.value})}
                />
                <TextField
                    id={'explanation'}
                    margin={'dense'}
                    label={t('admin.question.form.explanation')}
                    fullWidth
                    multiline
                    minRows={5}
                    variant={'outlined'}
                    value={formData.explanation}
                    onChange={e => setFormData({...formData, explanation: e.target.value})}
                />
            </Stack>
        </FormControl>
    );
};

import React from "react";
import {NavLink, useLocation} from "react-router-dom";

import {AppBar, Box, Button, CssBaseline, Drawer, Stack, Toolbar, Typography} from "@mui/material";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import ExamIcon from "@mui/icons-material/Quiz";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from '@mui/icons-material/School';

import {t} from "../i18n/i18n";
import {AuthUserFirstname, ShowIfAdmin, ShowIfAuth, ShowIfAuthAndProfileComplete, ShowIfNotAuth} from "./RequireAuth";
import {AdminMenu} from "../routes/admin/AdminMenu";
import { FeedbackButton } from "./FeedbackButton";

export const TopPanel = () => {
    const location = useLocation();

    const drawerWidth = location.pathname.startsWith('/admin') ? 240 : 0;

    return (
        <>
            <CssBaseline/>
            <AppBar
                position={'fixed'}
            >
                <Toolbar
                    sx={{
                        width: {md: `calc(100% - ${drawerWidth}px)`},
                        ml: {md: `${drawerWidth}px`}
                    }}
                >
                    <Typography variant="h6" color="inherit" noWrap sx={{ marginRight: '36px' }}>
                        {t('toppanel.title')}
                    </Typography>
                    <Stack direction={'row'} sx={{ flexGrow: 1, display: {xs: 'none', md: 'flex'} }}>
                        <Button
                            key={'homeTopMenuItem'}
                            component={NavLink}
                            to={'/'}
                            sx={{my: 2, color: 'white', display: 'flex', mr: 4}}
                            size={'small'}
                            startIcon={<HomeIcon/>}
                        >{t('toppanel.home')}</Button>
                        <ShowIfAuthAndProfileComplete>
                            <Button
                                key={'quizesTopMenuItem'}
                                component={NavLink}
                                to={'/quizes'}
                                sx={{my: 2, color: 'white', display: 'flex', mr: 4}}
                                size={'small'}
                                startIcon={<ExamIcon/>}
                            >{t('toppanel.quizes')}</Button>
                        </ShowIfAuthAndProfileComplete>
                        <ShowIfAuthAndProfileComplete>
                            <Button
                                key={'resultsTopMenuItem'}
                                component={NavLink}
                                to={'/results'}
                                sx={{my: 2, color: 'white', display: 'flex', mr: 4}}
                                size={'small'}
                                startIcon={<SchoolIcon/>}
                            >{t('toppanel.results')}</Button>
                        </ShowIfAuthAndProfileComplete>
                        <ShowIfAdmin>
                            <Button
                                key={'adminTopMenuItem'}
                                component={NavLink}
                                to={'/admin'}
                                sx={{my: 2, color: 'white', display: 'flex', mr: 4}}
                                size={'small'}
                                startIcon={<AdminIcon/>}
                            >{t('toppanel.admin')}</Button>
                        </ShowIfAdmin>
                    </Stack>
                    <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                        <FeedbackButton buttonType={'TopPanel'}/>
                        <ShowIfAuth>
                            <Button
                                key={'profileTopMenuItem'}
                                component={NavLink}
                                to={'/profile'}
                                sx={{my: 2, color: 'white', display: 'flex'}}
                                startIcon={<PersonIcon/>}
                            ><AuthUserFirstname/></Button>
                        </ShowIfAuth>
                        <ShowIfNotAuth>
                            <Button
                                key={'loginTopMenuItem'}
                                component={NavLink}
                                to={'/login'}
                                sx={{my: 2, color: 'white', display: 'flex'}}
                                size={'small'}
                                startIcon={<LoginIcon/>}
                            >{t('toppanel.login')}</Button>
                        </ShowIfNotAuth>
                    </Box>
                </Toolbar>
            </AppBar>
            <ShowIfAdmin>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                        display: {xs: 'none', md: 'block'}
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <AdminMenu/>
                </Drawer>
            </ShowIfAdmin>
        </>
    );
};

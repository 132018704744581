import {CircularProgress} from "@mui/material";
import GitInfo from 'react-git-info/macro';

import {useGetSystemInfoQuery} from "../../api/info";
import {ErrorAlert} from "../../components/ErrorAlert";
import {t} from "../../i18n/i18n";

export const SystemInfo = () => {
    const {data, isError, error, isLoading, isSuccess} = useGetSystemInfoQuery();
    const gitInfo = GitInfo();

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.systemInfo')} error={error} />}

            {isLoading && <CircularProgress />}

            {isSuccess && (
                <ul>
                    <li>{t('admin.systeminfo.version.backend', {
                        version: data.buildProperties.version,
                        timestamp: new Date(data.buildProperties.time).toLocaleString("nb-NO")
                    })}</li>
                    <li>{t('admin.systeminfo.version.frontend', {
                        version: process.env.REACT_APP_VERSION + '#' + gitInfo.commit.shortHash,
                        timestamp: new Date(gitInfo.commit.date).toLocaleString("nb-NO"),
                    })}</li>
                </ul>
            )}
        </>
    );
};

import api from './index';

interface TodayStats {
    uniqueUsers: number;
    quizesPerformed: number;
}

interface YearMonthStats {
    year: number;
    month: number;
    count: number;
}

const statistics = api.injectEndpoints({
    endpoints: (build) => ({
        getToday: build.query<TodayStats, void>({
            query: () => 'admin/stats/today'
        }),
        getLast2Years: build.query<YearMonthStats[], void>({
            query: () => 'admin/stats/last2years'
        }),
        getExecutionStats: build.query<YearMonthStats[], void>({
            query: () => 'admin/stats/quizExecutionStats'
        })
    }),
    overrideExisting: false
});

export const {
    useGetTodayQuery,
    useGetLast2YearsQuery,
    useGetExecutionStatsQuery
} = statistics;

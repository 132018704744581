import {Link} from "react-router-dom";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import GroupIcon from "@mui/icons-material/Group";
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';
import ListItemIcon from "@mui/material/ListItemIcon";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import QuizIcon from "@mui/icons-material/Quiz";
import PersonIcon from "@mui/icons-material/Person";
import SubjectIcon from "@mui/icons-material/Subject";
import SummarizeIcon from "@mui/icons-material/Summarize";
import {Divider, List, ListItem, ListItemButton, ListItemText, ListSubheader, Toolbar, Typography} from "@mui/material";

import {useGetUserInfoQuery} from "../../api/user";
import {isRegionAdmin, isSystemAdmin} from "../../util/AdminUtil";
import {t} from "../../i18n/i18n";

interface AdminMenuItem {
    label?: string;
    value?: string;
    icon?: JSX.Element;
}

export const AdminMenu = () => {
    const { data, isSuccess } = useGetUserInfoQuery();
    let admin = false;
    let regionAdmin = false;

    if (isSuccess) {
        admin = isSystemAdmin(data);
        regionAdmin = isRegionAdmin(data);
    }

    const items: AdminMenuItem[] = [
    ];

    if (admin || regionAdmin) {
        items.push(
            {
                label: t('adminmenu.infoTitle')
            },
            {
                label: t('adminmenu.systemInfo'),
                value: '/admin/systeminfo',
                icon: <InfoIcon/>
            },
            {
                label: t('adminmenu.contentTitle')
            },
            {
                label: t('adminmenu.news'),
                value: '/admin/news',
                icon: <NewspaperIcon/>
            },
            {},
            {
                label: t('adminmenu.usersTitle')
            },
            {
                label: t('adminmenu.users'),
                value: '/admin/users',
                icon: <PersonIcon/>
            },
            {
                label: t('adminmenu.usergroups'),
                value: '/admin/usergroups',
                icon: <GroupIcon/>
            }
        );
    }

    if (admin) {
        items.push(
            {
                label: t('adminmenu.accessmanagement'),
                value: '/admin/access_control',
                icon: <AdminPanelSettingsIcon/>
            }
        );
    }

    if (admin || regionAdmin) {
        items.push(
            {},
            {
                label: t('adminmenu.quizesTitle')
            },
            {
                label: t('adminmenu.subjects'),
                value: '/admin/subjects',
                icon: <SubjectIcon/>
            },
            {
                label: t('adminmenu.questions'),
                value: '/admin/questions',
                icon: <QuestionMarkIcon/>
            },
            {
                label: t('adminmenu.images'),
                value: '/admin/images',
                icon: <ImageIcon/>
            },
            {
                label: t('adminmenu.quizes'),
                value: '/admin/quizes',
                icon: <QuizIcon/>
            },
            {},
            {
                label: t('adminmenu.reportsTitle')
            },
            {
                label: t('adminmenu.quizUsergroup'),
                value: '/admin/reports/quizusergroup',
                icon: <SummarizeIcon/>
            },
            {
                label: t('adminmenu.quizQuestionsStatistics'),
                value: '/admin/reports/quizquestionstats',
                icon: <SummarizeIcon/>
            },
            {}
        );
    }

    if (admin) {
        items.push(
            {
                label: t('adminmenu.statisticsTitle')
            },
            {
                label: t('adminmenu.overall'),
                value: '/admin/statistics',
                icon: <AnalyticsIcon/>
            },
            {},
            {
                label: t('adminmenu.eventsTitle')
            },
            {
                label: t('adminmenu.eventsLatest'),
                value: '/admin/events',
                icon: <HistoryIcon/>
            },
            {}
        );
    }

    return (
        <>
            <Toolbar>
                <Typography variant={'h6'}>{t('adminmenu.menuTitle')}</Typography>
            </Toolbar>
            <Divider/>
            <List>
                {items.map((item, index) => {
                    if (item.label === undefined) {
                        return <Divider key={index}/>;
                    } else if (item.value === undefined) {
                        return <ListSubheader key={index}>{item.label}</ListSubheader>;
                    } else {
                        return (
                            <ListItem key={index} disablePadding>
                                <ListItemButton to={item.value} component={Link}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.label}/>
                                </ListItemButton>
                            </ListItem>
                        );
                    }
                })}
            </List>
        </>
    )
};

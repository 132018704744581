import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {CircularProgress} from "@mui/material";

export const InitRoute = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/admin/systeminfo');
    });

    return <CircularProgress/>;
};

import {useContext, useEffect, useState} from "react";

import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";

import {t} from "../../i18n/i18n";
import {
    Question,
    useAddQuestionMutation,
    useReplaceQuestionMutation,
    useUpdateQuestionMutation
} from "../../api/question";
import {ErrorAlert} from "../ErrorAlert";
import {QuestionForm1} from "./forms/QuestionForm1";
import {QuestionForm2} from "./forms/QuestionForm2";
import {QuestionForm3} from "./forms/QuestionForm3";
import {formContext, initFormData} from "./forms/QuestionFormContext";
import {QuestionForm2Images} from "./forms/QuestionForm2Images";
import {enqueueSnackbar} from "notistack";

interface Props {
    dialogOpen: boolean;
    closeCallback?: () => void;
    formValues?: Partial<Question>;
    replaceQ?: Question;
}

export const EditQuestionDialog = ({
    dialogOpen,
    closeCallback,
    formValues,
    replaceQ,
}: Props) => {
    const [addQuestion, addStatus] = useAddQuestionMutation();
    const [updateQuestion, updateStatus] = useUpdateQuestionMutation();
    const [replaceQuestion, replaceStatus] = useReplaceQuestionMutation();
    const [activeStep, setActiveStep] = useState(0);
    const { formData, setFormData } = useContext(formContext);

    const wizardSteps: string[] = [
        t('admin.question.wizard.step1'),
        t('admin.question.wizard.step2'),
        t('admin.question.wizard.step3')
    ];

    useEffect(() => {
        if (formValues !== undefined) {
            setFormData({
                ...formValues
            });
        }
    }, [formValues]);

    const handleSave = () => {
        if (replaceQ !== undefined) {
            replaceQuestion({
                replaceId: replaceQ.id,
                newQuestion: {
                    ...formData,
                    subject: replaceQ.subject,
                    difficulty: replaceQ.difficulty,
                    questionType: replaceQ.questionType
                }
            }).unwrap().then((res) => {
                enqueueSnackbar(t('admin.question.snackbar.replacedQuestion', {
                        id: replaceQ.id || 0,
                        replaceId: res.id
                    }),
                    { variant: "success" }
                );
                handleClose();
            });
        } else if (formData.id === undefined) {
            addQuestion(formData).unwrap().then((res) => {
                enqueueSnackbar(t('admin.question.snackbar.addedQuestion', {
                        id: res.id
                    }),
                    {variant: "success"}
                );
                handleClose();
            });
        } else {
            // @ts-ignore
            updateQuestion(formData).unwrap().then(() => {
                enqueueSnackbar(t('admin.question.snackbar.updatedQuestion', {
                        id: formData.id
                    }),
                    {variant: "success"}
                );
                handleClose();
            });
        }
    }

    const handleClose = () => {
        setFormData(initFormData);
        setActiveStep(0);
        closeCallback !== undefined && closeCallback();
    }

    const checkStepDataValid = (activeStep: number): boolean => {
        switch (activeStep) {
            case 0: {
                return formData.subject?.id !== undefined && formData.subject.id !== null &&
                    formData.title !== undefined && formData.title.length > 0 &&
                    (formData.questionType !== 'PHOTO_QUESTION' ||
                        (formData.questionType === 'PHOTO_QUESTION' && formData.titleImage !== undefined && formData.titleImage.length > 0));
            }

            case 1: {
                return formData.option1 !== undefined && formData.option1.length > 0 &&
                    formData.option2 !== undefined && formData.option2.length > 0;
            }
        }

        return false;
    };

    return (
        <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'lg'} fullWidth>
            {addStatus.isError && <ErrorAlert title={t('apiError.addQuestion')} error={addStatus.error}/>}
            {updateStatus.isError && <ErrorAlert title={t('apiError.updateQuestion')} error={updateStatus.error}/>}
            {replaceStatus.isError && <ErrorAlert title={t('apiError.replaceQuestion')} error={replaceStatus.error}/>}

            <DialogTitle>
                {formData.id !== undefined
                    ? ( replaceQ !== undefined
                        ? t('admin.question.replace.dialogTitle', { id: replaceQ.id })
                        : t('admin.question.edit.title') + formData.id)
                    : t('admin.question.add.title')
                }
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {t('admin.question.add.description')}
                </DialogContentText>

                <br/>

                <Stepper activeStep={activeStep} alternativeLabel>
                    {wizardSteps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <br/>

                {activeStep === 0 && <QuestionForm1 replace={replaceQ !== undefined} />}
                {activeStep === 1 && (formData.questionType === 'PHOTO_ANSWERS'
                    ? <QuestionForm2Images />
                    : <QuestionForm2 />
                )}
                {activeStep === 2 && <QuestionForm3 />}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    variant={'outlined'}
                >{t('generic.button.cancel')}</Button>

                <div style={{flex: '1 0 0'}}/>

                {activeStep > 0 && (
                    <Button
                        onClick={() => setActiveStep(activeStep - 1)}
                        variant={'outlined'}
                    >{t('generic.button.previous')}</Button>
                )}
                {activeStep < 2 && (
                    <Button
                        disabled={!checkStepDataValid(activeStep)}
                        onClick={() => setActiveStep(activeStep + 1)}
                        variant={'contained'}
                    >{t('generic.button.next')}</Button>
                )}
                {activeStep === 2 && (
                    <Button
                        onClick={() => handleSave()}
                        variant={'contained'}
                        disabled={addStatus.isLoading || updateStatus.isLoading || replaceStatus.isLoading}
                    >{t('generic.button.save')}</Button>
                )}
            </DialogActions>

            <Backdrop open={addStatus.isLoading || updateStatus.isLoading || replaceStatus.isLoading}>
                <CircularProgress/>
            </Backdrop>
        </Dialog>
    );
};

import {useState} from "react";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {t} from "../../i18n/i18n";

export interface ListSelectableItem {
    id: number;
    title: string;
}

export interface ListSelectableItemsProps {
    id: string;
    items: ListSelectableItem[];
    onAdd: (items: ListSelectableItem[]) => void;
}

export const ListSelectableItems = ({id, items, onAdd}: ListSelectableItemsProps) => {
    const [checked, setChecked] = useState<number[]>([]);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const handleAdd = () => {
        onAdd(items.filter((i) => checked.indexOf(i.id) >= 0));
        setChecked([]);
    }

    return (
        <>
            <Button
                variant={'contained'}
                startIcon={<AddIcon/>}
                onClick={handleAdd}
                disabled={checked.length === 0}
            >{t('generic.button.addSelected')}</Button>

            <List sx={{width: '100%'}}>
                {items.map((row) => {
                    const labelId = `${id}-listselectableitem-${row.id}`;

                    return (
                        <ListItem
                            key={row.id}
                            secondaryAction={
                                <IconButton disabled edge={'end'} aria-label={'View'}>
                                    <PreviewIcon/>
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(row.id)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge={'start'}
                                        checked={checked.indexOf(row.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={row.title}/>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </>
    );
};
import {useParams} from "react-router-dom";

import Box from "@mui/material/Box";

import {ResultExecutionView} from "../../components/admin/ResultExecutionView";

export const ResultExecutionPage = () => {
    const {executionid} = useParams();

    return (
        <Box>
            <ResultExecutionView executionId={executionid as unknown as number} />
        </Box>
    );
}

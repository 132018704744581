import {ReactElement, SyntheticEvent, useState} from "react";
import moment from "moment";
import {enqueueSnackbar} from "notistack";

import {Chip, Fab, Stack, Tooltip, Zoom} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import AddIcon from "@mui/icons-material/Add";
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ListAltIcon from '@mui/icons-material/ListAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRenderCellParams,
    GridRowId,
    GridRowParams,
    GridRowsProp,
    GridToolbar,
    MuiEvent,
    nbNO
} from "@mui/x-data-grid";

import {
    useCloneQuizMutation,
    useDeleteQuizMutation,
    useGetAllQuizesQuery,
    useUpdateQuizStatusMutation
} from "../../api/quiz";
import {EditQuizSubjectsDialog} from "../../components/admin/EditQuizSubjectsDialog";
import {EditQuizQuestionsDialog} from "../../components/admin/EditQuizQuestionsDialog";
import {EditQuizDialog} from "../../components/admin/EditQuizDialog";
import {EditQuizEndDialog} from "../../components/admin/EditQuizEndDialog";
import {useGetUserInfoQuery} from "../../api/user";
import {isSystemAdmin} from "../../util/AdminUtil";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";
import {useGetAllUserGroupsAdminQuery, UserGroupInfo} from "../../api/usergroups";
import {groupsFilterOperators, groupsSortComparator, groupsSortComparatorUserGroups} from "./utils";
import {t} from '../../i18n/i18n';
import {ErrorAlert} from "../../components/ErrorAlert";

interface ConfirmDialogState {
    open: boolean;
    quizId?: GridRowId;
    title?: string;
    description?: string;
    callback?: () => void;
}

interface EditDialogState {
    open: boolean;
    dialogType?: 'EDIT' | 'END' | 'SUBJECTS' | 'QUESTIONS';
    quizId?: number;
}

export const QuizesPage = () => {
    const {data: userInfo} = useGetUserInfoQuery();
    const {data, isSuccess, isError, error} = useGetAllQuizesQuery();
    const {
        data: groupsData,
        isSuccess: groupsSuccess,
        isError: groupsIsError,
        error: groupsError,
    } = useGetAllUserGroupsAdminQuery();
    const [editDialogState, setEditDialogState] = useState<EditDialogState>({ open: false });
    const [confirmState, setConfirmState] =
        useState<ConfirmDialogState>({ open: false });
    const [updateQuizStatus, updateStatus] = useUpdateQuizStatusMutation();
    const [cloneQuiz, cloneStatus] = useCloneQuizMutation();
    const [deleteQuiz, deleteStatus] = useDeleteQuizMutation();

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleAddNew = () => {
        setEditDialogState({
            open: true,
            dialogType: 'EDIT'
        });
    }

    const handleGotoQuestionsSubjectsForm = (quizId: number, quizQuestionsType: string) => {
        setEditDialogState({
            open: true,
            quizId: quizId,
            dialogType: quizQuestionsType === 'PREDEFINED' ? 'QUESTIONS' : 'SUBJECTS'
        });
    }

    const handleCloseForm = () => {
        setEditDialogState({
            open: false
        });
    }

    const handleEditRow = (id: GridRowId) => () => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            setEditDialogState({
                open: true,
                quizId: quiz.id,
                dialogType: 'EDIT'
            });
        }
    }

    const handleEditEndRow = (id: GridRowId) => () => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            setEditDialogState({
                open: true,
                quizId: quiz.id,
                dialogType: 'END'
            });
        }
    }

    const handleEditSubjectsOrQuestions = (id: GridRowId) => () => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            setEditDialogState({
                open: true,
                quizId: quiz.id,
                dialogType: quiz.quizQuestionsType === 'PREDEFINED' ? 'QUESTIONS' : 'SUBJECTS'
            });
        }
    }

    const handleCloseEdit = () => {
        setEditDialogState({
            open: false
        });
    }

    const handleStartQuiz = (id: GridRowId) => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            updateQuizStatus({
                id: quiz.id,
                status: 'ACTIVE'
            }).unwrap().then(() => {
                enqueueSnackbar(t('snackbar.quiz.activated'), { variant: "success" });
            });
        }
    }

    const handleArchiveQuiz = (id: GridRowId) => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            updateQuizStatus({
                id: quiz.id,
                status: 'ARCHIVED'
            }).unwrap().then(() => {
                enqueueSnackbar(t('snackbar.quiz.archived'), { variant: "success" });
            });
        }
    }

    const handleEndQuiz = (id: GridRowId) => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            updateQuizStatus({
                id: quiz.id,
                status: 'FINISHED'
            }).unwrap().then(() => {
                enqueueSnackbar(t('snackbar.quiz.finished'), { variant: "success" });
            });
        }
    }

    const handleDeleteQuiz = (id: GridRowId) => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            deleteQuiz(quiz.id)
                .unwrap().then(() => {
                enqueueSnackbar(t('snackbar.quiz.deleted'), { variant: "success" });
                });
        }
    }

    const handleCloneQuiz = (id: GridRowId) => {
        const quiz = (data || []).find(e => e.id === id);
        if (quiz !== undefined) {
            cloneQuiz(quiz.id)
                .unwrap().then(() => {
                    enqueueSnackbar(t('snackbar.quiz.cloned'), { variant: "success" });
                });
        }
    }

    const handleConfirmClose = (confirmed: boolean) => {
        if (confirmed && confirmState.callback !== undefined) {
            confirmState.callback();
        }

        setConfirmState({ open: false });
    }

    const cols: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: t('admin.quiz.list.header.actions'),
            width: 180,
            getActions: ({id, row}) => {
                const arr: JSX.Element[] = [];

                if (row.status === 'DRAFT') {
                    arr.push(
                        <GridActionsCellItem
                            icon={<Tooltip title={t('admin.quiz.list.buttonEditTooltip')}><EditIcon/></Tooltip>}
                            label={'Edit'}
                            className={'textPrimary'}
                            onClick={handleEditRow(id)}
                            color={'inherit'}
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title={t('admin.quiz.list.buttonQuestionsTooltip')}><ListAltIcon/></Tooltip>}
                            label={'Edit questions'}
                            onClick={handleEditSubjectsOrQuestions(id)}
                            color={'inherit'}
                        />
                    );

                    if (row.numberOfQuestions > 2) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Tooltip title={t('admin.quiz.list.buttonStartTooltip')}><PlayArrowIcon/></Tooltip>}
                                label={'Activate quiz'}
                                onClick={() => id !== undefined && setConfirmState({
                                    open: true,
                                    title: t('admin.quiz.list.start.title'),
                                    description: t('admin.quiz.list.start.description'),
                                    callback: () => handleStartQuiz(id)
                                })}
                                color={'inherit'}
                            />
                        );
                    }

                    arr.push(
                        <GridActionsCellItem
                            icon={<Tooltip title={t('admin.quiz.list.buttonDeleteTooltip')}><DeleteForeverIcon/></Tooltip>}
                            label={'Delete quiz'}
                            onClick={() => id !== undefined && setConfirmState({
                                open: true,
                                title: t('admin.quiz.list.delete.title'),
                                callback: () => handleDeleteQuiz(id)
                            })}
                            color={'inherit'}
                        />
                    );
                } else if (row.status === 'ACTIVE') {
                    arr.push(
                        <GridActionsCellItem
                            icon={<Tooltip title={t('admin.quiz.list.buttonDateendTooltip')}><DateRangeIcon/></Tooltip>}
                            label={'Edit end date'}
                            className={'textPrimary'}
                            onClick={handleEditEndRow(id)}
                            color={'inherit'}
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title={t('admin.quiz.list.buttonStopTooltip')}><StopIcon/></Tooltip>}
                            label={'End quiz'}
                            onClick={() => id !== undefined && setConfirmState({
                                open: true,
                                title: t('admin.quiz.list.end.title'),
                                callback: () => handleEndQuiz(id)
                            })}
                            color={'inherit'}
                        />
                    );
                } else if (row.status === 'FINISHED') {
                    arr.push(
                        <GridActionsCellItem
                            icon={<Tooltip title={t('admin.quiz.list.buttonArchiveTooltip')}><ArchiveIcon/></Tooltip>}
                            label={'Archive'}
                            className={'textPrimary'}
                            onClick={() => id !== undefined && setConfirmState({
                                open: true,
                                title: t('admin.quiz.list.archive.title'),
                                description: t('admin.quiz.list.archive.description'),
                                callback: () => handleArchiveQuiz(id)
                            })}
                            color={'inherit'}
                        />
                    );
                }

                arr.push(
                    <GridActionsCellItem
                        icon={<Tooltip title={t('admin.quiz.list.buttonCloneTooltip')}><FileCopyIcon/></Tooltip>}
                        label={'Clone'}
                        className={'textPrimary'}
                        onClick={() => id !== undefined && setConfirmState({
                            open: true,
                            title: t('admin.quiz.list.clone.title'),
                            description: t('admin.quiz.list.clone.description'),
                            callback: () => handleCloneQuiz(id)
                        })}
                        color={'inherit'}
                    />
                )

                return arr;
            }
        },
        {
            field: 'id',
            headerName: t('admin.quiz.list.header.id'),
            width: 60
        },
        {
            field: 'title',
            headerName: t('admin.quiz.list.header.title'),
            flex: 1
        },
        {
            field: 'start',
            headerName: t('admin.quiz.list.header.starttime'),
            type: 'dateTime',
            width: 140,
            valueFormatter: params => moment(params?.value).format('YYYY-MM-DD HH:mm')
        },
        {
            field: 'end',
            headerName: t('admin.quiz.list.header.endtime'),
            type: 'dateTime',
            width: 140,
            valueFormatter: params => params?.value === null ? '-' : moment(params?.value).format('YYYY-MM-DD HH:mm')
        },
        {
            field: 'userGroup',
            headerName: t('admin.quiz.list.header.groups'),
            type: 'singleSelect',
            width: 250,
            valueOptions: (groupsData || [])
                .map(g => ({
                    value: g.id,
                    label: g.title ? `${g.title} (${g.shortname})` : '-'
                }))
                .sort((a, b) => a.label.localeCompare(b.label, 'no')),
            valueGetter: (params) => [
                ...params.row.userGroups
            ],
            renderCell: (params: GridRenderCellParams<any, UserGroupInfo[]>) => {
                if (params.value !== undefined && params.value.length > 0) {
                    const groups = [...params.value];
                    groups.sort((a, b) => a.shortname.localeCompare(b.shortname, 'no'));
                    const el: ReactElement[] = [];
                    for (const group of groups) {
                        el.push(
                            <Chip
                                key={group.id}
                                label={group.shortname}
                                title={group.title}
                                size={'small'}
                                variant={'outlined'}
                            />
                        );
                    }

                    return <Stack direction={'row'} spacing={1}>{el}</Stack>;
                }
            },
            sortComparator: groupsSortComparatorUserGroups,
            filterOperators: groupsFilterOperators
        },
        {
            field: 'durationMinutes',
            headerName: t('admin.quiz.list.header.duration'),
            type: 'number',
            width: 80,
            valueFormatter: params => params?.value + ' min'
        },
        {
            field: 'numberOfQuestions',
            headerName: t('admin.quiz.list.header.questions'),
            type: 'number',
            width: 90
        },
        {
            field: 'status',
            headerName: t('admin.quiz.list.header.status'),
            type: 'singleSelect',
            width: 150,
            valueOptions: [
                { value: 'DRAFT', label: t('generic.quizStatus.draft') },
                { value: 'ACTIVE', label: t('generic.quizStatus.active') },
                { value: 'FINISHED', label: t('generic.quizStatus.finished') },
                { value: 'ARCHIVED', label: t('generic.quizStatus.archived') },
                { value: 'DELETED', label: t('generic.quizStatus.deleted') },
            ],
            valueGetter: (params) => {
                return params.value;
            },
            renderCell: (params: GridRenderCellParams<any, string>) => {
                let color: any = undefined;

                switch (params.value) {
                    case 'DRAFT': color = 'info'; break;
                    case 'ACTIVE': color = 'success'; break;
                    case 'FINISHED': color = 'warning'; break;
                    case 'DELETED': color = 'error'; break;
                    default:
                }

                return <Chip
                    label={t('generic.quizStatus.' + params.value?.toLowerCase())}
                    size={'small'}
                    color={color}
                />;
            },
            sortComparator: groupsSortComparator,
            filterOperators: groupsFilterOperators
        }
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getQuizesInfo')} error={error} />}
            {groupsIsError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={groupsError} />}
            {cloneStatus.isError && <ErrorAlert title={t('apiError.cloneQuiz')} error={cloneStatus.error} />}
            {deleteStatus.isError && <ErrorAlert title={t('apiError.deleteQuiz')} error={deleteStatus.error} />}
            {updateStatus.isError && <ErrorAlert title={t('apiError.updateQuiz')} error={updateStatus.error} />}

            <ConfirmationDialog
                id={'confirm-dialog'}
                title={confirmState.title ?? ''}
                description={confirmState.description}
                open={confirmState.open}
                onClose={handleConfirmClose}
            />
            <EditQuizDialog
                quizid={editDialogState.quizId}
                open={editDialogState.open && editDialogState.dialogType === 'EDIT'}
                close={handleGotoQuestionsSubjectsForm}
                cancel={handleCloseForm}
                userGroupIsRequired={!isSystemAdmin(userInfo)}
            />
            <EditQuizEndDialog
                quizid={editDialogState.quizId}
                open={editDialogState.open && editDialogState.dialogType === 'END'}
                close={handleCloseForm}
                cancel={handleCloseForm}
            />
            <EditQuizSubjectsDialog
                quizid={editDialogState.quizId}
                open={editDialogState.open && editDialogState.dialogType === 'SUBJECTS'}
                close={handleCloseEdit}
            />
            <EditQuizQuestionsDialog
                quizid={editDialogState.quizId}
                open={editDialogState.open && editDialogState.dialogType === 'QUESTIONS'}
                close={handleCloseEdit}
            />

            <DataGrid
                loading={!isSuccess || !groupsSuccess}
                autoHeight
                editMode={'row'}
                columns={cols}
                rows={(data || []) as GridRowsProp}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                slots={{ toolbar: GridToolbar }}
                localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'title', sort: 'asc' }]
                    }
                }}
            />

            <div className={'fabArea'}>
                <Zoom in unmountOnExit>
                    <Fab
                        color={'primary'}
                        className={'fabButton'}
                        aria-label={t('generic.button.addNew')}
                        onClick={handleAddNew}
                    ><AddIcon/></Fab>
                </Zoom>
            </div>
        </>
    );
};

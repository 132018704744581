import {useState} from "react";

import {Dropzone, ExtFile, FileMosaic} from "@files-ui/react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

import {t} from "../i18n/i18n";

export interface FileUploadDialogProps {
    title: string;
    accept: string;
    open: boolean;
    onClose: (confirmed: boolean, files: ExtFile[]) => void;
}

export const FileUploadDialog = ({
    title, accept, open, onClose
}: FileUploadDialogProps) => {
    const [files, setFiles] = useState<ExtFile[]>([]);

    const handleOk = () => {
        onClose(true, files);
        setFiles([]);
    }

    const handleCancel = () => {
        setFiles([]);
        onClose(false, []);
    }

    const removeFile = (id: string | number | undefined) => {
        setFiles(files.filter((image) => image.id !== id));
    }

    return (
        <Dialog
            maxWidth={'xs'}
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Dropzone
                    accept={accept}
                    onChange={setFiles}
                    value={files}
                >
                    {files.map((file) => (
                        <FileMosaic key={file.id} {...file} onDelete={removeFile} preview />
                    ))}
                </Dropzone>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>{t('generic.button.ok')}</Button>
                <Button autoFocus onClick={handleCancel}>{t('generic.button.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

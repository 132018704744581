import {useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";

import {
    Box, Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem, OutlinedInput,
    Select
} from "@mui/material";

import {AccessUser, useAddAccessUserRoleMutation} from "../../api/accessmanagement";
import {useGetAllUsersQuery} from "../../api/user";
import {useGetAllUserGroupsAdminQuery} from "../../api/usergroups";
import {t} from "../../i18n/i18n";
import {ErrorAlert} from "../ErrorAlert";

const formInitialState = {
    userId: null as number | null,
    fullName: undefined as string | undefined,
    role: '',
    userGroupId: undefined as number | undefined
};

export interface EditAccess {
    showDialog: boolean;
    userAccess?: AccessUser;
}

interface Props {
    editAccess: EditAccess;
    callbackClose: () => void;
}

export const EditAccessDialog = ({
    editAccess,
    callbackClose,
}: Props) => {
    const {data: usersData, isSuccess: usersSuccess} = useGetAllUsersQuery();
    const {data: userGroupsData, isSuccess: userGroupsSuccess} = useGetAllUserGroupsAdminQuery();
    const [addAccessUserRole, addStatus] = useAddAccessUserRoleMutation();
    const [formData, setFormData] = useState(formInitialState);

    useEffect(() => {
        if (editAccess.showDialog && editAccess.userAccess !== undefined) {
            console.log(editAccess.userAccess);
            setFormData({
                ...formInitialState,
                ...(editAccess.userAccess)
            });
        }
    }, [editAccess]);

    const handleAddClose = () => {
        setFormData(formInitialState);
        callbackClose();
    }

    const handleSave = () => {
        if (formData.userId === null) {
            return;
        }

        addAccessUserRole({
            userId: formData.userId,
            role: formData.role,
            userGroupId: formData.userGroupId
        }).unwrap().then(() => {
            setFormData(formInitialState);
            enqueueSnackbar(t('snackbar.access.updated'), { variant: "success" });
            callbackClose();
        });
    }

    return (
        <Dialog open={editAccess.showDialog} onClose={handleAddClose} maxWidth={'lg'} fullWidth>
            <DialogTitle>{formData.userId !== null
                ? t('admin.accessManagement.edit.title', {name: formData.fullName})
                : t('admin.accessManagement.add.title')}</DialogTitle>

            {addStatus.isError && <ErrorAlert title={t('apiError.addAccess')} error={addStatus.error} />}

            {(!usersSuccess || !userGroupsSuccess) && <CircularProgress />}

            <DialogContent>
                <Box component={'form'}
                     sx={{
                         display: 'grid',
                         gridTemplateColumns: {sm: '1fr'},
                         gap: 2
                     }}
                     noValidate
                     autoComplete={'off'}>
                    <FormControl fullWidth>
                        <InputLabel id={'userLabel'}>{t('admin.accessManagement.form.user')}</InputLabel>
                        {editAccess.userAccess === undefined && (
                            <Select
                                id={'user'}
                                label={t('admin.accessManagement.form.user')}
                                labelId={'userLabel'}
                                disabled={formData.fullName !== undefined}
                                value={formData.userId === null ? '' : formData.userId}
                                onChange={e => setFormData({
                                    ...formData,
                                    userId: e.target.value !== null ? e.target.value as unknown as number : null
                                })}
                            >
                                {(usersData || []).map((user) => (
                                    <MenuItem key={user.id}
                                              value={user.id as unknown as number}>{user.fullName}</MenuItem>
                                ))}
                            </Select>
                        )}
                        {editAccess.userAccess && (
                            <OutlinedInput
                                id={'fullname'}
                                label={t('admin.accessManagement.form.user')}
                                value={formData.fullName ?? ''}
                                disabled
                            />
                        )}
                    </FormControl>
                    {editAccess.userAccess && (
                        <input id={'user'} value={formData.userId ?? ''} type={"hidden"}/>
                    )}
                    <FormControl fullWidth>
                        <InputLabel id={'roleLabel'}>{t('admin.accessManagement.form.role')}</InputLabel>
                        <Select
                            id={'role'}
                            label={'Role'}
                            labelId={'roleLabel'}
                            value={formData.role}
                            onChange={e => setFormData({
                                ...formData,
                                role: e.target.value,
                                userGroupId: e.target.value !== 'REGIONADMIN' ? undefined : formData.userGroupId
                            })}
                        >
                            <MenuItem value={'ADMIN'}>{t('admin.accessManagement.roleLabel.ADMIN')}</MenuItem>
                            <MenuItem value={'REGIONADMIN'}>{t('admin.accessManagement.roleLabel.REGIONADMIN')}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        required={formData.role === 'REGIONADMIN'}
                        error={formData.role === 'REGIONADMIN' && formData.userGroupId === undefined}
                    >
                        <InputLabel id={'userGroup-label'}>{t('admin.accessManagement.form.region')}</InputLabel>
                        <Select
                            id={'userGroup'}
                            labelId={'userGroup-label'}
                            disabled={formData.role !== 'REGIONADMIN'}
                            fullWidth
                            value={formData.userGroupId ?? ''}
                            onChange={e => setFormData({
                                ...formData,
                                userGroupId: e.target.value as unknown as number
                            })}
                        >
                            {(userGroupsData || []).map((group) => (
                                <MenuItem
                                    key={group.id}
                                    value={group.id}
                                >
                                    {group.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={formData.userId === null || formData.role === '' ||
                        (formData.role === 'REGIONADMIN' && formData.userGroupId === undefined)
                    }
                >{t('generic.button.save')}</Button>
                <Button onClick={handleAddClose}>{t('generic.button.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

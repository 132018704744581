import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle, IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar
} from "@mui/material";

import {t} from "../i18n/i18n";
import {useState} from "react";
import {useGetAllFilenamesQuery} from "../api/images";
import PreviewIcon from "@mui/icons-material/Preview";

export interface Props {
    open: boolean;
    onClose: (confirmed: boolean, filename?: string) => void;
}

export const ImageSelectorDialog = ({
    open,
    onClose
}: Props) => {
    const [selectedFilename, setSelectedFilename] = useState<string | undefined>(undefined);
    const { data: filenames, isSuccess: filenamesSuccess } = useGetAllFilenamesQuery();

    const imagesData = filenames?.map((filename) => ({
        img: '/api/image/thumb/' + filename,
        filename,
        title: filename
    })) ?? [];

    const handleOk = () => {
        onClose(true, selectedFilename);
        setSelectedFilename(undefined);
    }

    const handleCancel = () => {
        onClose(false, undefined);
        setSelectedFilename(undefined);
    }

    return (
        <Dialog maxWidth={'md'} open={open}>
            <DialogTitle>{t('imageSelector.title')}</DialogTitle>

            {!filenamesSuccess && <CircularProgress />}

            <ImageList cols={4}>
                {imagesData.map((item) => (
                    <ImageListItem key={item.img} onClick={() => setSelectedFilename(item.filename)}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                        {selectedFilename === item.filename && (
                            <ImageListItemBar
                                title={item.title}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.title}`}
                                    >
                                        <PreviewIcon />
                                    </IconButton>
                                }
                            />
                        )}
                    </ImageListItem>
                ))}
            </ImageList>

            <DialogActions>
                <Button disabled={selectedFilename === undefined} onClick={handleOk}>
                    {t('generic.button.ok')}
                </Button>
                <Button autoFocus onClick={handleCancel}>
                    {t('generic.button.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

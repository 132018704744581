import {ChangeEvent, useState} from "react";
import {enqueueSnackbar} from "notistack";

import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    TextField
} from "@mui/material";

import {useAddUserGroupMutation} from "../../api/usergroups";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

const formInitialState = {
    title: '',
    shortname: '',
    userSelectable: true
};

interface Props {
    showDialog: boolean;
    callbackClose: () => void;
}

export const AddUserGroupDialog = ({
    showDialog,
    callbackClose,
}: Props) => {
    const [addUserGroup, addStatus] = useAddUserGroupMutation();
    const [formData, setFormData] = useState(formInitialState);

    const handleSaveNew = () => {
        addUserGroup({
            title: formData.title,
            shortname: formData.shortname,
            userSelectable: formData.userSelectable
        }).unwrap().then(() => {
            setFormData(formInitialState);
            enqueueSnackbar(t('snackbar.usergroup.updated'), { variant: "success" });
            callbackClose();
        });
    };

    const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, userSelectable: e.target.checked});
    };

    const handleAddClose = () => {
        setFormData(formInitialState);
        callbackClose();
    };

    return (
        <Dialog open={showDialog} onClose={handleAddClose} fullWidth>
            <DialogTitle>{t('admin.userGroup.add.title')}</DialogTitle>

            {addStatus.isError && <ErrorAlert title={t('apiError.updateAccessGroup')} error={addStatus.error} />}

            <DialogContent>
                <DialogContentText>
                    {t('admin.userGroup.add.description')}
                </DialogContentText>
                <TextField
                    id={'name'}
                    autoFocus
                    margin={'dense'}
                    label={t('admin.userGroup.form.title.label')}
                    fullWidth
                    variant={'standard'}
                    value={formData.title}
                    onChange={e => setFormData({
                        ...formData,
                        title: e.target.value
                    })}
                />
                <TextField
                    id={'shortname'}
                    autoFocus
                    margin={'dense'}
                    label={t('admin.userGroup.form.shortname.label')}
                    placeholder={t('admin.userGroup.form.shortname.placeholder')}
                    fullWidth
                    variant={'standard'}
                    value={formData.shortname}
                    onChange={e => setFormData({
                        ...formData,
                        shortname: e.target.value.toUpperCase().substring(0, 4)
                    })}
                />
                <FormControlLabel control={
                    <Checkbox
                        onChange={handleCheckBox}
                        checked={formData.userSelectable}
                    />
                } label={t('admin.userGroup.form.userSelectable.label')}/>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={addStatus.isLoading}
                    onClick={handleSaveNew}
                >{t('generic.button.save')}</Button>
                <Button onClick={handleAddClose}>{t('generic.button.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

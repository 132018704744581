import moment from "moment";

import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid";

import {useGetLatestEventsQuery} from "../../api/eventlog";
import {ErrorAlert} from "../../components/ErrorAlert";
import {t} from "../../i18n/i18n";

export const EventsPage = () => {
    const {data, isSuccess, isError, error } = useGetLatestEventsQuery();

    const cols: GridColDef[] = [
        {
            field: 'created',
            headerName: t('admin.eventlog.list.header.created'),
            width: 180,
            valueFormatter: ({value}) => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            field: 'createdBy',
            headerName: t('admin.eventlog.list.header.createdBy'),
            width: 200
        },
        {
            field: 'user',
            headerName: t('admin.eventlog.list.header.aboutUser'),
            width: 200
        },
        {
            field: 'eventType',
            headerName: t('admin.eventlog.list.header.type'),
            width: 200,
            valueFormatter: ({value}) => t('generic.eventlogType.' + value.toLowerCase())
        },
        {
            field: 'event',
            headerName: t('admin.eventlog.list.header.event'),
            flex: 1
        }
    ];

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getEventsInfo')} error={error} />}

            <DataGrid
                loading={!isSuccess}
                autoHeight
                columns={cols}
                rows={(data || []) as GridRowsProp}
            />
        </>
    );
};

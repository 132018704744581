import api from './index';
import {Question} from "./question";

export interface ReportUserRow {
    executionId: number;
    title: string;
    start: string;
    secondsSpent: number;
    score: number | null;
    totalQuestions: number;
    result: string;
}

export interface ReportQuizGroup {
    title: string;
    percentToPass: number;
    rows: ReportUserRow[];
}

export interface ReportQuizGroupQuery {
    quizId: number | null;
    userGroupId: number | null;
    onlyBestResult: boolean;
}

export interface ReportQuizQuestionStats {
    question: Question;
    questionNumberInQuiz?: number
    option1hits: number;
    option2hits: number;
    option3hits: number;
    option4hits: number;
    option5hits: number;
    option6hits: number;
    correctHits: number;
    totalHits: number;
}

export interface ReportQuizQuestionStatsQuery {
    quizId: number | null;
    userGroupId: number | null;
}

const reports = api.injectEndpoints({
    endpoints: (build) => ({
        getReportQuizGroup: build.mutation<ReportQuizGroup, ReportQuizGroupQuery>({
            query: (query) => ({
                url: `admin/reports/quiz/${query.quizId}/${query.userGroupId}`
                    + (query.onlyBestResult ? '' : '/all'),
                method: 'GET'
            })
        }),
        getReportQuizQuestionStats: build.mutation<ReportQuizQuestionStats[], ReportQuizQuestionStatsQuery>({
            query: (query) => ({
                url: `admin/reports/stats/questions/${query.quizId}/${query.userGroupId}`,
                method: 'GET'
            })
        })
    }),
    overrideExisting: false
});

export const {
    useGetReportQuizGroupMutation, useGetReportQuizQuestionStatsMutation
} = reports;

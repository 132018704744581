import {useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {CircularProgress, Paper, Stack, styled} from "@mui/material";

import {useStartQuizQuery} from "../api/quizexecution";
import {GenericError} from "../api/genericError";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

const StyledPaper = styled(Paper)(() => ({
    padding: '6px',
    height: 'calc(100vh - 12px)',
    width: 'calc(100vw - 12px)',
}));

export const QuizStartPage = () => {
    const {id} = useParams();
    const navigate = useRef(useNavigate());
    const {data, isSuccess, isError, error} = useStartQuizQuery(
        id !== undefined ? id as unknown as number : 0,
        {skip: id === undefined}
    );

    useEffect(() => {
        if (isSuccess) {
            navigate.current(`/quizrun/${data.executionId}`);
        } else if (error !== undefined) {
            const errorData = error as GenericError;
            if (errorData.data.quizardErrorCode === 'ERROR_010_ONGOING_QUIZ') {
                navigate.current('/quizongoing');
            }
        }
    }, [data, isSuccess, error, navigate]);

    return (
        <StyledPaper>
            <Stack spacing={2} justifyContent={'space-between'} sx={{marginBottom: 10}}>
                <h1>{t('quiz.starting')}</h1>
                {isError && <ErrorAlert title={t('apiError.quizStart')} error={error} />}
                {!isError && <CircularProgress/>}
            </Stack>
        </StyledPaper>
    );
};
